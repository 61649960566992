import React, { useEffect, useState } from 'react'
import { FaPlus, FaRegEdit } from 'react-icons/fa'
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { onUploadCreateMedia } from '../../../../../Redux/Actions/ProjectActions'
import TransModal from './TransModal'
import MediaRows from './MediaRows'
import { FiUpload } from 'react-icons/fi'

const MediaDoc = ({ cId, gotoAsset, setGotoAsset }) => {
    const dispatch = useDispatch()
    const [lang, setLang] = useState([])
    const [mediaData, setMediaData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        upload: false
    })
    const [show, setShow] = useState(false)

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Yes",
        language: "en-US",
        file: ""
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const onExtract = (file) => {
        setSweet({
            ...sweet,
            enable: true,
            file: file
        })
    }

    const onCancelExtract = () => {
        handleUploadFile(false)
    }

    const onPerform = () => {
        setSweet({
            ...sweet,
            enable: false
        })
        handleShow()
    }

    const performExtract = () => {
        handleUploadFile(true)
        handleClose()
    }

    const handleUploadFile = (isExtract) => {
        let obj = {
            isExtract: isExtract,
            code: sweet.language,
            cId: cId
        }
        const formData = new FormData()
        setLoader({
            ...loader,
            upload: true
        })
        formData.append('upload_type', "video")
        formData.append('file', sweet.file)
        dispatch(onUploadCreateMedia(formData, obj, handleClose, fetchDoc, loader, setLoader))
        setSweet({
            ...sweet,
            language: "en-US",
            file: "",
            enable: false
        })
    }

    const onInputFile = (e) => {

        if (e.target.files.length > 0) {
            if (['video/mp4'].includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 200000000) {
                    onExtract(e.target.files[0])
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 200MB!',
                        confirmButtonColor: "#3f429b"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type only MP4 files are allowed!',
                    confirmButtonColor: "#3f429b"
                })
            }
        }
    }



    const fetchLang = () => {
        dispatch(fetchData("fetch-transcribe-language", {}, setLang))
    }

    const fetchDoc = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-media-all", data, setMediaData, loader, setLoader))
    }

    useEffect(() => {
        if (mediaData.length > 0) {
            const isData = mediaData.find(({ data }) => data !== "")
            if (isData) {
                setGotoAsset({
                    ...gotoAsset,
                    media: true
                })
            } else {
                setGotoAsset({
                    ...gotoAsset,
                    media: false
                })
            }
        }
    }, [mediaData])

    useEffect(() => {
        fetchLang()
        fetchDoc()
    }, [])

    return (
        <>
            <div className='upload-box' style={{ border: "1px dashed #3f429b" }}>
                <div className='upload' style={{ height: "100%", width: "100%", color: "#3f429b" }}>
                    {loader.upload ?
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                        :
                        <>
                            <FiUpload />
                            <input type="file" onChange={onInputFile} style={{ cursor: "pointer" }} />
                            <p><strong>Upload Video (MP4)</strong></p>
                        </>
                    }
                </div>
            </div>
            <h2 className='pt-5'>Stored Documents</h2>
            <div className='doc-table'>
                <div className='table-responsive'>
                    <table className='table text-center'>
                        <tr>
                            <th></th>
                            <th>Content</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                        {mediaData.length > 0 ?
                            mediaData.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <MediaRows
                                            curElem={curElem}
                                            fetchDoc={fetchDoc}
                                        />
                                    </React.Fragment>

                                )
                            })
                            :
                            <tr>
                                <td colSpan={4} className='text-center'>
                                    {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                        "You have not uploaded a video file yet!"
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </div>


            <TransModal
                show={show}
                handleClose={handleClose}
                lang={lang}
                sweet={sweet}
                setSweet={setSweet}
                performExtract={performExtract}
                loader={loader}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Do you want to Transcribe the video ?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"No"}
                handleClose={onCancelExtract}
                performDelete={onPerform}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default MediaDoc