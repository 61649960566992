import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { FaRegEdit, FaRegEye } from 'react-icons/fa'
// import { MdPreview } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { fetchData, onCheckResourcesStatus, onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import dummyIcon from "../../../../../assets/images/defaultIcon.png"
import { FiExternalLink } from "react-icons/fi";
import { GoGift, GoShareAndroid } from "react-icons/go";
import ShareThisModal from '../../../../CommonComponent/ShareThisModal'

const BonusRow = ({ curElem, bonus, setBonus, fetchAllBonus }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-landing", data, bonus, setBonus, setSweet))
    }

    const checkStatus = (interval) => {
        let data = {
            type: "bonus",
            id: curElem.id
        }
        dispatch(onCheckResourcesStatus(data, interval, fetchAllBonus))
    }

    useEffect(() => {
        if (+curElem.status === 2) {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem.id])

    return (
        <tr>
            <td>
                <span className='vid-thumb'><img src={dummyIcon} alt="" /></span>
            </td>
            <td>{curElem.name}</td>
            <td ><span className='status-box' style={{ background: +curElem.status === 2 ? "#FF7F50" : "#008000" }}>{+curElem.status === 2 ? "Generating..." : "Generated"}</span></td>
            <td>{curElem.created}</td>
            <td>
                {+curElem.status === 1 ?
                    <>
                        <Link className='link-icon' title='Edit' to={`/projects/edit-landing?cid=${curElem.cId}&id=${curElem.id}`}><FaRegEdit /></Link>
                        <a className='link-icon' href={curElem.liveLink} target='_blank' title='Preview'><FiExternalLink /></a>
                        <a className='link-icon' title='ThankYou Page' href={curElem.thankyouLink} target='_blank'><GoGift /></a>
                    </>
                    :
                    null
                }
                <a className='link-icon' title='Delete' onClick={() => onDelete(curElem.id)} ><BsTrash /></a>
                <a className='link-icon' title='Share' onClick={() => setShow(true)} ><GoShareAndroid /></a>

            </td>
            <ShareThisModal
                url={curElem.liveLink}
                show={show}
                setShow={setShow}
            />
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this bonus page?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </tr>
    )
}

export default BonusRow