import React, { useState } from 'react'
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import defaultImage from "../../../../../../assets/images/dummyImage.jpg"
import ImagesModal from '../../BonusAssets/BonusPage/Modals/ImagesModal';
import { onChangeOptinHeader } from '../../../../../../Redux/Actions/ProjectActions';

const HeaderSection = () => {
    const dispatch = useDispatch()
    const optinData = useSelector(state => state.optin.data)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeOptinHeader(name, value))
    }


    const handleAddBanner = (data) => {
        let obj = {
            target: {
                name: "logo",
                value: data.url
            }
        }
        handleChange(obj)
    }

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Banner Heading"
                        type="text"
                        name='heading'
                        value={optinData.headers.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Logo Image</span>
                        <div className="inpLabel">
                            <span onClick={handleShow} style={{ cursor: "pointer" }} className="fullLink"></span>
                            <span className='uploaded-img'><img src={optinData.headers.logo !== "" ? optinData.headers.logo : defaultImage} alt="" /></span>
                            <input
                                className="inpLabel-inp"
                                placeholder=""
                                type="text"
                            />
                            <span className="inpBtn-icon">
                                <FiUpload /> <span className="pl-1">Choose</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt"> Description</span>
                <div className="inpLabel">
                    <label htmlFor="bannerDescription"> Description</label>
                    <textarea
                        className="inpLabel-inp"
                        name="description"
                        placeholder="Banner Description"
                        value={optinData.headers.description}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddBanner}
            />

        </div>

    )
}

export default HeaderSection