import React, { useEffect, useState } from 'react'
import { FaEdit, FaRegEdit, FaRegEye } from "react-icons/fa"
import { BsTrash } from "react-icons/bs"
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { useDispatch } from 'react-redux'
import { onCheckTranscribe, onDeleteKnowledgeTableRow } from '../../../../../Redux/Actions/ProjectActions'
import { Link } from 'react-router-dom'

const MediaRows = ({ curElem, fetchDoc }) => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState(0)
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            loader: true,
            confirmButtonName: "Deleting"
        })
        let data = {
            id: curElem.id,
            cId: curElem.cId,
            type: "media"

        }
        dispatch(onDeleteKnowledgeTableRow(data, fetchDoc, sweet, setSweet))
    }

    useEffect(() => {
        if (curElem && (+curElem.isExtract === 1)) {
            setStatus(curElem.status)
            if (+curElem.status === 0 || +curElem.status === 2) {
                const interval = setInterval(() => {
                    let data = {
                        tId: curElem.id,
                        jobName: curElem.jobName
                    }
                    dispatch(onCheckTranscribe(data, fetchDoc, setStatus, interval))
                }, 5000)

                return () => {
                    clearInterval(interval)
                }
            }
        }
    }, [curElem])

    return (

        <>

            <tr>
                <td><img src={curElem.thumbnail} style={{ width: 100, height: 100, objectFit: "contain" }} /></td>
                <td>{curElem.data ? <> {curElem.data.slice(0, 50)}...</> : "N/A"}</td>
                {/* <td>{+status === 0 || +status === 2 ? "Transcribing" : +status === 1 ? "Compeleted" : +status === 3 ? "Failed" : ""}</td> */}
                <td>{curElem.created.slice(0, 10)}</td>
                <td className='text-end'>
                    {+status === 1 ?
                        <Link className='link-icon' title='Edit' style={{ cursor: "pointer" }} to={`/projects/update-document?cid=${curElem.cId}&id=${curElem.id}&type=m`}><FaRegEdit /></Link>
                        : null}
                    <a className='link-icon' title='View' target='_blank' href={curElem.url}><FaRegEye /></a>

                    <span className='link-icon' title='Delete' style={{ cursor: "pointer" }} onClick={onDelete}><BsTrash /></span>
                </td>
                <SweetAlert
                    show={sweet.enable}
                    message={"Are you sure you want to delete this document?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                    alertLoader={sweet.loader}
                />

            </tr >

            {/* <div className='up-media-single' style={{ position: "relative" }}>
                <img src={curElem.thumbnail} alt="" />
                <div className="media_action_wrap">
                    <div className='media-image-actions'>
                        {+status === 1 ?
                            <div className="media_icon">
                                <span>
                                    <Link className='link-icon' title='Edit' style={{ cursor: "pointer", position: "relative", top: "-3px", left: '2px' }} to={`/projects/update-document?cid=${curElem.cId}&id=${curElem.id}&type=m`}><FaEdit /></Link>
                                </span>
                            </div>
                            : ""
                        }
                        <div className="media_icon">

                            <span className='link-icon' title='Delete' style={{ cursor: "pointer" }} onClick={onDelete}><BsTrash /></span>
                        </div>
                    </div>
                </div>
            </div> */}

        </>


    )
}

export default MediaRows