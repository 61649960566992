import React, { useState, useEffect } from 'react';

import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { onImportDoc, onWriteDoc } from '../../../../../Redux/Actions/ProjectActions';
import { useDispatch } from 'react-redux';
import ImportTableRows from './ImportTableRows';

const ImportUrl = ({ cId, gotoAsset, setGotoAsset }) => {
    const dispatch = useDispatch()
    const [isEnter, setIsEnter] = useState({
        in: false,
        out: false
    })
    const scriptStatus = ["Visiting URL", "Reading page's content", "Analysing content", "Extracting content"]
    const [staText, setStaText] = useState("")
    const [importData, setImportData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        create: false
    })
    const [data, setData] = useState({
        url: "",
        type: "normal"
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let obj = {
            cId: cId,
            ...data
        }
        let ind = 0
        setStaText(scriptStatus[0])
        const interval = setInterval(() => {
            ind++
            if (ind === 4) {
                ind = 1
            }
            setTimeout(() => {
                setIsEnter({
                    ...isEnter,
                    in: false,
                    out: true
                })
                setTimeout(() => {
                    setStaText(scriptStatus[ind])
                }, 1000)
            }, 1000)
        }, 7000)
        dispatch(onImportDoc(obj, fetchDoc, setData, loader, setLoader, interval))
    }

    const fetchDoc = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-import-all", data, setImportData, loader, setLoader))
    }

    useEffect(() => {
        if (staText) {
            setIsEnter({
                ...isEnter,
                in: true,
                out: false
            })
        }
    }, [staText])

    useEffect(() => {
        if (importData.length > 0) {
            const isData = importData.find(({ data }) => data !== "")
            if (isData) {
                setGotoAsset({
                    ...gotoAsset,
                    import: true
                })
            } else {
                setGotoAsset({
                    ...gotoAsset,
                    import: false
                })
            }
        }
    }, [importData])


    useEffect(() => {
        fetchDoc()
    }, [])
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='d-flex align-items-center justify-content-center '>
                    <div className='common-input-wrap mt-0' style={{ width: "100%" }}>
                        <label htmlFor="">Website URL</label>
                        <div style={{ position: "relative" }}>
                            <input
                                className='common-input'
                                type="url"
                                name='url'
                                placeholder='Enter Website URL'
                                value={data.url}
                                onChange={handleChange}
                                required
                            />
                            <select
                                className='input-select-box'
                                onChange={handleChange}
                                name='type'
                                value={data.type}
                            >
                                <option value={"normal"}>Non E-commerce URL</option>
                                <option value={"ecom"}>E-commerce URL</option>
                            </select>
                        </div>
                    </div>
                    <div className='common-input-wrap ms-2'>
                        <button className='site-link' type='submit' disabled={loader.create}><span className='d-flex align-items-center justify-content-center'>
                            {loader.create ? <> Importing <i className="fa fa-spinner fa-spin ms-1" /></> : "Import"}
                        </span></button>
                    </div>
                </div>
                <div className="swiper_text">
                    <div className='text-center my-1 '>
                        {loader.create && staText ?
                            <p style={{ color: "#3f429b" }} className={`animate__animated ${isEnter.in ? 'animate__lightSpeedInLeft' : ""} ${isEnter.out ? 'animate__lightSpeedOutRight' : ""}`}><strong>{staText}...</strong></p> : ""}
                    </div>
                </div>

            </form>

            <h2 className='pt-5'>Stored Documents</h2>
            <div className='doc-table'>
                <div className='table-responsive'>
                    <table className='table'>
                        <tr>
                            <th>URL</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                        {importData.length > 0 ?
                            importData.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ImportTableRows
                                            curElem={curElem}
                                            fetchDoc={fetchDoc}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={4} className='text-center'>
                                    {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                        "You have not imported a URL yet!"
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default ImportUrl