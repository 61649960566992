import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import CreateModalAssets from '../CreateModalAssets'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import VideoRows from './VideoRows'
import { FaSpinner } from 'react-icons/fa'

const VideoData = ({ createData, handleCreate, cid, handleClose, country, language, platformData }) => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [videoLoader, setVideoLoader] = useState({
        fetch: false
    })

    const fetchAllVideos = () => {
        let data = {
            cId: cid
        }
        setVideoLoader({
            ...videoLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-video-all", data, setVideos, videoLoader, setVideoLoader))
    }

    useEffect(() => {
        fetchAllVideos()
    }, [])
    return (
        <>
            <div className='title-head mt-5'>
                <h2>Video</h2>
                <button className='site-link' onClick={() => handleCreate("video")}><span>Create Video</span></button>
            </div>
            <div className='cont-box'>
                <div className='video-table'>
                    <div className='table-responsive'>
                        <table className='table text-center'>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            {videos.length > 0 ?
                                videos.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <VideoRows
                                                curElem={curElem}
                                                index={index}
                                                videos={videos}
                                                setVideos={setVideos}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} className="text-center load-icon-help mt-3">
                                        {
                                            videoLoader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                                <p style={{ fontSize: 15 }}>
                                                    You do not have video in this campaign.
                                                </p>
                                        }
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </div>

            <CreateModalAssets
                show={createData.show && createData.type === "video"}
                handleClose={handleClose}
                url={createData.url}
                type={createData.type}
                dimension={createData.dimension}
                fetchData={fetchAllVideos}
                cid={cid}
                country={country}
                language={language}
                platformData={platformData}
            />
        </>
    )
}

export default VideoData