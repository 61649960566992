import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaMinus, FaPlusCircle } from "react-icons/fa"
import { onAddAndRemoveHeadline, onChangeOptinVideo, onChangeOptinVideoHeadlines } from '../../../../../../Redux/Actions/ProjectActions'
const VideoSection = () => {
    const dispatch = useDispatch()
    const optinData = useSelector(state => state.optin.data)

    const handleChange = (e, index) => {
        if (index !== undefined) {
            dispatch(onChangeOptinVideoHeadlines(e.target.value, index))
        } else {
            const { name, value } = e.target
            dispatch(onChangeOptinVideo(name, value))
        }
    }

    const onAddHeadline = (type, index) => {
        dispatch(onAddAndRemoveHeadline(type, index))
    }

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Heading</span>
                <div className="inpLabel">
                    <label htmlFor="">Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Heading"
                        type="text"
                        name='heading'
                        value={optinData.videos.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Video Embed Code </span>
                <div className="inpLabel">
                    <label htmlFor="">Embed Code</label>
                    <textarea
                        className="inpLabel-inp"
                        placeholder="Enter Embed Code Here"
                        type="text"
                        name='src'
                        value={optinData.videos.src}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <div className='heading-action-box'>
                    <span className="labelTxt">Headlines </span>
                    <span>
                        <FaPlusCircle style={{ color: "" }} onClick={() => onAddHeadline("add")} />
                    </span>
                </div>
                {optinData.videos.headLines.length > 0 ?
                    optinData.videos.headLines.map((curElem, index) => {
                        return (
                            <div className="inpLabel mb-1" key={index}>
                                <label htmlFor="">Headline {index + 1}</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Enter Text Here "
                                    type="text"
                                    value={curElem.headline}
                                    onChange={(e) => handleChange(e, index)}
                                />
                                <span className='video-add-actions'>
                                    <FaMinus onClick={() => onAddHeadline("remove", index)} />
                                </span>
                            </div>
                        )
                    })
                    : null}



            </div>

        </div>
    )
}

export default VideoSection