import React, { useState } from 'react'
import { FaRegEdit, FaRegEye } from "react-icons/fa"
import { BsTrash } from "react-icons/bs"
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { useDispatch } from 'react-redux'
import { onDeleteKnowledgeTableRow } from '../../../../../Redux/Actions/ProjectActions'
import { Link } from 'react-router-dom'
import dummyText from "../../../../../assets/images/dummytextimage.png"
const UploadTableRows = ({ curElem, fetchDoc }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            loader: true,
            confirmButtonName: "Deleting"
        })
        let data = {
            id: curElem.id,
            cId: curElem.cId,
            type: "document"

        }
        dispatch(onDeleteKnowledgeTableRow(data, fetchDoc, sweet, setSweet))
    }

    return (
        <tr>
            <td>
                <img
                    src={curElem.type === "images" ? curElem.url : dummyText}
                    style={{ width: 100, height: 60, objectFit: "contain" }}
                />
            </td>
            <td>{curElem.data.slice(0, 50)}...</td>
            <td>{curElem.created.slice(0, 10)}</td>
            <td className='text-end'>
                <a className='link-icon' title='View' target='_blank' href={curElem.url}><FaRegEye /></a>
                <Link className='link-icon' title='Edit' style={{ cursor: "pointer" }} to={`/projects/update-document?cid=${curElem.cId}&id=${curElem.id}&type=u`}><FaRegEdit /></Link>
                <span className='link-icon' title='Delete' style={{ cursor: "pointer" }} onClick={onDelete}><BsTrash /></span>
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this document?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default UploadTableRows