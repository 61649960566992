import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCaretDownFill } from 'react-icons/bs'
import { MdPersonOutline, MdLockOutline, MdPersonAddAlt, MdStarBorder, MdOutlineHelpOutline, MdOutlineLogout } from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import brightness from "../../assets/images/brightness.png"
import { AiOutlineMergeCells, AiOutlineSetting } from "react-icons/ai";

const Header = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)
    const [scroll, setScroll] = useState(false)
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    }

    const handleOut = () => {
        dispatch(logoutUser())

    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>
            <header className={scroll ? "site-header fixed" : "site-header"}>
                <div className="container">
                    <div className="header-main">
                        <div className="header-left">
                            <NavLink className="logo" to="/dashboard">
                                <img className="logo-light" alt="" src={rebrand.data ? rebrand.data.logo : require('../../assets/images/logo.png')} />
                                <img className="logo-dark" alt="" src={rebrand.data ? rebrand.data.logo : require('../../assets/images/logo-dark.png')} />
                            </NavLink>
                            <div className={isActive ? 'mobClick open' : 'mobClick'} onClick={toggleClass}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>

                        <div>
                            <div className={isActive ? 'site-nav act' : 'site-nav'} onClick={toggleClass}>
                                <ul>
                                    <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                    <li><NavLink to="/projects">Projects</NavLink></li>
                                    {+auth.user.isClientAccount !== 1 ?
                                        <>
                                            <li><NavLink to="/integration">Integration</NavLink></li>
                                        </> : null}
                                    <li><NavLink to="/help">Help</NavLink></li>

                                </ul>
                            </div>
                        </div>

                        <div className="header-right">
                            <div className="profile-drop">
                                <Dropdown>
                                    <Dropdown.Toggle variant="tt">
                                        <div className="pro-pic">
                                            <img alt="" src={auth.user.profile} />
                                            <div className="pro-stat"></div>
                                        </div>
                                        <BsCaretDownFill />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>

                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    <li><NavLink to="/my-account"><span><MdPersonOutline /> </span> My Account</NavLink></li>
                                                    <li><NavLink to="/privacy"><span><MdLockOutline /> </span> Privacy</NavLink></li>
                                                    {+auth.user.isVirtualAccount === 1 ? "" :
                                                        memberShip.includes("enterprise") || memberShip.includes("fastpass") || memberShip.includes("bundle") ?
                                                            <>
                                                                <li><NavLink to="/add-account"><span><MdPersonAddAlt /> </span> Account Management</NavLink></li>
                                                                <li><NavLink to="/settings"><span><AiOutlineSetting /></span> Settings</NavLink></li>
                                                            </>
                                                            : ""

                                                    }
                                                    {memberShip.includes("professional") || memberShip.includes("professional-lite") || memberShip.includes("fastpass") || memberShip.includes("bundle") ?
                                                        <li> <NavLink to="/reel-merge"><span><AiOutlineMergeCells /> </span> Reelmerge</NavLink></li>
                                                        : ""
                                                    }

                                                    {memberShip.includes("ultimate") || memberShip.includes("enterprise") || memberShip.includes("fastpass") || memberShip.includes("bundle") || memberShip.includes("dfy") ?
                                                        <li><NavLink to="/upgrade"><span><MdStarBorder /> </span> Upgrade</NavLink></li>
                                                        : ""
                                                    }
                                                </>

                                            }


                                            <li><NavLink to="/help"><span><MdOutlineHelpOutline /> </span> Help</NavLink></li>
                                            <li><NavLink to="" onClick={handleOut}><span><MdOutlineLogout /> </span> Log Out</NavLink></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;