import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { IoImagesOutline } from "react-icons/io5"
import { BiUserVoice } from "react-icons/bi"
import { FaLink } from "react-icons/fa6"
import { MdOutlineAnimation } from "react-icons/md"
import { HiViewGrid } from "react-icons/hi"
import { FiSettings } from "react-icons/fi"
import { RiUserFill } from 'react-icons/ri';
const EditorNav = () => {
    return (
        <Nav variant="n" className="navTab">
            <Nav.Item>
                <Nav.Link eventKey="first" title="Scripts"><i class="icon-story"></i></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="second" title="Text"><i class="icon-text"></i></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="background" title="Background Media"><IoImagesOutline /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="layermedia" title="Layer Media"><i class="icon-media"></i></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="fourth" title="Music"><i class="icon-audio"></i></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="voiceover" title="Voiceover"><BiUserVoice /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="sadTalker" title="Talking Head"> <RiUserFill  /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="transition" title="Transition"><FaLink /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="animation" title="Animation"><MdOutlineAnimation /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="templates" title="Templates"><HiViewGrid /></Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="fifth" title="Settings"><FiSettings /></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="sixth" title="Credits"><i class="icon-credit"></i></Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default EditorNav