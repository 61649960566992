import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { onCreateNew, onPlatform } from '../../../../Redux/Actions/ProjectActions'
import { AiOutlineClose } from 'react-icons/ai'
import Plateform from '../CreateProject/Plateform'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'

const CreateModalAssets = (props) => {
    const dispatch = useDispatch()
    const allowedType = ["blog", "opt-in", "landing page"]
    const [loader, setLoader] = useState(false)
    const [createData, setCreateData] = useState({
        name: "",
        language: "English",
        country: "United States",
        languageCode: "",
        dimension: "16x9",
        style: "engaging",
        cId: props.cid,
        title: ""
    })

    const handleChange = (e) => {
        let { name, value } = e.target
        if (name === "language") {
            const lang = props.language.find(({ name }) => name === value)
            if (+lang.polly_support === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Warning...',
                    text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                    confirmButtonColor: "#3f429b"
                })
            }
        }

        if (name === "dimension") {
            value = value === "1920x1080" ? "16x9" : "9x16"
        }
        setCreateData({
            ...createData,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {}
        obj = { ...createData }
        let count = props.country.find(({ country_name }) => country_name === createData.country)
        let lang = props.language.find(({ name }) => name === createData.language)
        obj.languageCode = `${lang.code}-${count.code}`
        if (props.type !== "video") {
            delete obj.dimension
            delete obj.style
        }
        if (props.type === "ebook") {
            props.handleShowTemp(obj)
            props.handleClose()
        } else {
            setLoader(true)
            dispatch(onCreateNew(props.url, obj, props.fetchData, props.handleClose, setLoader))
        }
    }
    return (
        <Modal className='theme-modal video-modal xl' show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <h3 className='text-capitalize'>Create {props.type}</h3>
                <form className="p-4 row" onSubmit={handleSubmit}>
                    <div className='common-input-wrap mt-0 col-12'>
                        <label htmlFor="" className='text-capitalize'>{allowedType.includes(props.type) ? "Enter Product" : props.type} Name</label>
                        <input
                            className='common-input'
                            type="text"
                            placeholder={`Enter${props.type === "landing page" ? " Product" : ""} Name Here`}
                            name='name'
                            onChange={handleChange}
                            value={createData.name}
                            required
                        />
                    </div>
                    {props.type === "ebook" ?
                        <div className='common-input-wrap mt-0 col-12'>
                            <label htmlFor="" className='text-capitalize'>{props.type === "bonus page" ? "Enter Product" : props.type} Title</label>
                            <input
                                className='common-input'
                                type="text"
                                placeholder="Enter Title Here"
                                name='title'
                                onChange={handleChange}
                                value={createData.title}
                                required
                            />
                        </div>

                        : null}
                    <div className='common-input-wrap mt-0 col-6'>
                        <label htmlFor="">Country</label>
                        <select
                            className='common-input'
                            value={createData.country}
                            onChange={handleChange}
                            name='country'
                        // required
                        >
                            <option value={""}>Select Country</option>
                            {props.country.length > 0 ?
                                props.country.map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                    )
                                })
                                : ""}
                        </select>

                    </div>
                    <div className='common-input-wrap mt-0 col-6'>
                        <label htmlFor="">Language</label>
                        <select
                            className='common-input'
                            value={createData.language}
                            onChange={handleChange}
                            name='language'
                            required
                        >
                            <option value={""}>Select Language</option>
                            {props.language.length > 0 ?
                                props.language.filter((lang) => {
                                    return +lang.id !== 40
                                }).map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.name}>{curElem.name}</option>
                                    )
                                })
                                : ""}
                        </select>
                    </div>
                    {props.type === "video" ?

                        <>
                            <div className='common-input-wrap  col-6'>
                                <label htmlFor="">Plateform</label>
                                <select
                                    name="dimension"
                                    className="common-input"
                                    onChange={handleChange}
                                    required
                                    style={{ textTransform: "capitalize" }}
                                >
                                    <option value={"1920x1080"}>{"Standard YouTube Videos"}</option>
                                    {props.platformData ?
                                        <>
                                            <Plateform
                                                data={props.platformData.Facebook}
                                            />
                                            <Plateform
                                                data={props.platformData.Instagram}
                                            />
                                            <Plateform
                                                data={props.platformData.LinkedIn}
                                            />
                                            <Plateform
                                                data={props.platformData.Pinterest}
                                            />
                                            <Plateform
                                                data={props.platformData.Snapchat}
                                            />
                                            <Plateform
                                                data={props.platformData.TikTok}
                                            />
                                            <Plateform
                                                data={props.platformData.Twitter}
                                            />
                                            <Plateform
                                                data={props.platformData.YouTube}
                                            />
                                        </>

                                        : ""}
                                </select>
                            </div>
                            <div className='common-input-wrap  col-6'>
                                <label htmlFor="">Style</label>
                                <select
                                    className="common-input"
                                    name="style"
                                    onChange={handleChange}
                                    value={createData.style}
                                    required
                                >
                                    <option value={""}>Select Style</option>
                                    <option value={"VSL"}>VSL</option>
                                    <option value={"engaging"}>Engaging</option>
                                    <option value={"promo"}>Promo</option>
                                    <option value={"informative"}>Informative</option>
                                    <option value={"fun"}>Fun</option>
                                    <option value={"news"}>News</option>
                                    <option value={"controversial"}>Controversial</option>
                                    <option value={"Product review"}>Product review</option>
                                </select>
                            </div>
                        </> : null}
                    <div className='common-input-wrap mt-4'>
                        <button className='site-link full' type='submit'><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin" /> </> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModalAssets