import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/general-sans.css'
import '../src/assets/css/icomoon.css'
import './App.css';
import './CommonCss.css';

import './assets/css/light-theme.css'


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home/Home';
import Upgrade from './components/pages/upgrade/Upgrade';
import { useEffect } from 'react';
import { loadUser } from './Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from './Redux/Actions/AlertActions';
import AccountManagement from './components/pages/AccountManagement/AccountManagement';
import ForgotPassword from './components/pages/Authentication/ForgotPassword';
import Login from './components/pages/Authentication/Login';
import PrivateRoute from './components/CommonComponent/PrivateRoute';
import Alert from './components/CommonComponent/Alert';
import Privacy from './components/pages/MyAccount/Privacy';
import MyAccount from './components/pages/MyAccount/MyAccount';

import MasterLogin from './components/pages/Authentication/MasterLogin';
import ResetPassword from './components/pages/Authentication/ResetPassword';
import Help from './components/pages/HelpSupport/Help';
import Training from './components/pages/HelpSupport/Training';
import SupportArticle from './components/pages/HelpSupport/SupportArticle';
import { onFetchFont } from './Redux/Actions/CommonActions';
import Projects from './components/pages/Projects/Projects';
import Integration from './components/pages/Integration/Integration';
import MainProjects from './components/pages/Projects/MainProjects';
import KnowledgeBrain from './components/pages/Projects/CreateProject/KnowledgeBrain';
import EditDocs from './components/pages/Projects/CreateProject/EditDocs';
import AssetPage from './components/pages/Projects/AssetPage/AssetPage';
import UpdateScript from './components/pages/Projects/AssetPage/VideoAssets/UpdateScript';
import Editor from './components/pages/Projects/AssetPage/VideoAssets/Editor/Editor';
import EditBonusPage from './components/pages/Projects/AssetPage/BonusAssets/BonusPage/EditBonusPage';
import UpdateBlog from './components/pages/Projects/AssetPage/BlogArticleAssets/UpdateBlog';
import ScrollTop from './components/CommonComponent/ScrollTop';
import UpdateEbook from './components/pages/Projects/AssetPage/EbookAssets/UpdateEbook';
import UpdateOptin from './components/pages/Projects/AssetPage/OptinAssets/OptinUpdate/UpdateOptin';
import UpdateBanner from './components/pages/Projects/AssetPage/BannerAssets/UpdateBanner/UpdateBanner';
import TemplateCustomize from './components/pages/Projects/AssetPage/OptinAssets/OptinUpdate/TemplateCustomize';
import ReelMerge from './components/pages/ReelMerge/ReelMerge';
import FlipBook from './components/pages/Projects/AssetPage/EbookAssets/FlipBook';
import Settings from './components/pages/Settings/outreach/Settings';

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }


  useEffect(() => {
    fetchUser()
    fetchFonts()
  }, [])

  return (
    <div className="App">
      <Alert />
      <div>
        <Router>
          <ScrollTop />
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/dashboard" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/add-account" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
            <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /></PrivateRoute>} />
            <Route exact path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /></PrivateRoute>} />
            <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />
            <Route exact path="/template-customize" element={<PrivateRoute><TemplateCustomize /></PrivateRoute>} />
            <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /></PrivateRoute>} />
            <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />


            <Route exact path="/projects" element={<PrivateRoute><MainProjects /></PrivateRoute>} >
              <Route index element={<Projects />} />
              <Route path="knowledge-brain" element={<KnowledgeBrain />} />
              <Route path="update-document" element={<EditDocs />} />
              <Route path="assets" element={<AssetPage />} />
              <Route path="editor" element={<Editor />} />
              <Route path="update-script" element={<UpdateScript />} />
              <Route path="edit-landing" element={<EditBonusPage />} />
              <Route path="edit-blog" element={<UpdateBlog />} />
              <Route path="update-ebook" element={<UpdateEbook />} />
              <Route path="update-optin" element={<UpdateOptin />} />
              <Route path="update-ads" element={<UpdateBanner />} />
              <Route path="your-book" element={<FlipBook />} />
            </Route>

            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
