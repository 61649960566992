import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onCheckedAddon } from '../../../../../../Redux/Actions/ProjectActions';


const AddonDomain = () => {
    const dispatch = useDispatch()

    const landingData = useSelector(state => state.bonus.data)

    const [loader, setLoader] = useState(false)
    const [domain, setDomain] = useState(landingData.domain.domain)
    const [toggle, setToggle] = useState(false)

    const handleChange = (e) => {
        const { value } = e.target
        setDomain(value)
    }

    const handleClick = () => {
        if (!domain) {
        } else {
            setLoader(true)
            let data = {
                cId: landingData.cId,
                domainName: domain,
                landingPageId: landingData.id,
            }
            dispatch(onCheckedAddon(data, setLoader, setDomain))
        }

    }

    const handleToggle = () => {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }
    }

    return (
        <div>
            <div className="inpLabelWrap mt-2">
                <span className="labelTxt">Add your custom domain name</span>
                <div className="inpLabel">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12 mb-3">
                            <label htmlFor="liveLink">Enter your domain name</label>
                            <input
                                className="inpLabel-inp"
                                type="text"
                                id="liveLink"
                                placeholder='Enter Domain'
                                value={domain}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <button
                                className="inpBtn-icon cursor-pointer"
                                style={{ width: "100%", borderRadius: "10px", border: "none", position: "static", backgroundColor: domain === "" && undefined ? "gray" : "" }}
                                onClick={handleClick}
                            >
                                {loader ? <>Checking & Adding<i className="fa fa-spinner fa-spin mx-1" /></> : " Check & Add"}
                            </button>

                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-12 ">
                            <span
                                style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    fontSize: "14px",
                                    color: "#3f429b",
                                    fontFamily: "poppins,Sans-serif",
                                    marginLeft: "10px",
                                }}
                                onClick={handleToggle}
                            >
                                How to Add a Custom Domain?
                            </span>

                            {toggle ? <div className="add_on_domain text-dark">
                                <ul className="list_domain">
                                    <li>
                                        Please use top-level domains only (sub-domains
                                        won’t work).
                                    </li>
                                    <li>
                                        Please enter your domain above without any www, http or https (domain.com for example).
                                    </li>
                                    <li>
                                        Enter your domain name for example "mydomain.com"
                                        (without the quotation marks) and click the "Check & Add" button.
                                    </li>
                                    <li>
                                        Copy this IP address for the A Record:
                                        72.52.158.199
                                    </li>
                                    <li>
                                        Log into your domain registrar account and find
                                        the option to add Host Records, which can usually
                                        be found in the Advanced DNS section. If you're
                                        struggling to find this, perform a Google search
                                        for "[Domain Registrar Here] how to change host
                                        records".
                                    </li>
                                    <li>Select your type as "A Record".</li>
                                    <li>Set Host as "@".</li>
                                    <li>
                                        Paste the copied IP Address from step 4 into the
                                        value field.
                                    </li>
                                    <li>Leave TTL as Automatic or 14400.</li>
                                    <li>Click Save and you're done.</li>
                                </ul>
                                <span className='text-danger' style={{ fontSize: "16px", fontWeight: "500" }}>
                                    Note: It can take up to 48-72 hours to reflect.
                                </span>
                            </div> : ""}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AddonDomain