import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import HomeBanner from './HomeBanner';
import Webinar from './Webinar';
import { useSelector } from 'react-redux';
import VideoProjects from '../Projects/VideoProjects';
import { Link } from 'react-router-dom';
import { appName } from '../../../Global/Global';
import TitleBar from '../../title-bar/TitleBar';

const Home = () => {
    const rebrand = useSelector(state => state.rebrand)

    return (
        <>
            <TitleBar title="Dashboard" />
            <div className='front-page'>
                <Header />
            </div>

            <HomeBanner />

            <div className="video-project text-center">
                <h2 >Recent Projects</h2>
                <VideoProjects />

                <Link className='site-link mt-5' to={'/projects'}><span>View All</span></Link>
            </div>


            <section className='image-block pt-0'>
                <div className='container sm'>
                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-1.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Hands Free</h6>
                                    <h2>Take a Year Worth of Content:</h2>
                                    <p>Effortlessly create a continuous stream of engaging videos, articles, eBooks, and landing pages, saving you time and effort while keeping your marketing strategy consistently fresh and vibrant. Say goodbye to content scarcity and hello to abundant creativity.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-2.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Auto Find Clients for Your Services: </h2>
                                    <p>Our automated system actively seeks out potential clients interested in your marketing services. We connect you with businesses, websites, and individuals looking to enhance their online presence. Focus on what you do best while {rebrand.data ? rebrand.data.name : appName} brings the clients to your doorstep.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='image-block-single'>
                        <div className='row align-items-center'>
                            <div className='col-sm-6 col-xl-7'>
                                <div className='image-block-img'><img alt='' src={require('../../../assets/images/block-img-3.png')} /></div>
                            </div>
                            <div className='col-sm-6 col-xl-5'>
                                <div className='image-block-txt'>
                                    <h6>Clients</h6>
                                    <h2>Take on 100s of Clients Effortlessly: </h2>
                                    <p>{rebrand.data ? rebrand.data.name : appName} empowers you to efficiently manage and serve hundreds of clients simultaneously. From content creation to campaign optimization, allowing you to grow your client base without overwhelming your resources. Expand your reach, boost your profits, and take on clients effortlessly with {rebrand.data ? rebrand.data.name : appName} by your side.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Webinar />
            <Footer />
        </>
    )

}

export default Home;