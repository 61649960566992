import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi"
import { FaSpinner } from "react-icons/fa"
import Variations from "./Variations";
import { fetchData } from "../../../../../../../Redux/Actions/CommonActions";
import { onUpdateTemplate } from "../../../../../../../Redux/Actions/VideoActions";

const EditTemplate = ({ setPageLoader, setPreLoader }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [tempLoader, setTempLoader] = useState(false)
    const [tempId, setTempId] = useState(false)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({ fetch: false })

    const fetchTemp = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-template", {}, setData, loader, setLoader))
    }

    const handleSelectTemplate = (id) => {
        if (campaign.templateId !== id) {
            let data = {
                videoId: campaign.id,
                newTemplateId: id,
                dimension: campaign.dimension === "16x9" ? "1920X1080" : "1080X1920"
            }
            setTempLoader(true)
            setTempId(id)
            dispatch(onUpdateTemplate(data, navigate, setPageLoader,setTempLoader,setTempId))
        }
    }

    useEffect(() => {
        fetchTemp()
    }, [])

    return (
        <>
            <h2 className='tab-title'>Templates</h2>
            <div className="left-scroll">
                <div className="row sm">

                    {
                        data.length > 0 ?
                            data.map((curElem, index) => {
                                let imageData = campaign.dimension === "9x16" ? curElem.preview_vertical : curElem.previewImage
                                return (
                                    <div className="col-6" key={index}>
                                         <div
                                            className="photo-single"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSelectTemplate(curElem.id)}
                                        >
                                            <img alt="" src={imageData} />
                                            {+campaign.templateId === +curElem.id ?
                                                <div className='select-tile-style' >
                                                    <GiCheckMark />
                                                </div>
                                                : ''
                                            }
                                            {tempLoader && +curElem.id === +tempId?
                                                <div className='select-tile-style' >
                                                    <FaSpinner className="spin" />
                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                )
                            })
                            :
                            loader.fetch ? <div className='col-12 text-center mt-3' ><FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /></div> : ""
                    }
                </div>

                <h6 className="pt-4">Variations</h6>

                <Variations setPreLoader={setPreLoader} />

            </div>
        </>
    )
}

export default EditTemplate;