import React, { useState, useEffect } from 'react';
import { BsTrash } from "react-icons/bs"
import TitleBar from '../../../title-bar/TitleBar';
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { onFetchCamapign, onPlatform, onUpdateCampaign } from '../../../../Redux/Actions/ProjectActions';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import VideoData from './VideoAssets/VideoData';
import BlogArticleData from './BlogArticleAssets/BlogArticleData';
import BonusData from './BonusAssets/BonusData';
import EbookData from './EbookAssets/EbookData';
import OptinData from './OptinAssets/OptinData';
import BannerData from './BannerAssets/BannerData';
import { FaSpinner } from 'react-icons/fa';

const AssetPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cid } = queryString.parse(location.search)
    const [campaignData, setCampaignData] = useState({
        name: ""
    })
    const [headingLoader, setHeadingLoader] = useState(true)
    const [country, setCountry] = useState([])
    const [language, setLanguage] = useState([])

    const [platformData, setPlatformData] = useState(false)
    const [createData, setCreateData] = useState({
        url: "",
        type: "",
        show: false,
        fetch: false
    })
    const handleGotoAssets = (e) => {
        if (!e.target.checked) {
            navigate(`/projects/knowledge-brain?cid=${cid}`)
        }
    }

    const handleBlur = () => {
        if (campaignData.name !== "") {
            dispatch(onUpdateCampaign(campaignData))
        }
    }

    const handleCreate = (type) => {
        if (type === "video") {
            setCreateData({
                ...createData,
                url: "create-video",
                type: "video",
                show: true,
                dimension: campaignData.dimension
            })
        } else if (type === "bonus") {
            setCreateData({
                ...createData,
                url: "create-landing",
                type: "landing page",
                show: true,
            })
        }
        else if (type === "ebook") {
            setCreateData({
                ...createData,
                url: "create-ebook",
                type: "ebook",
                show: true,
            })
        }
        else if (type === "optin") {
            setCreateData({
                ...createData,
                url: "create-optin",
                type: "opt-in",
                show: true,
            })
        }
        else if (type === "blog") {
            setCreateData({
                ...createData,
                url: "create-blog",
                type: "blog",
                show: true,
            })
        }
        else if (type === "banner") {
            setCreateData({
                ...createData,
                url: "create-ads",
                type: "banner",
                show: true,
            })
        }
    }

    const handleClose = () => {
        setCreateData({
            ...createData,
            show: false,
        })
    }

    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }

    const fetchPlateform = () => {
        dispatch(onPlatform(setPlatformData))
    }

    const fetchCampaign = () => {
        let data = {
            id: cid
        }
        dispatch(onFetchCamapign(data, setCampaignData, setHeadingLoader))
    }

    useEffect(() => {
        fetchCampaign()
        fetchPlateform()
        fetchLanguage()
        fetchCountries()
    }, [])

    return (
        <>
            <TitleBar title="Assets" />
            <Header />

            <section className='page-wrap'>
                <div className='camp-name'>
                    <div className='container'>
                        <div className='title-wrap'>
                            <h2>
                                {headingLoader ?
                                    <FaSpinner className='spin' style={{ color: "#3f429b" }} /> :
                                    <>
                                        <input
                                            type='text'
                                            value={campaignData.name}
                                            onChange={(e) => setCampaignData({
                                                ...campaignData,
                                                name: e.target.value
                                            })}
                                            style={{ width: campaignData.name.length + "ch", paddingLeft: "5px" }}
                                            onBlur={handleBlur}
                                            className='camp-edit ps-1'
                                        /> | Assets
                                    </>
                                }

                            </h2>
                            <div className="opt-select  text-dark" style={{ fontSize: 12 }}>
                                <span ><strong>Knowledge Brain</strong></span>
                                <label className="switch mx-1">
                                    <input type="checkbox" checked={true} onChange={handleGotoAssets} />
                                    <span className="slider round"></span>
                                </label>
                                <span style={{ color: "#202265" }}><strong>Assets</strong></span>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <VideoData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                        platformData={platformData}
                    />

                    <BonusData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                    />

                    <EbookData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                    />
                    <OptinData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                    />

                    <BlogArticleData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                    />

                    <BannerData
                        createData={createData}
                        cid={cid}
                        handleClose={handleClose}
                        handleCreate={handleCreate}
                        country={country}
                        language={language}
                    />
                </div>
            </section >

            <Footer />
        </>
    )

}

export default AssetPage;