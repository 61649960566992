import React, { useEffect } from 'react'
import htmlToPdf from "html2pdf.js"
import ContentEditable from 'react-contenteditable';
const PdfGeneration = (props) => {

    const generateFuntion = async () => {
        let id = document.getElementById("content-pdf")
        let newData = id.innerHTML
        const pdfOptions = {
            margin: 10,
            filename: `ebook.pdf`,
            html2canvas: {
                scale: 2,
                image: { type: 'jpeg', quality: 0.98 },
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait'
            },
            pagebreak: { mode: 'avoid-all', before: '.main-section-content' },
            enable_local_file_access: true
        };

        await htmlToPdf().from(newData).set(pdfOptions).save().then(() => {
            props.setIsDownload(false)
        });

    }

    const styles = {
        container: {
            margin: 5,
            border: "1px solid #000",
            padding: 10,
            color: "#000"
        },
        footer: {
            fontSize: 15,
            position: "absolute",
            bottom: 11,
            right: 12,
            fontWeight: "bold"
        }
    }

    useEffect(() => {
        if (props.isDownload) {
            generateFuntion()
        }
    }, [props.isDownload])

    return (
        <>
            <div id='content-pdf' style={{display:"none"}} >
                <div style={{ position: "relative" }}>
                    {props.title ?
                        <div style={{ ...styles.container, display: "flex", alignItems: "center", textAlign: "center", height: "100vh", fontSize : 30, fontWeight: "bold" }}>
                                {props.title}
                        </div>
                        : null}
                    <div className='main-section-content' style={{ ...styles.container, minHeight: "100%" }}>
                        <div style={{ marginBottom: 50 }}>
                            <ContentEditable
                                html={props.text}
                                tagName='span'
                            />
                        </div>
                        <div style={{ ...styles.footer }}>
                            This PDF is generated by AI-Sellers
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PdfGeneration