import React, { useEffect, useState } from 'react'

import { fetchData } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import MergeCards from './MergeCard'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import Pagination from '../../CommonComponent/Pagination'

const MergeProjects = ({ filterObj }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })

    const [sortHeader, setSortHeader] = useState({
        name: "",
        created: ""
    })
    const [campaignsData, setCampaignsData] = useState([])
    const [videos, setVideos] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;

    const currentTodo =
        filterObj.searchKey !== "" ? campaignsData : campaignsData.slice(indexofFirstTodo, indexofLastTodo);

    const handleSort = (type) => {
        const data = [...videos]
        if (type === "name") {
            if (sortHeader.name) {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    name: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    name: true,
                });
            }
        }
        else if (type === "created") {
            if (sortHeader.created) {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() > b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() < b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: true,
                });
            }
        }
        setVideos(data)
    }

    const fetchMerge = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-merge-campaigns", {}, setVideos, loader, setLoader))
    }

    useEffect(() => {
        if (filterObj.searchKey === "") {
            setCampaignsData(videos)
        }
        else {
            let dataArr = videos.filter((item1) => {
                return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            })
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 10
            })
            setCampaignsData(dataArr)
        }

    }, [filterObj, videos])

    useEffect(() => {
        setPagination({
            ...pagination,
            currentPage: 1,
            totalItemOnPage: 10
        })
        fetchMerge()
    }, [])


    return (

        <div className='video-table'>
            <div className='table-responsive'>
                <table className='table'>
                    <tr>
                        <th></th>
                        <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>Name<span style={{ color: "darkgray" }}> {sortHeader.name === "" ? <FaSort /> : sortHeader.name ? <FaSortDown /> : <FaSortUp />}</span></th>
                        <th>Status</th>
                        <th onClick={() => handleSort("created")} style={{ cursor: "pointer" }}>Created<span style={{ color: "darkgray" }}  > {sortHeader.created === "" ? <FaSort /> : sortHeader.created ? <FaSortDown /> : <FaSortUp />}</span></th>
                        <th></th>
                    </tr>
                    {currentTodo.length > 0 ?
                        currentTodo.filter((proj) => {
                            return proj.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
                        }).map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <MergeCards
                                        curElem={curElem}
                                        videos={videos}
                                        setVideos={setVideos}
                                    />
                                </React.Fragment>
                            )
                        })
                        :
                        videos.length === 0 ?
                            <tr>
                                <td colSpan={5} className='text-center'>
                                    {
                                        loader.fetch ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                                            :
                                            <p style={{ fontSize: 18 }}>
                                                You do not have any "Reelmerge Projects" in your account.
                                            </p>
                                    }
                                </td>
                            </tr>
                            : ""
                    }
                </table>
            </div>

            <Pagination
                listData={campaignsData}
                pagination={pagination}
                setPagination={setPagination}
                currentTodo={currentTodo}
                listArr={videos}
                loader={loader.fetch}
            />
        </div>
    )
}

export default MergeProjects