import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import UploadTableRows from './UploadTableRows'
import { onUploadDocs } from '../../../../../Redux/Actions/ProjectActions'
import { FiUpload } from 'react-icons/fi'
import Swal from 'sweetalert2'

const UploadDoc = ({ cId, gotoAsset, setGotoAsset }) => {
    let allowDocType = ["application/msword", "application/pdf"]
    let allowImgType = ['image/png', 'image/jpg', 'image/jpeg']
    const dispatch = useDispatch()
    const [uploadData, setUploadData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        upload: false
    })

    const onInputFile = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (e.target.files[0].size <= 300000000) {
                if (allowDocType.includes(e.target.files[0].type)) {
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    formData.append("upload_type", "docx")
                    dispatch(onUploadDocs(formData, cId, "docx", fetchDoc, loader, setLoader))
                }
                else if (allowImgType.includes(e.target.files[0].type)) {
                    if (e.target.files[0].size < 5000000) {
                        setLoader({
                            ...loader,
                            upload: true
                        })
                        formData.append('upload_type', "images")
                        dispatch(onUploadDocs(formData, cId, "images", fetchDoc, loader, setLoader))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size for images is 5MB!',
                            confirmButtonColor: "#3f429b"
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Invalid file type!',
                        confirmButtonColor: "#3f429b"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 300MB!',
                    confirmButtonColor: "#3f429b"
                })
            }
        }
    }

    const fetchDoc = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-document-all", data, setUploadData, loader, setLoader))
    }

    useEffect(() => {
        if (uploadData.length > 0) {
            const isData = uploadData.find(({ data }) => data !== "")
            if (isData) {
                setGotoAsset({
                    ...gotoAsset,
                    upload: true
                })
            } else {
                setGotoAsset({
                    ...gotoAsset,
                    upload: false
                })
            }
        }
    }, [uploadData])

    useEffect(() => {
        fetchDoc()
    }, [])
    return (
        <>
            <div className='upload-box' style={{ border: "1px dashed #3f429b" }}>
                <div className='upload' style={{ height: "100%", width: "100%", color: "#3f429b" }}>
                    {loader.upload ?
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                        :
                        <>
                            <FiUpload />
                            <input type="file" onChange={onInputFile} style={{ cursor: "pointer" }} />
                            <p><strong>Upload PDF, Image(JPG, JPEG & PNG)</strong></p>
                        </>
                    }
                </div>
            </div>
            <h2 className='pt-5'>Stored Documents</h2>
            <div className='doc-table'>
                <div className='table-responsive'>
                    <table className='table'>
                        <tr>
                            <th></th>
                            <th>Content</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                        {uploadData.length > 0 ?
                            uploadData.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <UploadTableRows
                                            curElem={curElem}
                                            fetchDoc={fetchDoc}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={4} className='text-center'>
                                    {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                        "You have not uploaded a file yet!"
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </div>

        </>
    )
}

export default UploadDoc