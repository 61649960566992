import produce from "immer"

const initialState = {
    data: false
}

export const OptinReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_GET_OPTIN":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                if (!obj.videos.headLines) {
                    obj.videos.headLines = [{ headline: "" }]
                }
                draft.data = obj
            })

        case "ON_TOGGLE_OPTINS":
            return produce(state, (draft) => {
                if (action.payload.name === "headers") {
                    draft.data.headers.enable = action.payload.value
                } else if (action.payload.name === "style") {
                    draft.data.style.enable = action.payload.value
                } else if (action.payload.name === "videos") {
                    draft.data.videos.enable = action.payload.value
                } else if (action.payload.name === "contact") {
                    draft.data.contact.enable = action.payload.value
                } else if (action.payload.name === "aicoach") {
                    draft.data.aicoach.enable = action.payload.value
                } else if (action.payload.name === "footer") {
                    draft.data.footer.enable = action.payload.value
                }
            })

        case "ON_CHANGE_OPTIN_STYLE":
            return produce(state, (draft) => {
                draft.data.style = {
                    ...draft.data.style,
                    [action.payload.name]: action.payload.value
                }
            })
        case "ON_CHANGE_OPTIN_HEADER":
            return produce(state, (draft) => {
                draft.data.headers = {
                    ...draft.data.headers,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_OPTIN_VIDEO":
            return produce(state, (draft) => {
                draft.data.videos = {
                    ...draft.data.videos,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_ADD_REMOVE_VIDEO_HEADLINES":
            return produce(state, (darft) => {
                if (action.payload.type === "add") {
                    darft.data.videos.headLines.push({ headline: "" })
                }
                else {
                    darft.data.videos.headLines.splice(action.payload.index, 1)
                }
            })

        case "ON_CHANGE_HEADLINES":
            return produce(state, (darft) => {
                darft.data.videos.headLines[action.payload.index].headline = action.payload.value
            })


        case "ON_CHANGE_OPTIN_COACH":
            return produce(state, (draft) => {
                draft.data.aiCoach = action.payload.value
            })

        case "ON_CHANGE_OPTIN_CONTACT":
            return produce(state, (draft) => {
                draft.data.contact = {
                    ...draft.data.contact,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_OPTIN_CONTACT_STYLE":
            return produce(state, (draft) => {
                draft.data.contact.style = {
                    ...draft.data.contact.style,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_OPTIN_CONTACT_BUTTON":
            return produce(state, (draft) => {
                draft.data.contact.btn = {
                    ...draft.data.contact.btn,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_UPDATE_SOCIAL_CONTACT":
            return produce(state, (draft) => {
                const { type, name, value } = action.payload
                if (type === "facebook") {
                    draft.data.contact.socials.facebook = {
                        ...draft.data.contact.socials.facebook,
                        [name]: value
                    }
                } else if (type === "youtube") {
                    draft.data.contact.socials.youtube = {
                        ...draft.data.contact.socials.youtube,
                        [name]: value
                    }
                }
                else if (type === "twitter") {
                    draft.data.contact.socials.twitter = {
                        ...draft.data.contact.socials.twitter,
                        [name]: value
                    }
                } else if (type === "linkedin") {
                    draft.data.contact.socials.linkedin = {
                        ...draft.data.contact.socials.linkedin,
                        [name]: value
                    }
                } else if (type === "instagram") {
                    draft.data.contact.socials.instagram = {
                        ...draft.data.contact.socials.instagram,
                        [name]: value
                    }
                }
            })

        case "ON_CHANGE_OPTIN_FOOTER":
            return {
                ...state,
                data: {
                    ...state.data,
                    footer: {
                        ...state.data.footer,
                        [action.payload.name]: action.payload.value
                    }
                }
            }
        default:
            return state
    }
}