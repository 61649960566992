import React, { useState, useEffect } from 'react';
import { Search } from 'react-iconly'
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { fetchArticles, fetchVideos } from '../../../Redux/Actions/HelpActions';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import trainingImg from "../../../assets/images/trainingImg.png"
import { AiOutlineClose } from 'react-icons/ai'
import { appName } from '../../../Global/Global';

const Help = () => {
    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const help = useSelector(state => state.help)

    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help & Support" />
            <Header />

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Help & Support</h1>

                    <div className='help-banner'><img alt='' src={require('../../../assets/images/help.png')} /></div>

                    <div className='content-search'>
                        <h3 className='pb-4'>What do you want to know ?</h3>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search'
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* <div className='col-xl-2'>
                                <button className='site-link full'><span>Search</span></button>
                            </div> */}
                        </div>
                    </div>

                    <div className='help-block mt-3'>
                        <div className='row'>
                            {
                                help.article.length > 0 ?
                                    help.article.filter((art) => {
                                        return art.tags.toLowerCase().includes(q.toLowerCase())
                                    }).map((curElem, index) => {
                                        return (
                                            <div className='col-sm-6 col-lg-4 text-center' key={index}>
                                                <NavLink to={`/training?id=${curElem.id}`}>
                                                    <div className='help-block-single'>
                                                        <div className='help-icon' ><img src={curElem.logo} alt='' style={{ width: 100, background: "#618fca", padding: 15, borderRadius: 10, height: 100, objectFit: "contain" }} /></div>
                                                        <h3>{curElem.name}</h3>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className='col-sm-12 mt-3 text-center'>
                                        {
                                            loader.article ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                                                : ""
                                        }
                                    </div>
                            }
                        </div>
                    </div>

                    <div className='help-video'>
                        <h2 className='text-center'>Video Tutorials</h2>
                        <div className='row'>
                            {help.videos.length > 0 ?
                                help.videos.map((curElem, index) => {
                                    return (
                                        <div className='col-sm-6 col-lg-3' key={index} >
                                            <div className='help-video-single'  style={{cursor:"pointer"}} onClick={() => playVideo(curElem.url)}>
                                                <div className='video-banner'>
                                                    {/* <img src={curElem.thumbnail} alt="" /> */}
                                                    <img src={trainingImg} alt="" />

                                                </div>
                                                <p>{curElem.title}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className='col-sm-12 mt-3 text-center'>
                                    {
                                        loader.video ?
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                                            : ""
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    <div className='help-bottom'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <h3>Can't find your answer?</h3>
                                <p>We're here to help! From general communication to specifc {rebrand ? rebrand.name : appName} <br /> platform questions, please reach out so we can assist.</p>
                                <a target="_blank" className='site-link mt-4' href="https://vineasx.helpscoutdocs.com/" rel="noreferrer"><span>Contact Our Helpdesk</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </section >


            <Modal className='theme-modal video-modal xl' show={showModal} onHide={closeModal} centered>
                <Modal.Body>
                    <div className='modalClose' onClick={closeModal}><AiOutlineClose /></div>
                    <div className="ratio ratio-16x9">
                        <iframe id="frameSrc" className="embed-responsive-item" src={url} allowFullScreen title="help-videos"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

            <Footer />
        </>
    )

}

export default Help;