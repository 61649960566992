import React, { useState } from 'react'
import dummyIcon from "../../../assets/images/defaultIcon.png"
import { BsTrash } from 'react-icons/bs'
import { MdOutlineDownload } from 'react-icons/md'
import PublishModal from '../../pages/Projects/PublishModal'
import SweetAlert from '../../CommonComponent/SweetAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { CiGlobe } from 'react-icons/ci'

const MergeCard = ({ curElem, videos, setVideos }) => {

    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancel = () => {
        setSweet({
            enable: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-merge", data, videos, setVideos, setSweet))
    }

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = curElem.output_video
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }


    return (

        <tr>
            <td>
                <span className='vid-thumb'><img src={curElem.preview_image ? curElem.preview_image : dummyIcon} alt="" /></span>
            </td>
            <td>{curElem.name}</td>
            <td>
                {curElem.status === "Failed" ?
                    <span className='status-box' style={{ background: "#FF0000" }}>{curElem.status}</span> :
                    curElem.status === "Finished" ?
                        <span className='status-box' style={{ background: "#008000" }}>{curElem.status}</span> :
                        curElem.status === "Downloading Files" ?
                            <span className='status-box' style={{ background: "#FF7F50" }}>{curElem.status}</span> :
                            <span className='status-box' style={{ background: "#DAA520" }}>{curElem.status}</span>
                }
            </td>
            <td>{curElem.created}</td>
            <td>
                <a className='link-icon' title='Download' onClick={handleDownload}><MdOutlineDownload /></a>
                <a className='link-icon' title='Publish' onClick={() => handleShow(true)}><CiGlobe /></a>
                <a className='link-icon' title='Delete' onClick={() => onDelete(curElem.id)}><BsTrash /></a>
            </td>


            <PublishModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this video?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />

        </tr>
    )
}

export default MergeCard