import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import CreateModalAssets from '../CreateModalAssets'
import { useDispatch } from 'react-redux'
import BlogRow from './BlogRow'
import { FaSpinner } from 'react-icons/fa'

const BlogArticleData = ({ createData, handleCreate, cid, handleClose,  country, language }) => {
    const dispatch = useDispatch()
    const [blogs, setBlogs] = useState([])
    const [blogLoader, setBlogLoader] = useState({
        fetch: false
    })
    const fetchAllBlog = () => {
        let data = {
            cId: cid
        }
        setBlogLoader({
            ...blogLoader,
            fetch: true
        })
        dispatch(fetchData("list-blog", data, setBlogs, blogLoader, setBlogLoader))
    }

    useEffect(() => {
        fetchAllBlog()
    }, [])
    return (
        <>
            <div className='title-head mt-5'>
                <h2>Blog Article</h2>
                <button className='site-link' onClick={() => handleCreate("blog")}><span>Create Blog</span></button>
            </div>
            <div className='cont-box'>
                <div className='video-table'>
                    <div className='table-responsive'>
                        <table className='table text-center'>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            {blogs.length > 0 ?
                                blogs.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <BlogRow
                                                curElem={curElem}
                                                index={index}
                                                blogs={blogs}
                                                setBlogs={setBlogs}
                                                fetchAllBlog={fetchAllBlog}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} className="text-center load-icon-help mt-3">
                                        {
                                            blogLoader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                                <p style={{ fontSize: 15 }}>
                                                    You do not have blog articles in this campaign.
                                                </p>
                                        }
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </div>

            <CreateModalAssets
                show={createData.show && createData.type === "blog"}
                handleClose={handleClose}
                url={createData.url}
                type={createData.type}
                fetchData={fetchAllBlog}
                cid={cid}
                country={country}
                language={language}
            />
        </>
    )
}

export default BlogArticleData