import React, { useEffect, useState } from 'react';
// import modalCloseIcon from '../../images/modal-close.png';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
// import { onGetClient } from '../../Actions/ClientsAction';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io"
import AddSetupGoogle from './AddSetupGoogle';
import { AiOutlineClose } from 'react-icons/ai';



const GooglePage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const [state, setState] = useState({
        agencyId: aId,
        clientId: cId,
        facebookAccountId: false,
        businessManagerId: false,
        addAccountId: false,
        adCampaignId: false,
        adSetsId: false
    })
    const [loader, setLoader] = useState({
        pageLoader: true,
        submitLoader: false,
    })
    const [imageData, setImageData] = useState("")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (e) => {
        const { name, value } = e.target
        if ((name === "campaignId" || name === "adId") && value === "open") {
            handleShow()
        } else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        }
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        // dispatch(onGetClient(data, loader, setLoader))
    }
    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])

    // useEffect(() => {
    //     // Run to fetch Facebook Business Manager, Ad Account, Ad Campaigns, Ad sets
    //     dispatch(onFacebookFetch(state, 'facebook-account-fetch', setFacebookAccount, loader, setLoader))
    // }, [])

    // useEffect(() => {
    //     dispatch(onFacebookFetch(state, 'facebook-business-manager-fetch', setBusinessManager, loader, setLoader))
    // }, [facebookAccount.length])

    // useEffect(() => {
    //     dispatch(onFacebookFetch(state, 'facebook-ad-account-fetch', setAdAccount, loader, setLoader))
    // }, [businessManager.length])

    // useEffect(() => {
    //     dispatch(onFacebookFetch(state, 'facebook-ad-campaign-fetch', setAdCampaign, loader, setLoader))
    // }, [adAccount.length])

    // useEffect(() => {
    //     dispatch(onFacebookFetch(state, 'facebook-ad-sets-fetch', setAdSets, loader, setLoader))
    // }, [adCampaign.length])

    return (
        <>
            {/* <FB_modal handleClose={closeFbModel} fbModle={fbModle} imageData={imageData} type="google" /> */}
            <div className="container">
                <div className="business-wrap">

                    <div className="business-list">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="socialTabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" style={{ color: "#202265" }}>Ad Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" style={{ color: "#202265" }}>Ad Setup</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Account</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Account</label>
                                                        <select className="inpLabel-inp" name="" id="">
                                                            <option value="">Select Account</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Manage Account Customer ID</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Manage Account Customer ID</label>
                                                        <input className="inpLabel-inp" type="text" placeholder="Manage Account Customer ID" />
                                                    </div>
                                                    <span className="noteTxt">Note: Please hit enter after inserting a customer ID.</span>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Ad Account</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Ad Account</label>
                                                        <select className="inpLabel-inp" name="" id="">
                                                            <option value="">Select Ad Account</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Campaign</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Campaign</label>
                                                        <select
                                                            className="inpLabel-inp"
                                                            name="campaignId"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Campaign</option>
                                                            <option value="open">Create New</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Ad Group</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Ad Group</label>
                                                        <select
                                                            className="inpLabel-inp"
                                                            name="adId"
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Ad Group</option>
                                                            <option value="open">Create New</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="btn-sec text-right mt-2">
                                            <button className="site-link"><span>Next</span></button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <AddSetupGoogle
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>

                </div>
            </div>


            <Modal className="theme-modal video-modal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <form>
                        <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                        <h3 className='text-capitalize'>Create Ad Set</h3>
                        <div className="formSec">

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="common-input-wrap mt-0">
                                        <div className="inpLabel">
                                            <label>Ad Set Name</label>
                                            <input className="common-input" placeholder="Ad Set Name" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="common-input-wrap mt-0">
                                        <div className="inpLabel">
                                            <label>Select Budget Type</label>
                                            <select className="common-input" name="" id="">
                                                <option value="">Select Budget Type</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="common-input-wrap">
                                        <div className="inpLabel">
                                            <label>Budget USD</label>
                                            <input className="common-input" placeholder="Budget Amount" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="common-input-wrap">
                                        <div className="inpLabel">
                                            <label>Bid Control(USD)</label>
                                            <input className="common-input" placeholder="Bid Amount" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className='label text-dark' htmlFor="">Ad Set Schedule</label>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <div className='d-flex'>
                                                <label className='ads switch flex' htmlFor="">
                                                    <input type="checkbox" />
                                                    <span className='ads slider round'></span>
                                                    <span className='slOpen2 text-dark'>Run Continuously From Today</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='common-input-wrap mt-0'>
                                            <div className='d-flex'>
                                                <label className='ads switch flex' htmlFor="">
                                                    <input type="checkbox" />
                                                    <span className='ads slider round'></span>
                                                    <span className='slOpen2 text-dark'>Set a Start and End Date</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="common-input-wrap">
                                <div className="inpLabel">
                                    <label>Language(Optional)</label>
                                    <select className="common-input" name="" id="">
                                        <option value="">Select Language</option>
                                    </select>
                                </div>
                            </div>


                            <button type="submit" className="site-link full mt-2">
                                <span> Create</span>
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>


    )
}

export default GooglePage;