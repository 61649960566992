import React, { useEffect, useState } from 'react'
import CreateModalAssets from '../CreateModalAssets'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'
import OptinRow from './OptinRow'

const OptinData = ({ createData, handleCreate, cid, handleClose,  country, language }) => {
    const dispatch = useDispatch()
    const [optins, setOptins] = useState([])
    const [optinLoader, setOptinLoader] = useState({
        fetch: false
    })

    const fetchAllOptin = () => {
        let data = {
            cId: cid
        }
        setOptinLoader({
            ...optinLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-optin-all", data, setOptins, optinLoader, setOptinLoader))
    }

    useEffect(() => {
        fetchAllOptin()
    }, [])

    return (
        <>
            <div className='title-head mt-5'>
                <h2>Opt-in Page</h2>
                <button className='site-link' onClick={() => handleCreate("optin")}><span>Create Opt-in</span></button>
            </div>
            <div className='cont-box'>
                <div className='video-table'>
                    <div className='table-responsive'>
                        <table className='table text-center'>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            {optins.length > 0 ?
                                optins.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <OptinRow
                                                curElem={curElem}
                                                index={index}
                                                optins={optins}
                                                setOptins={setOptins}
                                                fetchAllOptin={fetchAllOptin}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} className="text-center load-icon-help mt-3">
                                        {
                                            optinLoader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                                <p style={{ fontSize: 15 }}>
                                                    You do not have ebooks in this campaign.
                                                </p>
                                        }
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </div>

            <CreateModalAssets
                show={createData.show && createData.type === "opt-in"}
                handleClose={handleClose}
                url={createData.url}
                type={createData.type}
                fetchData={fetchAllOptin}
                cid={cid}
                country={country}
                language={language}
            />
        </>
    )
}

export default OptinData