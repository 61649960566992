import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../title-bar/TitleBar';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';

import { onFetchBlog, onUpdateBlog } from '../../../../../Redux/Actions/ProjectActions';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { MdArrowBackIos } from "react-icons/md";
import CommonWysiwyg from '../../../../CommonComponent/CommonWysiwyg ';
import PdfGeneration from '../../../../CommonComponent/PdfGeneration';

const UpdateBlog = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { id, cid } = queryString.parse(location.search)

  const [blogData, setBlogData] = useState(false)
  const [loader, setLoader] = useState(true)

  const [isDownload, setIsDownload] = useState(false)

  const [buttonLoader, setButtonLoader] = useState({
    save: false,
  })


  const handleChange = (e) => {
    const { name, value } = e.target
    setBlogData({
      ...blogData,
      [name]: value
    })
  }

  const handleSubmit = () => {
    if (blogData.savedContent && blogData.savedTitle) {
      let data = {
        id: id,
        cId: cid,
        aiContent: {
          article: blogData.savedContent,
          title: blogData.savedTitle
        }
      }
      setButtonLoader({
        ...buttonLoader,
        save: true
      })
      dispatch(onUpdateBlog(data, buttonLoader, setButtonLoader))
    } else {
      dispatch(setAlert("Please provide content!", "danger"))
    }
  }

  //Pdf Funtions 
  const handleGeneratePdf = () => {
    setIsDownload(true)
  }


  //---------


  const dataFunction = (res, name) => {
    let data = {
      target: {
        value: res, name
      }
    }
    handleChange(data)
  }

  const fetchVideo = () => {
    let data = {
      id: id
    }
    dispatch(onFetchBlog(data, setBlogData, setLoader))
  }

  useEffect(() => {
    if (id && cid) {
      fetchVideo()
    }
  }, [id, cid])


  return (
    loader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <TitleBar title="Update Blog" />
        <Header />

        <section className='page-wrap'>
          <div className='container'>
            <PdfGeneration
              isDownload={isDownload}
              text={blogData.savedContent}
              title={blogData.savedTitle}
              setIsDownload={setIsDownload}
            />
            <div className='flex-bar'>
              <h4 style={{ fontSize: 28 }}><Link className='text-dark' to={`/projects/assets?cid=${cid}`}><MdArrowBackIos className='pb-1' />Update Blog Article</Link></h4>
              <div className='text-center'>
                <button className='site-link bdr me-1' onClick={handleGeneratePdf}><span>Download Article {isDownload ? <i className="fa fa-spinner fa-spin" /> : ""}</span></button>
                <button className='site-link' onClick={() => handleSubmit()}><span>{buttonLoader.save ? <>Updating <i className="fa fa-spinner fa-spin" /></> : "Update"}</span></button>
              </div>
            </div>

            <div className='sm-containern'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="inpLabelWrap">
                    <span className='labelTxt'>Blog Title</span>
                    <input
                      className='common-input'
                      value={blogData.savedTitle}
                      name='savedTitle'
                      onChange={handleChange}
                    />
                  </div>

                  <div className="inpLabelWrap">
                    <div className='sm-gap mt-3 article-wysiwyg' >
                      <span className='labelTxt'>Article</span>
                      <CommonWysiwyg
                        text={blogData.savedContent.replaceAll("\n", "<br>")}
                        name={"savedContent"}
                        dataFunction={dataFunction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <Footer />
      </>
  )

}

export default UpdateBlog;