import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

const MyComponent = (props) => {

    return (
        <Modal className='share_modal theme-modal xl' show={props.show} centered scrollable onHide={() => props.setShow(false)}>
            <div className='modalClose'>
                <AiOutlineClose style={{ fontSize: "30px", color: "#fff", cursor: "pointer" }} onClick={() => props.setShow(false)} />
            </div>
            <Modal.Body>
                <div>
                    <ul>
                        {
                            <>
                                <FacebookShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <FacebookIcon />
                                </FacebookShareButton>

                                <EmailShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <EmailIcon />
                                </EmailShareButton>

                                <LinkedinShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <LinkedinIcon />
                                </LinkedinShareButton>

                                <PinterestShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <PinterestIcon />
                                </PinterestShareButton>

                                <RedditShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <RedditIcon />
                                </RedditShareButton>

                                <TelegramShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TelegramIcon />
                                </TelegramShareButton>

                                <PocketShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <PocketIcon />
                                </PocketShareButton>

                                <TwitterShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TwitterIcon />
                                </TwitterShareButton>

                                <WhatsappShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <WhatsappIcon />
                                </WhatsappShareButton>

                                <TumblrShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TumblrIcon />
                                </TumblrShareButton>

                                <InstapaperShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <InstapaperIcon />
                                </InstapaperShareButton>

                                <ViberShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <ViberIcon />
                                </ViberShareButton>

                                <VKShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <VKIcon />
                                </VKShareButton>

                                <WorkplaceShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <WorkplaceIcon />
                                </WorkplaceShareButton>
                            </>
                        }
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
};


export default MyComponent;