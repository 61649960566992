import produce from "immer"
const initilState = {
    data: false
}

export const BannerReducer = (state = initilState, action) => {
    switch (action.type) {
        case "SET_BANNER_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        default:
            return state
    }
}
