import React, { useState } from 'react'
import { FiUpload } from "react-icons/fi";
import ImagesModal from './Modals/ImagesModal';
import { useDispatch, useSelector } from 'react-redux';
import defaultImage from "../../../../../../assets/images/dummyImage.jpg"
import { onChangeHeader, onUpdateHeaderbgData, onUpdateHeaderbgType } from '../../../../../../Redux/Actions/ProjectActions';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';
import VideoModal from './Modals/VideoModal';
import GradientBox from '../../../../../CommonComponent/GradientBox';

let modalType = ""
const HeaderSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.bonus.data)
    const [show, setShow] = useState(false);
    const [colorShow, setColorShow] = useState(false);
    const [isGradient, setIsGradient] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = (type) => {
        modalType = type
        setShow(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "type") {
            dispatch(onUpdateHeaderbgType(value))
        }
        else {
            dispatch(onChangeHeader(name, value))
        }
    }

    const handleChooseSrc = (val, type) => {
        let value = ""
        if (type === "color") {
            let rgba = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a.toFixed(2)})`
            value = `#${rgbHex(rgba)}`
        } else if (type === "image") {
            value = val
        } else if (type === "video") {
            value = {
                src: val.src,
                thumb: val.thumb
            }
        }

        dispatch(onUpdateHeaderbgData(value))
    }

    const getFinalGradient = (type, finalColor) => {
        dispatch(onUpdateHeaderbgData(finalColor))
    }

    const handleAddBanner = (data) => {
        if (modalType === 'logo') {
            dispatch(onChangeHeader("logo", data.url))
        } else {
            handleChooseSrc(data.url, "image")
        }
    }

    const handleAddBannerVideo = (data) => {
        handleChooseSrc(data, "video")
    }



    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Banner Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Banner Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Banner Heading"
                        type="text"
                        name='heading'
                        value={landingData.headers.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Logo Image</span>
                        <div className="inpLabel">
                            <span onClick={() => handleShow('logo')} style={{ cursor: "pointer" }} className="fullLink"></span>
                            <span className='uploaded-img'><img src={landingData.headers.logo !== "" ? landingData.headers.logo : defaultImage} alt="" /></span>
                            <input
                                className="inpLabel-inp"
                                placeholder=""
                                type="text"
                            />
                            <span className="inpBtn-icon">
                                <FiUpload /> <span className="pl-1">Choose</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Banner Background Type</span>
                        <div className="inpLabel">
                            <label htmlFor="">Banner Background Type</label>
                            <select
                                className="inpLabel-inp"
                                onChange={handleChange}
                                name="type"
                                value={landingData.headers.bgStyle.type}
                            >
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                                <option value="color">Color</option>
                                <option value="gradient">Gradient</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt text-capitalize">Background {landingData.headers.bgStyle.type}</span>
                        <div className="inpLabel">
                            {landingData.headers.bgStyle.type === "image" ?

                                <>
                                    <span onClick={() => handleShow('image')} style={{ cursor: "pointer" }} className="fullLink"></span>
                                    <span className='uploaded-img'><img src={landingData.headers.bgStyle.src !== "" ? landingData.headers.bgStyle.src : defaultImage} alt="" /></span>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder=""
                                        type="text"
                                    />
                                    <span className="inpBtn-icon">
                                        <FiUpload /> <span className="pl-1">Choose</span>
                                    </span>
                                </> :
                                landingData.headers.bgStyle.type === "video" ?
                                    <>
                                        <span onClick={() => handleShow('video')} style={{ cursor: "pointer" }} className="fullLink "></span>
                                        <span className='uploaded-img'><img src={landingData.headers.bgStyle.thumb !== "" ? landingData.headers.bgStyle.thumb : defaultImage} alt="" /></span>
                                        <input
                                            className="inpLabel-inp"
                                            placeholder=""
                                            type="text"
                                        />
                                        <span className="inpBtn-icon">
                                            <FiUpload /> <span className="pl-1">Choose</span>
                                        </span>
                                    </>
                                    :
                                    landingData.headers.bgStyle.type === "gradient" ? <>
                                        <div className='primary-gradient'>
                                            <GradientBox
                                                getFinalGradient={getFinalGradient}
                                                colorString={landingData.headers.bgStyle.src}
                                                setIsGradient={setIsGradient}
                                                type="button"
                                            />
                                        </div>

                                    </> :
                                        <>
                                            <div className='color-wrap'>
                                                <span
                                                    className='colorBox'
                                                    style={{ background: landingData.headers.bgStyle.src }}
                                                    onClick={() => setColorShow(true)}
                                                ></span>
                                                <span className='colorVal'>{landingData.headers.bgStyle.src}</span>
                                            </div>

                                            {colorShow ?
                                                <>
                                                    <div style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setColorShow(false)} />
                                                    <span className="color-box">
                                                        <SketchPicker
                                                            color={landingData.headers.bgStyle.src}
                                                            onChange={(e) => handleChooseSrc(e, "color")}
                                                        />
                                                    </span>
                                                </> : null}


                                        </>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Banner Description</span>
                <div className="inpLabel">
                    <label htmlFor="bannerDescription">Banner Description</label>
                    <textarea
                        className="inpLabel-inp"
                        name="bannerDescription"
                        placeholder="Banner Description"
                        value={landingData.headers.bannerDescription}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-6'>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Button Affiliate Link</span>
                        <div className="inpLabel">
                            <label htmlFor="heading">Button Affiliate Link</label>
                            <input
                                className="inpLabel-inp"
                                placeholder="Enter Affiliate Link Here"
                                type="text"
                                name='affilateLink'
                                value={landingData.headers.affilateLink}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Button Text</span>
                        <div className="inpLabel">
                            <label htmlFor="heading">Button Text</label>
                            <input
                                className="inpLabel-inp"
                                placeholder="Enter Button Text"
                                type="text"
                                name='buttonText'
                                value={landingData.headers.buttonText}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <ImagesModal
                show={show && (modalType === "image" || modalType === "logo")}
                handleClose={handleClose}
                callbackFun={handleAddBanner}
            />

            <VideoModal
                show={show && modalType === "video"}
                handleClose={handleClose}
                callbackFun={handleAddBannerVideo}
            />
        </div>

    )
}

export default HeaderSection