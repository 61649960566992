import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { BsFillMicFill, BsFillMicMuteFill, BsLink } from 'react-icons/bs'
import { FiTrash2 } from 'react-icons/fi'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { FaSpinner } from 'react-icons/fa'
import { deleteBackTile, deleteTile, onAddSlide, onCheckInnerStatus, onRemovePlaySlide, onSelectInnerBackground, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../../../../../Redux/Actions/VideoActions'
import SweetAlert from '../../../../../../CommonComponent/SweetAlert'

const VideoTiles = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [generatingText, setGenerationText] = useState("")

    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        slide: false,
        index: -1
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const onConfirm = () => {
        if (sweet.slideIndex !== undefined) {
            setSweet({
                ...sweet,
                confirmButtonName: "Deleting..."
            })
            dispatch(deleteBackTile(sweet.slideIndex, sweet.backIndex, setSweet))
        } else {
            if (campaign.slides.length === 1) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You cannot delete last slide!',
                    confirmButtonColor: "#3f429b"
                })
            } else {
                setSweet({
                    ...sweet,
                    confirmButtonName: "Deleting..."
                })
                dispatch(deleteTile(state.slide, state.index, setSweet))
            }
        }
    }

    const onCancel = () => {
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "Delete",
        })
    }
    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(campaign.slides, result.source.index, result.destination.index);
        dispatch(rearrangeTiles(items));
    }

    const onSelectTile = (index) => {
        if (index !== selectedSlideIndex) {
            dispatch(updateScript(index, selectedSlideIndex))
            dispatch(onRemovePlaySlide(selectedSlideIndex))
        }
    }

    const selectInnerBackground = (index, ind, isApply) => {
        if(!isApply){
            dispatch(onSelectInnerBackground(index, ind))            
        }
    }

    const handleDeleteAlert = (e, slide, index) => {
        e.stopPropagation()
        setState({
            ...state,
            slide: slide,
            index: index
        })
        setSweet({
            ...sweet,
            enable: true
        })
    }

    const handleDelete = (e, slideindex, backIndex) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            slideIndex: slideindex,
            backIndex: backIndex
        })
    }


    const handleTts = (slideIndex, e) => {
        e.stopPropagation()
        dispatch(removeTtsFromSlides(slideIndex))
    }

    const handleAddSlide = (data) => {
        let obj = {
            videoId: data.videoId,
        }
        setLoader(true)
        dispatch(onAddSlide(obj, setLoader))
    }

    const fetchVideo = (interval) => {
        let data = {
            id: campaign.id
        }
        dispatch(onCheckInnerStatus(data, setGenerationText, interval))
    }

    useEffect(() => {
        if (+campaign.status === 5) {
            let text = `Generated ${campaign.slides.length}/${+campaign.totalSlides}`
            setGenerationText(text)
            const interval = setInterval(() => {
                fetchVideo(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [campaign])

    return (
        <DragDropContext onDragEnd={onSetRecorder}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {campaign.slides.length > 0 ?
                            campaign.slides.map((curElem, index) => {
                                return (
                                    <>
                                        <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={index}
                                                    onClick={() => onSelectTile(index)}
                                                    className={`cursor-pointer editor_slide_bg ${+curElem.isSelected ? "active-tile-border" : "deactive-tile-border"}`}
                                                >
                                                    <div
                                                        className="editorReel-single-li"
                                                        style={{
                                                            border: +curElem.background[0].isSelected ? "2px solid #202265" : "",
                                                            background: curElem.background[0].type === "color" ? curElem.background[0].thumbnail : ""
                                                        }}
                                                        onClick={(e) => selectInnerBackground(index, 0)}
                                                    >
                                                        <div className="editorReel-single">
                                                            {curElem.background[0].type !== "color" ?
                                                                <img src={curElem.background[0].thumbnail} alt="" /> : ""}
                                                        </div>

                                                        <div className="IconDel">
                                                            <FiTrash2
                                                                onClick={(e) => handleDeleteAlert(e, curElem, index)}
                                                                style={{
                                                                    fontSize: "19px",
                                                                    cursor: 'pointer',
                                                                    color: `${+curElem.background[0].isSelected === 1 ? '#00D0B0' : '#ffsf'}`,
                                                                }}

                                                            />
                                                        </div>

                                                    </div>
                                                    <ul className='m-0'>
                                                        {curElem.background.length > 0 ?
                                                            curElem.background.map((curElem, ind) => {
                                                                return (ind !== 0 ?
                                                                    <li className="editorReel-single-sub"
                                                                        key={ind} onClick={(e) => selectInnerBackground(index, ind, curElem.isSelected)}
                                                                    >
                                                                        <div className="Sub-editorReel-single-li"
                                                                            style={{ background: curElem.type === "color" ? curElem.thumbnail : "", border: +curElem.isSelected === 1 ? "2px solid #202265" : "" }}
                                                                        >
                                                                            <div className="editorReel-single">
                                                                                <img src={curElem.thumbnail} alt="" />
                                                                            </div>

                                                                            <div className="IconDel">
                                                                                <FiTrash2
                                                                                    style={{
                                                                                        fontSize: "19px",
                                                                                        cursor: 'pointer',
                                                                                        color: `${+curElem.isSelected === 1 ? '#00D0B0' : '#fff'}`,
                                                                                    }}
                                                                                    onClick={(e) => handleDelete(e, index, ind)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    : null)
                                                            }) : ""}

                                                    </ul>
                                                    <div className="micON">
                                                        <div className="d-flex">
                                                            <div className='micON'>
                                                                <div className="d-flex">
                                                                    {curElem.audio.enable ?
                                                                        <BsFillMicFill
                                                                            style={{
                                                                                fontSize: "19px",
                                                                                cursor: 'pointer',
                                                                                color: '#00D0B0'
                                                                            }}
                                                                            onClick={(e) => handleTts(index, e)}
                                                                        />
                                                                        :
                                                                        <BsFillMicMuteFill
                                                                            className="voiceIcn-off"
                                                                            style={{
                                                                                color: '#00D0B0',
                                                                                fontSize: "19px",
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </Draggable>
                                        {curElem.transitionName !== "" && curElem.transitionName ?
                                            <div className="transition-wrapper mx-2" title={curElem.transitionName}>
                                                <BsLink style={{ color: '#00D0B0' }} size={25} />
                                            </div> : ""}
                                        <div className="addReelBt" title="Add Slide" onClick={() => handleAddSlide(curElem)}>
                                            {loader ?
                                                <FaSpinner className='spin' style={{ color: "#3f429b", fontSize: '25px' }} />
                                                :
                                                <AiFillPlusCircle />}
                                        </div>
                                    </>
                                )
                            }) : ""}
                        {provided.placeholder}
                        {
                            generatingText ?
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <FaSpinner className='spin' style={{ color: "#3f429b", fontSize: '25px' }} />
                                    </div>
                                    <div className="mx-3" style={{ fontSize: 12 }}>
                                        Generating more slide. Please be patient
                                        <p>{generatingText}</p>
                                    </div>

                                </div>
                                :
                                <></>
                        }
                    </ul>
                )}
            </Droppable>
            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this tile?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </DragDropContext>

    )
}

export default VideoTiles