import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { onAddMember, onUpdateMember } from '../../../../../../../Redux/Actions/ProjectActions'
import { useDispatch } from 'react-redux'
import { FaCheck } from "react-icons/fa";

const BonusModal = (props) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(false)

  const handleSubmit = () => {
    if (selected) {
      if (props.bonusIndex !== undefined) {
        dispatch(onUpdateMember(selected, props.bonusIndex))
      } else {
        dispatch(onAddMember(selected))
      }
      props.handleClose()
    }
  }

  return (
    <Modal className='theme-modal video-modal xl' show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
        <h3 className='text-capitalize'>Choose Bonus</h3>
        <div className="p-4 Choose_Bonus_wrapper">
          <div className="row">

            {props.data.length > 0 ?
              props.data.map((curElem, index) => {
                return (
                  <div className='col-md-4 mb-4' key={index}>
                    <div className={`Choose_Bonus ${selected.id === curElem.id ? "selected_bonus" : ""}`} style={{ cursor: "pointer" }} onClick={() => setSelected(curElem)}>
                      <img className="" src={curElem.bonusUrl} alt='' />
                      <p className='mt-1 mb-3'>{curElem.name}</p>
                      {selected.id === curElem.id ?
                        <div className="checkicon">
                          <FaCheck style={{ color: "#202265", fontSize: "30px" }} />
                        </div> : ""}
                    </div>
                  </div>
                )
              })
              : ""}

          </div>

        </div>
        <div className='common-input-wrap text-end'>
          <button className='site-link bdr ' type='button'><span>Cancle</span></button>
          <button className='site-link ms-1' type='button' onClick={handleSubmit}><span>Add</span></button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BonusModal