import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { onFetchFont } from '../../../../../../Redux/Actions/CommonActions';
import { onUpdateOptinStyle } from '../../../../../../Redux/Actions/ProjectActions';
import rgbHex from 'rgb-hex';

import GradientBox from '../../../../../CommonComponent/GradientBox';

const PageStyle = () => {
    const dispatch = useDispatch()
    const optinData = useSelector(state => state.optin.data)

    const [fonts, setFonts] = useState(false);
    const [colorShow, setColorShow] = useState({
        bg: false,
        font: false
    });

    const handleToggleActive = (type) => {
        setColorShow({
            ...colorShow,
            [type]: true
        })
    }

    const handleToggleDeactive = (type) => {
        setColorShow({
            ...colorShow,
            [type]: false
        })
    }

    const handleChange = (name, value) => {
        dispatch(onUpdateOptinStyle(name, value))
    }

    const handleChangeColor = (e, type) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        handleChange(type, hex)
    }

    const getFinalGradient = (type, finalGradient) => {
        handleChange("src", finalGradient)
    }

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts, false))
    }

    useEffect(() => {
        fetchFonts()
    }, [])

    return (
        <>
            <div className="row">
                <div>
                    <div className='inpLabelWrap'>
                        <span className='labelTxt'>Background</span>
                    </div>
                    <div className='row px-4'>
                        <div className='col-lg-6'>
                            <div className='inpLabelWrap mt-1'>
                                <span className='labelTxt'>Type</span>
                                <select
                                    className='common-input'
                                    value={optinData.style.type}
                                    name='type'
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                >
                                    <option value="Color">Color</option>
                                    <option value="Gradient">Gradient</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='inpLabelWrap mt-1'>
                                <span className='labelTxt'>{optinData.style.type}</span>
                                {optinData.style.type === "Color" ?
                                    <div className='primary-background'>
                                        <div className='color-wrap'>
                                            <span
                                                className='colorBox'
                                                style={{ background: optinData.style.src }}
                                                onClick={() => handleToggleActive("bg")}
                                            ></span>
                                            <span className='colorVal'>{optinData.style.src}</span>
                                        </div>
                                        {colorShow.bg ?
                                            <>
                                                <div
                                                    style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                    onClick={() => handleToggleDeactive("bg")}
                                                />
                                                <span className="color-box">
                                                    <SketchPicker
                                                        color={optinData.style.src}
                                                        onChange={(e) => handleChangeColor(e, "src")}
                                                    />
                                                </span>
                                            </> : null}
                                    </div> :
                                    <GradientBox
                                        getFinalGradient={getFinalGradient}
                                        colorString={optinData.style.src}
                                        setIsGradient={false}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='inpLabelWrap'>
                        <span className='labelTxt'>Font Color</span>
                    </div>
                    <div className='row px-4'>
                        <div className='col-lg-12'>
                            <div className='inpLabelWrap mt-1'>
                                <div className='primary-background'>
                                    <div className='color-wrap'>
                                        <span
                                            className='colorBox'
                                            style={{ background: optinData.style.textColor }}
                                            onClick={() => handleToggleActive("font")}
                                        ></span>
                                        <span className='colorVal'>{optinData.style.textColor}</span>
                                    </div>
                                    {colorShow.font ?
                                        <>
                                            <div
                                                style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                onClick={() => handleToggleDeactive("font")}
                                            />
                                            <span className="color-box">
                                                <SketchPicker
                                                    color={optinData.style.textColor}
                                                    onChange={(e) => handleChangeColor(e, "textColor")}
                                                />
                                            </span>
                                        </> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='inpLabelWrap'>
                            <span className='labelTxt'>Font Family</span>
                        </div>
                        <div className='row px-4'>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Fonts</span>
                                    <select
                                        className='common-input'
                                        onChange={(e) => handleChange("fontFamily", e.target.value)}
                                        value={optinData.style.fontFamily}
                                        style={{ fontFamily: optinData.style.fontFamily }}
                                    >
                                        {fonts.length > 0 ?
                                            fonts.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem} style={{ fontFamily: curElem }}>{curElem}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageStyle;
