import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'

const TransModal = (props) => {
    return (
        <Modal className='theme-modal video-modal xl' show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <h3>Audio Language</h3>
                <div className='common-input-wrap col-12'>
                    <label htmlFor="">Language</label>
                    <select
                        className='common-input'
                        value={props.sweet.language}
                        onChange={(e) => props.setSweet({
                            ...props.sweet,
                            language: e.target.value
                        })}
                        required
                    >
                        {props.lang.length > 0 ?
                            props.lang.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.code}>{curElem.language}</option>
                                )
                            })
                            : ""}
                    </select>
                </div>
                <div className='common-input-wrap text-end'>
                    <button className='site-link bdr' type='button' onClick={props.handleClose}><span> Cancel</span></button>
                    <button className='site-link ms-1' type='button' onClick={props.performExtract}><span> Create</span></button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TransModal