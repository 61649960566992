import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onCheckedToggle, onFetchOptin, onToggleOptins, onUpdateLanding, onUpdateOptin } from "../../../../../../Redux/Actions/ProjectActions";

import { MdArrowBackIos } from "react-icons/md";
import Footer from "../../../../../footer/Footer";
import Header from "../../../../../header/Header";
import TitleBar from "../../../../../title-bar/TitleBar";
import HeaderSection from "./HeaderSection";
import VideoSection from "./VideoSection";
import OptinSection from "./OptinSection";
import FooterSection from "./FooterSection";
import PageStyle from "./PageStyle";
import AicoachSection from "./AicoachSection";


const UpdateOptin = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, cid } = queryString.parse(location.search)
  const optinData = useSelector(state => state.optin.data)

  const [loader, setLoader] = useState({
    submitLoader: false,
    pageLoader: true
  })

  const fetchLandingData = () => {
    let data = {
      id: id
    }
    dispatch(onFetchOptin(data, loader, setLoader))
  }

  const handleChecked = (e) => {
    const { name, checked } = e.target
    dispatch(onToggleOptins(name, checked))
  }

  const onCopyLink = () => {
    var copyText = document.getElementById("liveLink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const handleSubmit = () => {
    let data = { ...optinData }
    setLoader({
      ...loader,
      submitLoader: true
    })
    dispatch(onUpdateOptin(data, loader, setLoader))
  }

  const handlePreview = () => {
    window.open(optinData.liveLink, "_blank")
  }

  useEffect(() => {
    if (id) {
      fetchLandingData()
    }
  }, [id])


  return (
    loader.pageLoader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <Header />
        <TitleBar title="Opt-in Page" />
        <div className="page-wrap">
          <div className="container">
            <div className="business-wrap">
              <div className="business-top">
                <h2><Link className="text-dark" to={`/projects/assets?cid=${optinData.cId}`}><MdArrowBackIos className='pb-1' />Update Opt-in Page  </Link></h2>
                <div>
                  <Link className="site-link mx-2" to={`/template-customize?productLink=${optinData.liveLink}&productId=${id}&type=optin`} target="_blank"><span>Edit in WYSIWYG</span></Link>
                  <button onClick={handlePreview} className="site-link" ><span>Preview</span></button>
                </div>
              </div>

              <div className="business-list">
                <div className="inpLabelWrap mt-0">
                  <span className="labelTxt">Live Link</span>
                  <div className="inpLabel">
                    <label htmlFor="liveLink">Live Link</label>
                    <input
                      className="inpLabel-inp"
                      value={optinData.liveLink}
                      type="text"
                      readOnly
                      id="liveLink"
                    />
                    <span className="inpBtn-icon " style={{ cursor: "pointer" }} onClick={onCopyLink}>
                      <FiCopy /> <span className="ps-1">Copy</span>
                    </span>
                  </div>
                </div>

                <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label for="check-domain">Page Style</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="style"
                        checked={optinData.style.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {optinData.style.enable ?
                  <PageStyle /> : ""}


                <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label for="check-domain">Header Section</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="headers"
                        checked={optinData.headers.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {
                  optinData.headers.enable ?
                    <HeaderSection />
                    : ""
                }

                <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label for="check-domain">Video Section</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="videos"
                        checked={optinData.videos.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round">
                      </span>
                    </label>
                  </div>
                </div>
                {
                  optinData.videos.enable ?
                    <VideoSection />
                    : ""
                }


                <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label for="check-domain">Opt-in Section</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="contact"
                        checked={optinData.contact.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round">
                      </span>
                    </label>
                  </div>
                </div>
                {
                  optinData.contact.enable ?
                    <OptinSection />
                    : ""
                }

                {/* <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label for="check-domain">AI-Coache Section</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="aicoach"
                        checked={optinData.aicoach.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round">
                      </span>
                    </label>
                  </div>
                </div> */}
               
                    <AicoachSection />

                <div className="inpLabel d-flex align-items-center justify-content-between line">
                  <label >Footer Section</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="footer"
                        checked={optinData.footer.enable}
                        onChange={(e) => handleChecked(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {
                  optinData.footer.enable ?
                    <FooterSection />
                    : ""
                }

                <div className="btn-sec text-end mt-3">
                  <button onClick={handleSubmit} className="site-link"><span>{loader.submitLoader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
  )
}

export default UpdateOptin;