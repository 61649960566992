import React, { useEffect, useState } from 'react'
import { BsCheckCircle, BsUpload, BsFillTrashFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { onDeleteImage, onFecthUploadImage, onUploadImageLibrary } from '../../../../../../../Redux/Actions/ProjectActions'
import SweetAlert from '../../../../../../CommonComponent/SweetAlert'
import { onDeleteUserMedia } from '../../../../../../../Redux/Actions/VideoActions'

const UploadImage = (props) => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.bonus.data)

    const [imageList, setImageList] = useState([])
    const [loader, setLoader] = useState({
        imageLoader: false,
        fetchLoader: false,
        saveLoader: false
    })

    const [selected, setSelected] = useState({
        index: -1,
        data: {},
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        type: "",
        loader: false
    })

  

    const fecthImageList = () => {
        let data = {
            type: "images",
        }

        setLoader({
            ...loader,
            fetchLoader: true
        })
        dispatch(onFecthUploadImage(data, setImageList, loader, setLoader))
    }

    const onUploadFile = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    imageLoader: true
                })
                const formData = new FormData()
                formData.append('upload_type', "images")
                formData.append('file', fileData)
                formData.append('clientId', landingData.clientId ? landingData.clientId : props.cId)
                dispatch(onUploadImageLibrary(formData, fecthImageList, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#3f429b"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#3f429b"
            })
        }
    }

    const handleChoose = (curElem, index) => {
        setSelected({
            ...selected,
            index: index,
            data: curElem
        })
    }
    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            if (props.iconIndex !== undefined) {
                props.callbackFun(selected.data, props.iconIndex)
            } else {
                props.callbackFun(selected.data)
            }

            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);
    }

    
    const handleDelete = (e, id) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            id: id,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
        })
    }

    const performDelete = () => {
        const formData = new FormData()
        formData.append("id", sweet.id)
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteUserMedia(formData, fecthImageList, setSweet))
    }
    

    useEffect(() => {
        fecthImageList()
    }, [])

    return (
        <>
            <div className="imgUp-main " >
                <div className="style-upload-box">
                    <input
                        className="custom-file-input"
                        type="file"
                        onChange={(e) => onUploadFile(e)}
                    />
                    <div>
                        {loader.imageLoader ?
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#3f429b" }} />
                            : <>
                                <BsUpload />
                                <h6>Upload your Image</h6>
                            </>}
                    </div>
                </div>

                <div className="img-search-scroll">
                    <ul>
                        {imageList.length > 0 ?
                            imageList.map((curElem, index) => {
                                return (
                                    <li key={index}>
                                        <div
                                            className="photo-single"
                                            onClick={() => handleChoose(curElem, index)}
                                            style={{ border: +selected.index === index ? "2px solid #3f429b" : "", cursor: "pointer" }}
                                        >
                                            <img
                                                src={curElem.url}
                                                alt={curElem.name}
                                            />
                                            <div className='list-delete' onClick={(e) => handleDelete(e, curElem.id)}>
                                                <BsFillTrashFill />
                                            </div>
                                            <div className="imgStat"><BsCheckCircle /></div>
                                        </div>
                                    </li>
                                )
                            })
                            : ""}
                    </ul>
                    {loader.fetchLoader ?
                        <div className='text-center mt-3'>
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#3f429b" }} />
                        </div>
                        : ""}
                </div>
            </div>
            <div className="btnTab">
                <button className="site-link bdr" onClick={props.handleClose}><span>Cancel</span></button>
                <button className="site-link ms-1" onClick={handleSave}><span>{loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /> </> : "Use"}</span></button>
            </div>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this image?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </>
    )
}

export default UploadImage