import React, { useEffect, useState } from 'react';
import grapesjs from "grapesjs"
import 'grapesjs/dist/css/grapes.min.css';
import plugin1 from 'grapesjs-preset-webpage';
import plugin2 from 'grapesjs-preset-newsletter';
import queryString from "query-string";
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const TemplateCustomize = () => {

    const location = useLocation();
    const productLink = queryString.parse(location.search).productLink;
    const productId = queryString.parse(location.search).productId;
    const type = queryString.parse(location.search).type;
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const editor = grapesjs.init({
            container: '#gjs',
            plugins: [plugin1, plugin2],

            storageManager: {
                type: 'remote',
                stepsBeforeSave: 1,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                autoload: true,
                storeCss: true,
                options: {
                    remote: {
                        headers: {
                            'Authorization': auth.token
                        },
                        contentTypeJson: true,
                        credentials: 'omit',
                        urlLoad: `https://backend.aisellers.io/fetch-html/${type}/${productId}`,
                        urlStore: "https://backend.aisellers.io/save-html",
                        onStore: data => ({ id: productId, type: type, htmlData: data }),
                    }
                }
            }
        });

        fetch(productLink)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.text();
            })
            .then((data) => {
                editor.setComponents(data);
            })
            .catch((error) => {
                console.error('Error loading content:', error);
            });

    }, []);

    return (
        <div>
            <div className='alert alert-warning text-center tempAlert'>
                All changes will be auto saved
            </div>
            <div id="gjs">

            </div>
        </div>
    );
}

export default TemplateCustomize;
