import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { BsTrash } from 'react-icons/bs'
import { FaRegEdit } from "react-icons/fa"
import { Link } from 'react-router-dom'
import dummyIcon from "../../../assets/images/defaultIcon.png"
import SweetAlert from '../../CommonComponent/SweetAlert'
import { useSelector } from 'react-redux'

const ProjectRows = ({ curElem, camp, setCamp }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (campId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: campId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false,
            id: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            loader: true,
            confirmButtonName: "Deleting"
        })
        let data = {
            id: sweet.id,
        }
        dispatch(onDeleteCampaign("delete-campaign", data, camp, setCamp, setSweet))
    }

    return (
        <tr>
            <td>
                <span className='vid-thumb'><img src={dummyIcon} alt="" /></span>
            </td>
            <td className='text-capitalize'>{curElem.name}</td>
            <td>{curElem.created}</td>
            <td>
                {+auth.user.isClientAccount !== 1 ?
                    <>
                        <Link className='link-icon' title='Edit' to={`/projects/knowledge-brain?cid=${curElem.id}`}><FaRegEdit /></Link>
                        <a className='link-icon' onClick={() => onDelete(curElem.id)}><BsTrash /></a>
                    </> : null}
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this document?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default ProjectRows