import React, { useState } from 'react';

function ClientContract() {

  return (
    <>

      <div className="tabepaneIn">
        <h2 className="tabTitle">Client Contract</h2>
        <div className="tabepaneIn-main">
          <div className="tabepaneIn-cont">
            <h3>Client Contract</h3>
            <div className="upgrade-para">
              <p className="text-center ">
                Download our "Client Contract" template. This is a docx
                file, works best with MS Word. Edit the doc and add your
                business name, address and other details. Fill the empty
                blank space with your service, eg Video Marketing, Local
                Reputation Management, Social Media Marketing, Design
                &amp; Creatives, Ads Management and so on.
              </p>
            </div>
          </div>

          <div className="tabepaneIn-cont-alt">
            <div className="upgrade-content">
              <div className="row">
                <div className="col-xl-4 col-md-6 col-sm-12 col-md-6 mb-2">
                  <div className="upgrade-box-content text-center  text-white">
                    <div className="upgrade-heading">
                      <h6>Brief Pitch Template</h6>
                    </div>
                    <div className="upgrade-para p-3">
                      <a
                        rel="noreferrer"
                        href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc"
                        target="_blank"
                      >
                        <button className="site-link full btn-block mt-0">
                          <span> Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12  mb-2">
                  <div className="upgrade-box-content text-center  text-white">
                    <div className="upgrade-heading">
                      <h6>Service Agreement</h6>
                    </div>
                    <div className="upgrade-para p-3">
                      <a
                        rel="noreferrer"
                        href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc"
                        target="_blank"
                      >
                        <button className="site-link full btn-block mt-0">
                          <span> Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 col-sm-12  mb-2">
                  <div className="upgrade-box-content text-center  text-white">
                    <div className="upgrade-heading">
                      <h6>Meeting Setup Template</h6>
                    </div>
                    <div className="upgrade-para p-3">
                      <a
                        rel="noreferrer"
                        href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc"
                        target="_blank"
                      >
                        <button className="site-link full btn-block mt-0">
                          <span> Download</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default ClientContract;