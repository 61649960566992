import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { unselectLayers } from '../../../../../../../Redux/Actions/VideoActions'
import { FaSpinner } from 'react-icons/fa'

const BackgroundLayer = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [backgroundData, setBackgroundData] = useState({
        type: "color",
        src: "",
        animation: ""
    })

    const layersUnselect = () => {
        const isLayerSelected = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (isLayerSelected !== -1) {
            dispatch(unselectLayers(props.selectedSlideIndex))
        }
    }

    useEffect(() => {
        const selectedBackgroundData = props.background.find(({ isSelected }) => +isSelected === 1)
        if (selectedBackgroundData) {
            setBackgroundData({
                ...backgroundData,
                type: selectedBackgroundData.type,
                src: selectedBackgroundData.src,
                animation: selectedBackgroundData.animation.name
            })
            if (!props.selectedSlide.isPlaying) {
                setLoader(true)
            }
        }
    }, [props.background]);

    return (
        backgroundData.type === "video" ?
            <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0, animationDuration: 1 }}
                onClick={layersUnselect}
                className={props.selectedSlide.isPlaying ? backgroundData.animation : ""}
            >
                {loader ?
                    <div
                        className="loader-center-temp1"
                        style={props.mediaType === "bgImage" ?
                            {
                                background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)',
                                width: "100%",
                                height: "100%",
                                position: 'absolute',
                                zIndex: "2"
                            } : {}}
                    >
                        <FaSpinner className='spin' style={{ fontSize: "50px" }} />
                    </div>
                    : ''}
                <ReactPlayer
                    url={backgroundData.src}
                    playing={true}
                    loop={true}
                    // className={` ${props.mediaType === "pImage" ? 'video-player' : ''}`}
                    onPlay={() => setLoader(false)}
                    muted
                    style={{ visibility: `${loader ? 'hidden' : 'visible'}`, objectFit: "cover" }}
                    className={`${props.dimension === "9x16" ? "video-container_9x16" : ""}`}
                />
            </div> :
            backgroundData.type === "image" ?
                <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0, textAlign: "center", animationDuration: 1 }}
                    onClick={layersUnselect}
                    className={props.selectedSlide.isPlaying ? backgroundData.animation : ""}

                >
                    {loader ?
                        <div
                            className="loader-center-temp1"
                            style={props.mediaType === "bgImage" ?
                                {
                                    background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)',
                                    width: "100%",
                                    height: "100%",
                                    position: 'absolute',
                                    zIndex: "2"
                                } : {}}
                        >
                            <FaSpinner className='spin' style={{ fontSize: "50px" }} />
                        </div> : ''}
                    <img
                        className={`${props.dimension === "9x16" ? "editor-background-img_9x16" : "editor-background-img"}`}
                        src={backgroundData.src}
                        alt=''
                        onLoad={() => setLoader(false)}
                        style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}
                    />
                </div>
                :
                <div
                    style={{ width: "100%", height: "100%", position: 'absolute', top: 0, backgroundColor: `${backgroundData.src}`, animationDuration: 1 }}
                    onClick={layersUnselect}
                    className={props.selectedSlide.isPlaying ? backgroundData.animation : ""}
                >
                </div>
    )
}

export default BackgroundLayer