import React from "react";
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useSelector } from "react-redux";
import ConnectFacebook from "./ConnectFacebook";
import ConnectReel from "./ConnectReel";
import ConnectYoutube from "./ConnectYoutube";

const Integration = () => {
   const auth = useSelector(state => state.auth)
   const socialData = useSelector(state => state.social);


   console.log(socialData)
   // const [memberShip, setMemberShip] = useState([])


   // useEffect(() => {
   //     if (auth.user) {
   //         setMemberShip(auth.user.membership.split('__'))
   //     }
   // }, [auth.user])

   return (
      <>
         <TitleBar title="Integraton" />
         <Header />

         <div className="page-wrap">
            <div className="container">
               <div className="integration">
                  <h2>Integration</h2>
                  <ul>
                     <li>
                        <a href="#facebook" className="text-light">
                           <div className={`${socialData.facebook.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/facebook.png')} /></div>
                              <p>Facebook</p>
                           </div>
                        </a>

                     </li>
                     <li>
                        <a href="#youtube" className="text-light">
                           <div className={`${socialData.youtube.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/youtube.png')} /></div>
                              <p>YouTube</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#syvid" className="text-light">
                           <div className={`${socialData.syvid.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/syvid.png')} /></div>
                              <p>SyVid</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#livereel" className="text-light">
                           <div className={`${socialData.livereel.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/livereel.png')} /></div>
                              <p>LiveReel</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#scriptreel" className="text-light">
                           <div className={`${socialData.scriptreel.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/scriptreel.png')} /></div>
                              <p>ScriptReel</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#trimreel" className="text-light">
                           <div className={`${socialData.trimreel.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/trim.png')} /></div>
                              <p>TrimReel</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#sonority" className="text-light">
                           <div className={`${socialData.sonority.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/sonority.png')} /></div>
                              <p>Sonority</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#videoreel" className="text-light">
                           <div className={`${socialData.videoreel.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/videoIcon.png')} /></div>
                              <p>VideoReel</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#aicoach" className="text-light">
                           <div className={`${socialData.aiCoaches.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require( '../../../assets/images/icon/aicoaches.png')} /></div>
                              <p>AI-Coaches</p>
                           </div>
                        </a>
                     </li>
                     <li>
                        <a href="#autoresponder" className="text-light">
                           <div className={`${socialData.autoResponder.length > 0 ? "active" : ""} integration-single`}>
                              <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/code.png')} /></div>
                              <p>Autoresponder</p>
                           </div>
                        </a>
                     </li>
                  </ul>
               </div>

               <div className="connection">
                  <h2>Accounts</h2>
                  <div className="row">
                     <div className="col-sm-12">
                        <ConnectFacebook
                           data={socialData.facebook}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectYoutube
                           data={socialData.youtube}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/syvid.png')}
                           name="Syvid"
                           type="syvid"
                           data={socialData.syvid}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/livereel.png')}
                           name="LiveReel"
                           type="livereel"
                           data={socialData.livereel}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/scriptreel.png')}
                           name="ScriptReel"
                           type="scriptreel"
                           data={socialData.scriptreel}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/trim.png')}
                           name="TrimReel"
                           type="trimreel"
                           data={socialData.trimreel}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/sonority.png')}
                           name="Sonority"
                           type="sonority"
                           data={socialData.sonority}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/videoIcon.png')}
                           name="VideoReel"
                           type="videoreel"
                           data={socialData.videoreel}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/aicoaches.png')}
                           name="AI-Coach"
                           type="aicoach"
                           data={socialData.aiCoaches}
                        />
                     </div>
                     <div className="col-sm-12">
                        <ConnectReel
                           icon={require('../../../assets/images/icon/code.png')}
                           name="Autoresponder"
                           type="autoresponder"
                           data={socialData.autoResponder}
                        />
                     </div>

                  </div>
               </div>
            </div>
         </div>

         <Footer />
      </>
   )
}
export default Integration;