import React, { useState, useEffect } from 'react';
import TitleBar from '../../../title-bar/TitleBar';
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { PiPenNibFill } from "react-icons/pi"
import { FiUpload } from "react-icons/fi"
import { BiLink } from "react-icons/bi"
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import WriteDoc from './WriteDocument/WriteDoc';
import { onFetchCamapign, onUpdateCampaign } from '../../../../Redux/Actions/ProjectActions';
import ImportUrl from './ImportDocument/ImportUrl';
import UploadDoc from './UploadDocument/UploadDoc';
import MediaDoc from './MediaDocument/MediaDoc';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
import { BiSolidVideos } from "react-icons/bi";

const KnowledgeBrain = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { cid } = queryString.parse(location.search)
    const [loader, setLoader] = useState(true)
    const [gotoAsset, setGotoAsset] = useState({
        write: false,
        import: false,
        upload: false,
        media: false
    })

    const [campaignData, setCampaignData] = useState({
        name: ""
    })

    const handleGotoAssets = (e) => {
        if (e.target.checked && (gotoAsset.write || gotoAsset.import || gotoAsset.upload || gotoAsset.media)) {
            navigate(`/projects/assets?cid=${cid}`)
        } else {
            dispatch(setAlert("Please add some data in Knowledge Brain page to enable Assets page!", "danger"))
        }
    }

    const handleBlur = () => {
        if (campaignData.name !== "") {
            dispatch(onUpdateCampaign(campaignData))
        }
    }


    const fetchCampaign = () => {
        let data = {
            id: cid
        }
        dispatch(onFetchCamapign(data, setCampaignData, setLoader))
    }

    useEffect(() => {
        fetchCampaign()
    }, [])


    return (
        loader ?
            <>
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </>
            :
            <>
                <TitleBar title="Knowledge Brain" />
                <Header />

                <section className='page-wrap'>
                    <div className='camp-name'>
                        <div className='container'>
                            <div className='title-wrap'>
                                <h2 className='text-capitalize' style={{ fontWeight: 100, fontSize: 20 }}>
                                    <input
                                        type='text'
                                        value={campaignData.name}
                                        onChange={(e) => setCampaignData({
                                            ...campaignData,
                                            name: e.target.value
                                        })}
                                        style={{ width: campaignData.name.length + "ch", paddingLeft: "5px" }}
                                        onBlur={handleBlur}
                                        className='camp-edit ps-1'
                                    /> | Knowledge Brain
                                </h2>
                                <div className="opt-select text-dark" style={{fontSize:12}}>
                                    <span style={{color : "#202265"}}><strong>Knowledge Brain</strong></span>
                                    <label className="switch mx-1" title='Assets'>
                                        <input
                                            type="checkbox"
                                            onChange={handleGotoAssets}
                                            checked={false}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <span><strong>Assets</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='create-box'>
                            <h2>Create Training Documents</h2>

                            <div className='tab-inner'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="g" className="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">
                                                <span className='icon'><PiPenNibFill /></span>
                                                <h5>Write</h5>
                                                <p>Write training document</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">
                                                <span className='icon'><BiLink /></span>
                                                <h5>Import</h5>
                                                <p>Import from website URL</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                <span className='icon'><FiUpload /></span>
                                                <h5>Media</h5>
                                                <p>Upload your media</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">
                                                <span className='icon'><BiSolidVideos /></span>
                                                <h5>Video File</h5>
                                                <p>Select video files</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <WriteDoc
                                                cId={cid}
                                                gotoAsset={gotoAsset}
                                                setGotoAsset={setGotoAsset}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <ImportUrl
                                                cId={cid}
                                                gotoAsset={gotoAsset}
                                                setGotoAsset={setGotoAsset}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="third">
                                            <UploadDoc
                                                cId={cid}
                                                gotoAsset={gotoAsset}
                                                setGotoAsset={setGotoAsset}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fourth">
                                            <MediaDoc
                                                cId={cid}
                                                gotoAsset={gotoAsset}
                                                setGotoAsset={setGotoAsset}
                                            />
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Tab.Container>
                            </div>

                        </div>
                    </div>
                </section >

                <Footer />
            </>
    )

}

export default KnowledgeBrain;