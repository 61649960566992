import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import CreateModalAssets from '../CreateModalAssets'
import BonusRow from './BonusRow'
import { FaSpinner } from 'react-icons/fa'

const BonusData = ({ createData, handleCreate, cid, handleClose, country, language }) => {
    const dispatch = useDispatch()
    const [bonus, setBonus] = useState([])
    const [bonusLoader, setBonusLoader] = useState({
        fetch: true
    })

    const fetchAllBonus = () => {
        let data = {
            cId: cid
        }
        dispatch(fetchData("fetch-landing-all", data, setBonus, bonusLoader, setBonusLoader))
    }

    useEffect(() => {
        fetchAllBonus()
    }, [])
    return (
        <>
            <div className='title-head mt-5'>
                <h2>Landing Page</h2>
                <button className='site-link' onClick={() => handleCreate("bonus")}><span>Create Landing Page</span></button>
            </div>
            <div className='cont-box'>
                <div className='video-table'>
                    <div className='table-responsive'>
                        <table className='table text-center'>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            {bonus.length > 0 ?
                                bonus.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <BonusRow
                                                curElem={curElem}
                                                index={index}
                                                bonus={bonus}
                                                setBonus={setBonus}
                                                fetchAllBonus={fetchAllBonus}
                                               
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} className="text-center load-icon-help mt-3">
                                        {
                                            bonusLoader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                                <p style={{ fontSize: 15 }}>
                                                    You do not have landing page in this campaign.
                                                </p>
                                        }
                                    </td>
                                </tr>
                            }

                        </table>
                    </div>
                </div>
            </div>
            <CreateModalAssets
                show={createData.show && createData.type === "landing page"}
                handleClose={handleClose}
                url={createData.url}
                type={createData.type}
                fetchData={fetchAllBonus}
                cid={cid}
                country={country}
                language={language}
            />
        </>
    )
}

export default BonusData