import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const CommonWysiwyg = (props) => {
    const [text, setText] = useState("")

    const handleChange = (val) => {
        props.dataFunction(val, props.name)
    }

    const editorConfiguration = {
        toolbar: ['undo', 'redo' ,'|', 'heading', '|', 'bold', 'italic', 'strikethrough', 'blockQuote', 'indent', 'numberedList', 'bulletedList', '|', 'fontfamily', 'fontColor', 'fontBackgroundColor' ]
    };

    useEffect(() => {
        setText(props.text)
    }, [props.text])


    return (
        <div className="order_warp">
            <CKEditor
                onReady={editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                }}
                onError={(error, { willEditorRestart }) => {
                    if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                    }
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChange(data)
                }}
                editor={DecoupledEditor}
                data={text}
                config={editorConfiguration}
            />
        </div>

    )
}

export default CommonWysiwyg