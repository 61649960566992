import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import syvidIcon from "../../../assets/images/icon/syvid.png";
import facebookIcon from "../../../assets/images/icon/facebook.png";
import youtubeIcon from "../../../assets/images/icon/youtube.png"
import scriptIcon from "../../../assets/images/icon/scriptreel.png";
import trimIcon from "../../../assets/images/icon/trim.png";
import liveIcon from "../../../assets/images/icon/livereel.png";
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import SocialIcons from './SocialIcons';
import { onPublishVideo } from '../../../Redux/Actions/CommonActions';

const PublishModal = ({ curElem, show, handleClose, memberShip }) => {
    const socials = useSelector(state => state.social)
    const dispatch = useDispatch()
    const [currSocial, setCurrSocial] = useState([]);
    const [selectIcon, setSelectIcon] = useState(false);
    const [socialData, setSocialData] = useState(false);
    const [loader, setLoader] = useState(false)
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: "",
        integration_id: '',
        type: '',
        network: ''
    })


    const currentSocial = (social, type, icon) => {
        if (social.length > 0) {
            setCurrSocial(social);
            setSelectIcon(icon);
            setPostData({
                ...postData,
                type: type,
                integration_id: social[0].id,
                network: icon,
            });
        }
    }

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(onPublishVideo(postData.type, postData, handleClose, setLoader))
    }

    useEffect(() => {
        if (socialData === false) {
            setSocialData(socials)
        }
    }, [socials]);

    useEffect(() => {
        if (curElem.videoUrl) {
            setPostData({
                ...postData,
                video_url: curElem.videoUrl
            })
        } else {
            setPostData({
                ...postData,
                video_url: curElem.output_video
            })
        }
    }, [curElem])


    return (

        <Modal className="theme-modal public_modal  px-2" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                <h3 className='text-capitalize text-center mb-5'>Publish Video</h3>
                <ul className='d-flex justify-content-between align-items-center px-1' >
                    <li onClick={() => currentSocial(socialData.facebook, 'upload-to-facebook', 'facebook', curElem.url)} className='social_hover'>
                        <SocialIcons
                            icon={facebookIcon}
                            status={socials.facebook.length > 0 ? true : false}
                            name="Facebook"
                            selectIcon={selectIcon === "facebook" ? "select-social" : ''}
                        />
                    </li>
                    <li
                        onClick={() => currentSocial(socialData.youtube, 'upload-to-youtube', 'youtube', curElem.url)} className='social_hover'
                    >
                        <SocialIcons
                            icon={youtubeIcon}
                            status={socials.youtube.length > 0 ? true : false}
                            name="Youtube"
                            selectIcon={selectIcon === "youtube" ? "select-social" : ''}
                        />
                    </li>
                    <li
                        onClick={() => currentSocial(socialData.syvid, 'push-to-app', 'syvid', curElem.url)} className='social_hover'
                    >
                        <SocialIcons
                            icon={syvidIcon}
                            status={socials.syvid.length > 0 ? true : false}
                            name="Syvid"
                            selectIcon={selectIcon === "syvid" ? "select-social" : ''}

                        />
                    </li>
                    <li
                        onClick={() => currentSocial(socialData.scriptreel, 'push-to-app', 'scriptreel', curElem.url)} className='social_hover'
                    >
                        <SocialIcons
                            icon={scriptIcon}
                            status={socials.scriptreel.length > 0 ? true : false}
                            name="ScriptReel"
                            selectIcon={selectIcon === "scriptreel" ? "select-social" : ''}

                        />
                    </li>
                    {/* {memberShip.includes("professional") || memberShip.includes("professional-lite") || memberShip.includes("bundle") ? */}
                    <li
                        onClick={() => currentSocial(socialData.trimreel, 'push-to-app', 'trimreel', curElem)} className='social_hover'
                    >
                        <SocialIcons
                            icon={trimIcon}
                            status={socials.trimreel.length > 0 ? true : false}
                            name="TrimReel"
                            selectIcon={selectIcon === "trimreel" ? "select-social" : ''}

                        />
                    </li>
                    {/* : ""} */}
                    <li
                        onClick={() => currentSocial(socialData.livereel, 'push-to-app', 'livereel', curElem.url)} className='social_hover'
                    >
                        <SocialIcons
                            icon={liveIcon}
                            status={socials.livereel.length > 0 ? true : false}
                            name="LiveReel"
                            selectIcon={selectIcon === "livereel" ? "select-social" : ''}
                        />
                    </li>
                </ul>

                <div className="form-body mt-4">
                    <form onSubmit={(e) => onFormSubmit(e)}>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted">Title</label>
                            <input type="text" className="form-control text-light common-input publish_text" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter Title" />
                        </div>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted mb-2">Description</label>
                            <textarea className="form-control text-light common-input publish_text" required name="description" onChange={(e) => onInputChange(e)} placeholder="Enter Description" />
                        </div>
                        <div className="form-group form-group-alt">
                            <label className="form-text text-muted">Account Type</label>
                            <select
                                className="form-control common-input publish_text"
                                required
                                name="integration_id"
                                onChange={(e) => onInputChange(e)}
                            >
                                <option value={""}>Select Account</option>
                                {
                                    currSocial.length > 0 ?
                                        currSocial.map((item, index) => {
                                            return (
                                                <option key={index} style={{ backgroundColor: "#000", color: "#fff" }} value={item.id}>{item?.username}</option>
                                            )
                                        })
                                        : ''
                                }
                            </select>
                        </div>
                        <div className="text-center mt-4">
                            <button type="submit" className="site-link" disabled={loader}>
                                <span>
                                    {loader ? <>
                                        Publishing
                                        <i className="fa fa-spinner fa-spin mx-2" />  </> : 'Publish'}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default PublishModal