import React, { useState } from 'react';

function Acadeable() {

    return (
        <>
            <div className="tabepaneIn">
                <h2 className="tabTitle">Acadeable</h2>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="tabepaneIn-main  text-white  ">
                            <div className="tabepaneIn-cont">
                                <h3>Acadeable</h3>
                                <div className="upgrade-para">
                                    <p className="text-center ">
                                        Download Udemy Style Website to showcase your courses. Instant Access to Udemy Style Website Builder to Sell Courses
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row  mt-5">
                    <div className="col-md-12">
                        <div className="upgrade-box-content text-center p-3 text-white">
                            <a
                                rel="noreferrer"
                                href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip"
                                target="_blank"
                            >
                                <button className="site-link">
                                    <span>Download Setup</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Acadeable;