import React from 'react'
import { onUpdateSocialsConatct } from '../../../../../../Redux/Actions/ProjectActions'
import { useDispatch } from 'react-redux'

const SocialBox = (props) => {
    const dispatch = useDispatch()
    const handleChangeOptinContactSocials = (e) => {
        let { name, value, checked } = e.target
        if (name === "enable") {
            value = checked
        }
        dispatch(onUpdateSocialsConatct(props.name, name, value))
    }
    return (
        <>
            <div className='social-block'>
                <span className='labelTxt text-capitalize' >{props.type} URL</span>
                <div className="d-flex">
                    <label className="switch">
                        <input
                            type="checkbox"
                            name="enable"
                            checked={props.data.enable}
                            onChange={handleChangeOptinContactSocials}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            {props.data.enable ?
                <div className="inpLabel mb-1">
                    <label htmlFor="heading text-capitalize" >{props.type} URL</label>
                    <input
                        className="inpLabel-inp"
                        placeholder={`Enter URL Here`}
                        type="urtextl"
                        name='url'
                        value={props.data.url}
                        onChange={handleChangeOptinContactSocials}
                    />
                </div> : null}
        </>
    )
}

export default SocialBox