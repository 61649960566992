import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useDispatch } from 'react-redux';
import { onUpdateCta } from '../../../../../../../Redux/Actions/VideoActions';

const SummerNote = ({ state, setState, type }) => {
    const dispatch = useDispatch()
    const handleChange = (val) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                data: val
            }
        })
        if (type) {
            dispatch(onUpdateCta(val))
        }
    }

    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo']
    };


    return (
        <CKEditor
            onReady={editor => {
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
            }}
            onError={(error, { willEditorRestart }) => {
                if (willEditorRestart) {
                    this.editor.ui.view.toolbar.element.remove();
                }
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                handleChange(data)
            }}
            editor={DecoupledEditor}
            data={state.promo.data}
            config={editorConfiguration}
        />

    )
}

export default SummerNote