import React, { useEffect, useState } from 'react';
import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import { Link, useNavigate } from 'react-router-dom';
import { Search } from 'react-iconly'
import VideoProjects from './VideoProjects';
import CreatePrModal from './CreateProject/CreatePrModal';
import { onCreateCampaign } from '../../../Redux/Actions/ProjectActions';
import { useDispatch } from 'react-redux';
import { BsTrash } from 'react-icons/bs';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import ProjectRows from './ProjectRows';
import Pagination from '../../CommonComponent/Pagination';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import MergeProjects from '../ReelMerge/MergeProjects';

const Projects = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [type, setType] = useState(1)
    const [campLoader, setCampLoader] = useState({
        fetch: true
    })
    const [sortHeader, setSortHeader] = useState({
        name: "",
        created: ""
    })

    const [campaignsData, setCampaignsData] = useState([])
    const [camp, setCamp] = useState([])
    const [filterObj, setFilterObj] = useState({
        searchKey: ""
    })
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;

    const currentTodo =
        filterObj.searchKey !== "" ? campaignsData : campaignsData.slice(indexofFirstTodo, indexofLastTodo);

    const handleSort = (type) => {
        const data = [...camp]
        if (type === "name") {
            if (sortHeader.name) {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    name: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    name: true,
                });
            }
        }
        else if (type === "created") {
            if (sortHeader.created) {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() > b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: false,
                });
            } else {
                data.sort((a, b) => {
                    if (a.created.toLowerCase() < b.created.toLowerCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setSortHeader({
                    ...sortHeader,
                    created: true,
                });
            }
        }
        setCamp(data)
    }

    const handleChange = (e) => {
        const { value } = e.target
        setFilterObj({
            ...filterObj,
            searchKey: value
        })
    }

    const createCampaign = () => {
        if (!loader) {
            setLoader(true)
            dispatch(onCreateCampaign({}, navigate, setLoader))
        }
    }

    const fetchCamp = () => {
        // let limit = props.isDash ? 4 : false
        dispatch(fetchData("get-campaign", {}, setCamp, campLoader, setCampLoader))
        // dispatch(fetchData("get-campaign", {}, setCamp, campLoader, setCampLoader, false, "shortDataLg"))
    }

    useEffect(() => {
        if (filterObj.searchKey === "") {
            setCampaignsData(camp)
        }
        else {
            let dataArr = camp.filter((item1) => {
                return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            })
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 10
            })
            setCampaignsData(dataArr)
        }

    }, [filterObj, camp])

    useEffect(() => {
        setPagination({
            ...pagination,
            currentPage: 1,
            totalItemOnPage: 10
        })

        setCamp([])
        fetchCamp()
    }, [])

    return (
        <>
            <TitleBar title="Projects" />
            <Header />

            <section className='page-wrap'>

                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h1 className='page-title'>My Projects</h1>
                        {+auth.user.isClientAccount !== 1 ?
                            <span className='site-link bdr' onClick={createCampaign}><span>{loader ? <>Creating <i className='fa fa-spinner fa-spin' /> </> : "+ Create Project"}</span></span> : null}
                    </div>
                    <div className='cont-box' style={{ padding: 20 }}>
                        <div className='row'>
                            <div className='col-xl-10'>
                                <div className='input-wrap with-icon'>
                                    <span className='inp-icon'><Search /></span>
                                    <input
                                        className='inp'
                                        type="text"
                                        placeholder='Search Project'
                                        onChange={handleChange}
                                    // readOnly={camp.length === 0}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="input-wrap with-icon">
                                    <div className="inp-wrap">
                                        <select
                                            className="inp"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <option value={1}>Projects</option>
                                            <option value={2}>Reelmerge Projects</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {+type === 1 ?
                            <div className='video-table'>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <tr>
                                            <th></th>
                                            <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>Name<span style={{ color: "darkgray" }}> {sortHeader.name === "" ? <FaSort /> : sortHeader.name ? <FaSortDown /> : <FaSortUp />}</span></th>
                                            <th onClick={() => handleSort("created")} style={{ cursor: "pointer" }}>Created<span style={{ color: "darkgray" }}  > {sortHeader.created === "" ? <FaSort /> : sortHeader.created ? <FaSortDown /> : <FaSortUp />}</span></th>
                                            {/* <th>Name</th>
                                         <th>Created</th> */}
                                            <th></th>
                                        </tr>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ProjectRows
                                                            curElem={curElem}
                                                            camp={camp}
                                                            setCamp={setCamp}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            camp.length === 0 ?
                                                <tr>
                                                    <td colSpan={4} className='text-center'>
                                                        {
                                                            campLoader.fetch ?
                                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                                                                :
                                                                <p style={{ fontSize: 18 }}>
                                                                    You do not have any "Projects" in your account.
                                                                </p>
                                                        }
                                                    </td>
                                                </tr>
                                                : ""
                                        }

                                    </table>
                                </div>
                                <Pagination
                                    listData={campaignsData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={camp}
                                    loader={campLoader.fetch}
                                />
                            </div>
                            :
                            <MergeProjects
                                filterObj={filterObj}
                            />
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )

}

export default Projects;