import React, { useState } from 'react';

function WebHosting() {

  return (
    <>
      <div className="tabepaneIn">
        <h2 className="tabTitle">Web Hosting</h2>
        <div className="tabepaneIn-main">
          <div className="tabepaneIn-cont">
            <h3>Web Hosting</h3>
            <div className="upgrade-para">
              <p className="text-center ">
                Please raise a support ticket with your Ai Sellers
                purchase transaction ID, your domain name that you
                currently own and wish to use with the hosting, email
                address, your full name and a note about how you would
                be using the webhosting for your business.
              </p>
            </div>
            <a
              rel="noreferrer"
              href="https://support.vineasx.com/"
              target="_blank"
            >
              <button className="site-link full btn-block">
                <span>Raise a Ticket</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )

}

export default WebHosting;