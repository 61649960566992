import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Rnd } from "react-rnd";
import { endAnimation } from '../../../../../../../Redux/Actions/VideoActions';


const ShapeLayer = (props) => {
    let ediotWidth = props.dimension === "9x16" ? 360 : 853
    let ediotHeight = props.dimension === "9x16" ? 640 : 480
    let newLeft = (props.layer.position.left * ediotWidth) / 100
    let newTop = (props.layer.position.top * ediotHeight) / 100

    const dispatch = useDispatch()
    const [myStyle, setMyStyle] = useState({
        color: props.layer.style.color,
        fontFamily: props.layer.style.fontFamily,
        background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
        fontSize: parseInt(props.layer.style.fontSize),
        textAlign: props.layer.style.textAlign,
        fontWeight: props.layer.style.fontWeight,
        fontStyle: props.layer.style.fontStyle,
        textTransform: props.layer.style.textTransform,
        textDecoration: props.layer.style.textDecoration,
        lineHeight: props.layer.style.lineHeight,
        opacity: props.layer.style.opacity,
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        clipPath: props.layer.style.clipPath,
        boxShadow: props.layer.style.boxShadow,
        transform: props.layer.style.transform,
        display: props.layer.style.display,
        alignItems: props.layer.style.alignItems,
        justifyContent: props.layer.style.justifyContent,
        borderRadius: props.layer.style.borderRadius,
        textShadow: props.layer.style.textShadow,
        writingMode: props.layer.style.writingMode,
        borderWidth: props.layer.style.borderWidth,
        borderStyle: props.layer.style.borderStyle,
        borderImage: props.layer.style.borderImage,
        borderImageSlice: props.layer.style.borderImageSlice,
        borderTopRightRadius: props.layer.style.borderTopRightRadius,
        borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
        borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
        borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        letterSpacing: props.layer.style.letterSpacing,
        padding: props.layer.style.padding,
        position: "absolute",
        overflow: "hidden"
    })



    useEffect(() => {

        let newLeft = (props.layer.position.left * ediotWidth) / 100
        let newTop = (props.layer.position.top * ediotHeight) / 100
        const obj = {
            color: props.layer.style.color,
            fontFamily: props.layer.style.fontFamily,
            background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
            fontSize: parseInt(props.layer.style.fontSize),
            textAlign: props.layer.style.textAlign,
            fontWeight: props.layer.style.fontWeight,
            fontStyle: props.layer.style.fontStyle,
            textTransform: props.layer.style.textTransform,
            textDecoration: props.layer.style.textDecoration,
            lineHeight: props.layer.style.lineHeight,
            opacity: props.layer.style.opacity,
            left: newLeft,
            top: newTop,
            width: `${props.layer.size.width}%`,
            height: `${props.layer.size.height}%`,
            clipPath: props.layer.style.clipPath,
            boxShadow: props.layer.style.boxShadow,
            transform: props.layer.style.transform,
            display: props.layer.style.display,
            alignItems: props.layer.style.alignItems,
            justifyContent: props.layer.style.justifyContent,
            borderRadius: props.layer.style.borderRadius ? `${props.layer.style.borderRadius}%` : "",
            textShadow: props.layer.style.textShadow,
            writingMode: props.layer.style.writingMode,
            borderWidth: props.layer.style.borderWidth,
            borderStyle: props.layer.style.borderStyle,
            borderImage: props.layer.style.borderImage,
            borderImageSlice: props.layer.style.borderImageSlice,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
            letterSpacing: props.layer.style.letterSpacing,
            padding: props.layer.style.padding,
            position: "absolute",
            overflow: "hidden"

        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size])

    let localStyle = {
        color: myStyle.color,
        fontFamily: myStyle.fontFamily,
        fontSize: parseInt(myStyle.fontSize),
        textAlign: myStyle.textAlign,
        fontWeight: myStyle.fontWeight,
        fontStyle: myStyle.fontStyle,
        textTransform: myStyle.textTransform,
        textDecoration: myStyle.textDecoration,
        lineHeight: myStyle.lineHeight,
        opacity: myStyle.opacity,
        position: "absolute",
        // paddingLeft: '8px',  //this line is useless please check 
        overflow: "hidden",
        boxShadow: props.layer.style.boxShadow,
        transform: props.layer.style.transform,
        alignItems: props.layer.style.alignItems,
        justifyContent: props.layer.style.justifyContent,
        textShadow: props.layer.style.textShadow,
        writingMode: props.layer.style.writingMode,
        borderWidth: props.layer.style.borderWidth,
        borderStyle: props.layer.style.borderStyle,
        borderImage: props.layer.style.borderImage,
        borderImageSlice: props.layer.style.borderImageSlice,
        borderTopRightRadius: props.layer.style.borderTopRightRadius,
        borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
        borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
        borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        letterSpacing: props.layer.style.letterSpacing,
        padding: props.layer.style.padding,
        width: '100%',
        height: "100%",
        display: "inline-block",
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        clipPath: props.layer.style.clipPath,
        background: myStyle.background,
        border: props.layer.style.border,
        borderRadius: myStyle.borderRadius,
        backdropFilter: props.layer.style.backdropFilter,
        filter: props.layer.style.filter
    }



    if (localStyle.borderRadius === "" || localStyle.borderRadius === undefined) {
        delete localStyle.borderRadius
        localStyle = {
            ...localStyle,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        }
    }

    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.layerIndex))
    }


    return (
        <Rnd
            dragGrid={[1, 1]}
            className={`${props.layer.isSelected ? "layer-border" : ""} p-0`}
            enableResizing={props.layer.isSelected ? true : false}
            disableDragging={props.layer.isSelected ? false : true}
            onClick={() => props.handleSelectLayer(props.layerIndex)}
            default={{ x: 0, y: 0, width: 100, height: 100 }}
            // size={{ width: myStyle.width, height: myStyle.height }}
            // position={{ x: myStyle.left, y: myStyle.top }}
            size={{ width: `${props.layer.size.width}%`, height: `${props.layer.size.height}%` }}
            position={{ x: newLeft, y: newTop }}
            onDragStop={(e, d) => {
                let rndLeft = ((d.x / ediotWidth) * 100)
                let rndTop = ((d.y / ediotHeight) * 100)
                // setMyStyle({ ...myStyle, left: rndLeft, top: rndTop })
                props.handleRePosition(rndTop, rndLeft, props.layerIndex)
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                let rndLeft = (position.x / ediotWidth) * 100
                let rndTop = (position.y / ediotHeight) * 100
                // setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height, top: rndTop, left: rndLeft });
                props.handleResize(ref.style.width, ref.style.height, rndLeft, rndTop, props.layerIndex)
            }}
            bounds=".canvasEditor-main"
        >
            {props.layer.src ?
                <img src={props.layer.src}
                    className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""}`}
                    style={localStyle}
                    onAnimationEnd={() => handleAnimEnd()}

                /> :
                <span
                    className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""}`}
                    style={localStyle}
                    onAnimationEnd={() => handleAnimEnd()}
                >
                </span>
            }
        </Rnd>
    )
}

export default ShapeLayer