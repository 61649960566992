import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FiTrash2 } from 'react-icons/fi'

const MergeTiles = ({ mergeVideos, setCurrentSlide, setMergeVideos }) => {



    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );
        setMergeVideos(items);
    }

    const activeSlide = (video, index) => {
        let data = [...mergeVideos]
        let selectedIndex = data.findIndex(({ isSelected }) => isSelected === true)
        if (selectedIndex !== -1) {
            data[selectedIndex].isSelected = false
            data[index].isSelected = true
        }
        setCurrentSlide(video)
    }

    const deleteSlide = (e, index) => {
        e.stopPropagation()
        let removeArr = [...mergeVideos];
        if (removeArr[index].isSelected === true) {
            if (removeArr.length > 1) {
                removeArr[index].isSelected = false
                if (index !== 0) {
                    removeArr[index - 1].isSelected = true
                } else {
                    removeArr[index + 1].isSelected = true
                }
            }
        }
        removeArr.splice(index, 1);
        setMergeVideos(removeArr);
    }


    return (
        <DragDropContext onDragEnd={onSetRecorder}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <ul
                        className="d-flex list-inline align-items-center m-0"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {mergeVideos.length > 0 ?
                            mergeVideos.map((curElem, index) => {
                                return (
                                    <Draggable
                                        key={index}
                                        draggableId={`id_${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <li
                                                style={{}}
                                                onClick={() => activeSlide(curElem, index)}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                key={index}
                                            >
                                                <div style={{ position: "relative", width: "100%", height: "100%", border: `${curElem.isSelected ? '2px solid #202265' : ''}`, borderRadius: 8 }}>
                                                    <img src={curElem.preview_image} alt="" style={{ borderRadius: 5, height: "100%" }} />
                                                    <FiTrash2
                                                        className="merge-delete"
                                                        size={20}
                                                        onClick={(e) => deleteSlide(e, index)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })
                            : null}
                    </ul>
                )}
            </Droppable >
        </DragDropContext>
    )
}

export default MergeTiles