import React, { useEffect, useRef, useState } from 'react'
import HTMLFlipBook from 'react-pageflip';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { onFetchEbook } from '../../../../../Redux/Actions/ProjectActions';
import { useImmer } from 'use-immer';
import TitleBar from '../../../../title-bar/TitleBar';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';
import { MdArrowBackIos } from 'react-icons/md';

const FlipBook = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = queryString.parse(location.search)
    const [ebookData, setEbookData] = useImmer(false)
    const [pageLoder, setPageLoader] = useState(true)
    const [pageArr, setPageArr] = useState([])

    const characterLimitPerPage = 990;


    const splitContentIntoPages = (content, limit) => {
        const words = content.split(' ');
        let currentPage = '';
        const pages = [];

        for (const word of words) {
            if (currentPage.length + word.length <= limit) {
                currentPage += word + ' ';
            } else {
                pages.push({
                    stuff: currentPage.trim(),
                    heading: ""
                });
                currentPage = word + ' ';
            }
        }

        if (currentPage.trim().length > 0) {
            pages.push({
                stuff: currentPage.trim(),
                heading: ""
            });
        }

        return pages;
    };

    useEffect(() => {
        if (ebookData) {
            if (ebookData.savedContent.length > 0) {
                let newArr = []
                ebookData.savedContent.forEach((curElem, index) => {
                    let semiArr = splitContentIntoPages(curElem.content, characterLimitPerPage)
                    if (semiArr.length > 0) {
                        semiArr[0].heading = curElem.title
                    }
                    newArr = newArr.concat(semiArr)
                })
                setPageArr(newArr)
            }
        }
    }, [ebookData])


    const fetchVideo = () => {
        let data = {
            id: id
        }
        dispatch(onFetchEbook(data, setEbookData, setPageLoader))
    }
    useEffect(() => {
        if (id) {
            fetchVideo()
        }
    }, [id])

    return (
        pageLoder ?
            <>
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </>
            :
            <>
                <TitleBar title="Flip Book" />
                <div className=''>
                    <Header />
                </div>
                <section className='page-flip'>
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">

                            <h2><Link className='text-dark' to={`/projects/update-ebook?cid=${ebookData.cId}&id=${id}`}><MdArrowBackIos className='pb-1 ' />Flip Book</Link></h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="previewBook" style={{ padding: "30px 0" }}>
                                    <HTMLFlipBook
                                        // width={"100%"}
                                        // height={"100%"}
                                        width={550}
                                        height={603}
                                        size="fixed"
                                        minWidth={315}
                                        maxWidth={"100%"}
                                        minHeight={400}
                                        maxHeight={"100%"}
                                        maxShadowOpacity={0.5}
                                        showCover={true}
                                        mobileScrollSupport={true}
                                        className="demo-book"
                                        flippingTime={1000}
                                    >
                                        <div className="page page-cover">
                                            <div className="page-content">
                                                <img src={ebookData.image} />
                                                <h2>{ebookData.name}</h2>
                                            </div>
                                        </div>

                                        {pageArr.length > 0 ?
                                            pageArr.map((curElem, index) => {
                                                return (
                                                    <div className="demoPage" key={index}>

                                                        <h1>{curElem.heading}</h1>
                                                        <p dangerouslySetInnerHTML={{ __html: curElem.stuff }} />
                                                        <div className='page-footer'>
                                                            <span>{index + 1}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : <></>}

                                        <div className="page page-cover">
                                            <div className="page-content">
                                                <span> End</span>
                                            </div>
                                        </div>
                                    </HTMLFlipBook >
                                </div>
                            </div>
                        </div >
                    </div >
                </section>
                <Footer />
            </>
    )
}

export default FlipBook