import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { onCreateNew } from '../../../../../Redux/Actions/ProjectActions'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import { GiCheckMark } from 'react-icons/gi'

const EbookTemplateModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [loaderFetch, setLoaderFetch] = useState({
        fetch: false
    })
    const [temps, setTemps] = useState([])
    const [template, setTemplate] = useState(1)

    const handleSubmit = () => {
        let obj = { ...props.show.data }
        obj.templateId = template
        setLoader(true)
        dispatch(onCreateNew(props.url, obj, props.fetchData, props.handleClose, setLoader))
    }

    const handleChooseTemp = (id) => {
        setTemplate(id)
    }


    const fetchTemplates = () => {
        setLoaderFetch({
            ...loaderFetch,
            fetch: true
        })
        dispatch(fetchData("list-image-templates", {}, setTemps, loaderFetch, setLoaderFetch))
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    return (
        <Modal className='theme-modal video-modal xl' show={props.show.enable} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <h3 className='text-capitalize'>Choose Ebook Template</h3>
                <div className='row p-4 text-center' style={{height:500, overflow : "auto"}}>
                    {temps.length > 0 ?
                        temps.map((curElem, index) => {
                            return (
                                <div className='col-md-4 mb-3' key={index}>
                                    <div className='ebook-template-box' onClick={() => handleChooseTemp(curElem.id)}>
                                        <img src={curElem.previewUrl} alt='' />
                                        {+template === +curElem.id ?
                                            <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                <GiCheckMark />
                                            </div> : ""}
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className='mt-3'>
                            {loader ? <i className='fa fa-spinner fa-spin' style={{ fontSize: 25, color: "#3f429b" }} /> : null}
                        </div>
                    }
                </div>
                <div className='common-input-wrap text-end'>
                    <button className='site-link bdr ' type='button' onClick={props.handleClose}><span>Cancel</span></button>
                    <button className='site-link ms-1' type='button' onClick={handleSubmit}><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin" /> </> : "Create"}</span></button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EbookTemplateModal