import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { onEditClient } from '../../../Redux/Actions/AccountManageAction';

const EditMemberModal = ({ show, handleClose, user, fetchMembers }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        id: "",
        name: "",
        email: "",
        password: ""
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (details.password) {
            if (pattern.test(details.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                setLoader(true);
                dispatch(onEditClient(details, fetchMembers, handleClose, setLoader))
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            dispatch(onEditClient(details, fetchMembers, handleClose, setLoader))
        }
    }


    useEffect(() => {
        setDetails({
            ...details,
            name: user.name,
            email: user.email,
            id: user.id
        })
    }, [user])

    useEffect(() => {
        if (!show) {
            setPasswordMsg({
                ...passwordMsg,
                msg: "",
                validation: false
            })
        }
    }, [show])

    return (
        <Modal className='theme-modal xl' show={show} onHide={handleClose} centered scrollable>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='info-tab'>
                        <h3>Edit Team Member</h3>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Account Type</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        value={"Team"}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Account Name</label>
                                    <input
                                        className='common-input'
                                        type="text"
                                        name='name'
                                        value={details.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Email</label>
                                    <input
                                        className='common-input'
                                        type="email"
                                        name='email'
                                        value={details.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Password</label>
                                    <input
                                        className='common-input'
                                        type="password"
                                        value={details.password}
                                        name='password'
                                        onChange={handleChange}
                                    />
                                </div>
                                <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='site-link bdr' type='button' onClick={handleClose}><span>Cancel</span></button>
                    <button className='site-link' type='submit' ><span>{loader ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save"}</span></button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditMemberModal