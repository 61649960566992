import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../title-bar/TitleBar';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';

import { onFetchBlog, onFetchEbook, onFetchScript, onGeneratePdf, onUpdateBlog, onUpdateEbook, onUpdateScript } from '../../../../../Redux/Actions/ProjectActions';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { MdArrowBackIos } from "react-icons/md";
import ImagesModal from '../BonusAssets/BonusPage/Modals/ImagesModal';
import defaultImage from "../../../../../assets/images/dummyImage.jpg"
import { FiUpload } from 'react-icons/fi';
import { useImmer } from 'use-immer';
import CommonWysiwyg from '../../../../CommonComponent/CommonWysiwyg ';
import { Dropdown } from 'react-bootstrap';

const UpdateEbook = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id, cid } = queryString.parse(location.search)

    const [ebookData, setEbookData] = useImmer(false)
    const [loader, setLoader] = useState(true)
    const [show, setShow] = useState(false)
    const [buttonLoader, setButtonLoader] = useState({
        save: false,
        generate: false
    })
    const [chapterIndex, setChapterIndex] = useState(0)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [selectedValue, setSelectedValue] = useState(null);

    const handleDropdownSelect = (value) => {
        setSelectedValue(value);

        if (value === "Preview PDF") {
            handleSubmit(true)
        } else if (value === "Preview FlipBook") {
            navigate(`/projects/your-book?id=${id}`)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "content") {
            setEbookData((draft) => {
                draft.savedContent[chapterIndex].content = value
            })

        } else {
            setEbookData({
                ...ebookData,
                [name]: value
            })
        }
    }
    const handleSubmit = (isGenerate) => {
        if (ebookData.savedContent && ebookData.aiTitle) {
            setButtonLoader({
                ...buttonLoader,
                save: isGenerate ? false : true,
                generate: isGenerate ? true : false

            })
            dispatch(onUpdateEbook(ebookData, buttonLoader, setButtonLoader, isGenerate))
        } else {
            dispatch(setAlert("Please provide content!", "danger"))
        }
    }
    const handleAddMemberImage = (data) => {
        let obj = {
            target: {
                name: "image",
                value: data.url
            }
        }
        handleChange(obj)
    }


    const dataFunction = (res, name) => {
        let data = {
            target: {
                value: res,
                name,
            }
        }
        handleChange(data)
    }

    const fetchVideo = () => {
        let data = {
            id: id
        }
        dispatch(onFetchEbook(data, setEbookData, setLoader))
    }

    const handleChangeChapter = (index) => {
        setChapterIndex(index)
    }

    useEffect(() => {
        if (id && cid) {
            fetchVideo()
        }
    }, [id, cid])


    return (
        loader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Update Ebook" />
                <Header />

                <section className='page-wrap'>
                    <div className='container'>

                        <div className='flex-bar'>
                            <h4 style={{ fontSize: 28 }}><Link className='text-dark' to={`/projects/assets?cid=${cid}`}><MdArrowBackIos className='pb-1' />Update Ebook</Link></h4>
                            <div className='d-flex align-items-center'>
                                <Dropdown className='site-link-2 ms-1' onSelect={(value) => handleDropdownSelect(value)}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ background: "none", border: "none", width: '100%' }}>
                                        {selectedValue ? selectedValue : 'Select Preview'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="Preview PDF"> Preview PDF</Dropdown.Item>
                                        <Dropdown.Item eventKey="Preview FlipBook">Preview FlipBook</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button className='site-link ms-1' onClick={() => handleSubmit(false)}><span>{buttonLoader.save ? <>Updating <i className="fa fa-spinner fa-spin" /></> : "Update"}</span></button>
                            </div>
                        </div>

                        <div className='sm-containern'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="inpLabelWrap">
                                        <span className='labelTxt'>Title</span>
                                        <input
                                            className='common-input'
                                            value={ebookData.aiTitle}
                                            name='aiTitle'
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="inpLabelWrap">
                                        <span className="labelTxt">Image</span>
                                        <div className="inpLabel">
                                            <span onClick={handleShow} style={{ cursor: "pointer" }} className="fullLink"></span>
                                            <span className='uploaded-img'><img src={ebookData.image !== "" ? ebookData.image : defaultImage} alt="" /></span>
                                            <input
                                                className="inpLabel-inp"
                                                placeholder=""
                                                type="text"
                                            />
                                            <span className="inpBtn-icon">
                                                <FiUpload /> <span className="pl-1">Choose</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12'>

                                    <div className="inpLabelWrap">
                                        <span className='mb-1 labelTxt'>Chapter ({+chapterIndex + 1})</span>
                                        <select
                                            className='common-input'
                                            value={chapterIndex}
                                            onChange={(e) => handleChangeChapter(e.target.value)}
                                        >
                                            {ebookData.savedContent.length > 0 ?
                                                ebookData.savedContent.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={index}>{curElem.title}</option>
                                                    )
                                                })
                                                : null}
                                        </select>
                                    </div>
                                    <div className="inpLabelWrap">
                                        <div className='sm-gap mt-3 article-wysiwyg' >
                                            <span className='mb-1 labelTxt'>Chapter Content</span>
                                            {ebookData.savedContent.length > 0 ?
                                                ebookData.savedContent.filter((cur, ind) => {
                                                    return +chapterIndex === +ind
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <CommonWysiwyg
                                                                text={curElem.content.replaceAll("\n", `<br>`)}
                                                                name="content"
                                                                dataFunction={dataFunction}
                                                            // isChange={isChange}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ImagesModal
                    show={show}
                    handleClose={handleClose}
                    callbackFun={handleAddMemberImage}
                />
                <Footer />
            </>
    )

}

export default UpdateEbook;