import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { MdArrowBackIos } from "react-icons/md";

import Header from "../../../../../header/Header";
import TitleBar from "../../../../../title-bar/TitleBar";
import Footer from "../../../../../footer/Footer";
import GooglePage from "./GooglePage";
import { onFetchAds } from "../../../../../../Redux/Actions/ProjectActions";
import { saveAs } from 'file-saver';

const UpdateBanner = () => {
    const JSZip = require("jszip");
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, cid } = queryString.parse(location.search)
    const banner = useSelector(state => state.banner.data)
    const [loader, setLoader] = useState({
        submitLoader: false,
        pageLoader: true
    })

    const fetchLandingData = () => {
        let data = {
            id: id
        }
        dispatch(onFetchAds(data, loader, setLoader))
    }

    const handleDownload = async () => {

        // function convert(oldImag, callback) {
        //     console.log(oldImag)
        //     var img = new Image();
        //     img.onload = function () {
        //         callback(img)
        //     }
        //     img.setAttribute('crossorigin', 'anonymous');
        //     img.src = oldImag;
        // }
        // function getBase64Image(img, callback) {
        //     convert(img, function (newImg) {
        //         var canvas = document.createElement("canvas");
        //         canvas.width = newImg.width;
        //         canvas.height = newImg.height;
        //         var ctx = canvas.getContext("2d");
        //         ctx.drawImage(newImg, 0, 0);
        //         var base64 = canvas.toDataURL("image/png");
        //         callback(base64)
        //     })
        // }
        // getBase64Image(banner.image, function (base64) {
        //     console.log(base64)
        // });


        var zip = new JSZip();
        zip.file("Headline.txt", banner.title);
        zip.file("Description.txt", banner.description);
        // zip.file("image.jpg", imgData, { base64: true });
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "ads.zip");
            });
    }

    useEffect(() => {
        if (id) {
            fetchLandingData()
        }
    }, [id])


    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <Header />
                <TitleBar title="Opt-in Page" />
                <div className="page-wrap">
                    <div className="container">
                        <div className="business-wrap">
                            <div className="business-top">
                                <h2><Link className="text-dark" to={`/projects/assets?cid=${banner.cId}`}><MdArrowBackIos className='pb-1' />Ads  </Link></h2>
                                {/* <button onClick={handleDownload} className="site-link" ><span>Download</span></button> */}
                            </div>
                            <GooglePage />

                        </div>
                    </div>
                </div>
                <Footer />
            </>
    )
}

export default UpdateBanner;
