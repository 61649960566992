import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import { useSelector } from 'react-redux'
import { setAlert } from '../../Redux/Actions/AlertActions'
import getCroppedImg from './CropImage'
import { useDispatch } from 'react-redux'
import { onGenerateFile, onVideoCrop } from '../../Redux/Actions/VideoActions'

const CropModal = ({ show, handleClose, type, cropData, setCropData, handleSelectImage }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        load: false,
        crop: false
    })
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


    const handleUse = () => {
        let data
        if (type === "images") {
            data = {
                url: cropData.cropped
            }
        } else {
            data = {
                url: cropData.cropped,
                thumbnail: cropData.thumbnail
            }
        }
        handleSelectImage(data)
        handleClose()
    }

    const onCropImage = useCallback(async () => {
        setLoader({
            ...loader,
            crop: true
        })
        try {
            const croppedImage = await getCroppedImg(
                cropData.current,
                croppedAreaPixels,
                0
            )
            downloadFiles(croppedImage);
        } catch (e) {
            dispatch(setAlert(e, "danger"))
            console.error(e)
        }
    })

    const onCropVideo = () => {
        setLoader({
            ...loader,
            crop: true
        })
        const formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', cropData.current);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        dispatch(onVideoCrop(formData, loader, setLoader, cropData, setCropData))
    }


    const downloadFiles = (imageData) => {
        let ext = cropData.current.match(/\.([^\./\?]+)($|\?)/)[1];

        let data = {
            data: imageData,
            format: ext
        }
        dispatch(onGenerateFile(data, loader, setLoader, cropData, setCropData))
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(() => {
        if (!show) {
            setCropData({
                ...cropData,
                current: false,
                cropped: false
            })
        }
        return () => {
            setCroppedAreaPixels(null)

        }
    }, [show])

    return (
        <Modal className='theme-modal xl' show={show} onHide={handleClose} centered scrollable>
            <Modal.Header className="crop-header">
                <Modal.Title className="text-center">
                    <h3>Crop {type === "images" ? "image" : type}</h3>
                </Modal.Title>

            </Modal.Header>
            <Modal.Body style={{ padding: "20px 40px", position: "relative", height: "50vh" }} >
                <div className='info-tab' >

                    <div className='row'>
                        <div className='col-sm-12' >
                            {type === "images" ?
                                <Cropper
                                    image={cropData.current}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={campaign.dimension === "1920x1080" ? 16 / 9 : campaign.dimension === "1080x1920" ? 9 / 16 : campaign.dimension === "1080x1080" ? 1 / 1 : 16 / 9}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    objectFit="contain"
                                /> :
                                <Cropper
                                    video={cropData.current}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={campaign.dimension === "1920x1080" ? 16 / 9 : campaign.dimension === "1080x1920" ? 9 / 16 : campaign.dimension === "1080x1080" ? 1 / 1 : 16 / 9}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />

                            }
                            {
                                croppedAreaPixels === null ?
                                    <div className="crop-icon-center" style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                    }}>
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#3f429b", fontSize: '25px' }} />
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='site-link'
                    onClick={type === "images" ? onCropImage : onCropVideo}
                >
                    <span>{loader.crop ? <>Cropping <i className="fa fa-spinner fa-spin mx-1" /></> : "Crop"}</span>
                </button>
                <button
                    className='site-link'
                    onClick={handleUse}
                    style={{ background: cropData.cropped ? "" : "gray" }}
                    disabled={!cropData.cropped}
                >
                    <span>Use</span>
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default CropModal