import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import TitleBar from '../../title-bar/TitleBar';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import FbTemplates from './FbTemplates';
import VipEventCalendar from './VipEventCalendar';
import ClientContract from './ClientContract';
import ResellerLicense from './ResellerLicense';
import VideoCommercial from './VideoCommercial';
import DfyLeadMagnets from './DfyLeadMagnets';
import AgencyWebsite from './AgencyWebsite';
import Rebranding from './Rebranding';
import VideoFlix from './VideoFlix';
import DfyCourse from './DfyCourse';
import WebHosting from './WebHosting';
import SocialMediaAssets from './SocialMediaAssets';
import BusinessFinder from './BusinessFinder';
import DfyWhitelabelContentStreams from './DfyWhitelabelContentStreams';
import { useSelector } from 'react-redux';
import DfyArticles from './DfyArticles';
import Acadeable from './Acadeable';
import Accordion from 'react-bootstrap/Accordion';
import Week1 from './Week1';
import Week2 from "./Week2";
import Week3 from "./Week3";
import Week4 from "./Week4";
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeKeySearch from './YoutubeKeySearch';
const Upgrade = () => {

    const auth = useSelector(state => state.auth)
    const [membership, setMembership] = useState([])

    useEffect(() => {
        if (auth) {
            if (auth.user) {
                setMembership(auth.user.membership.split("__"))
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="Upgrade" />
            <Header></Header>

            <section className='page-wrap'>
                <div className='container'>
                    <h1 className='page-title'>Upgrade</h1>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab-0">


                        <div className='upgrade-container'>
                            <div className='upgrade-left'>
                                <Nav variant="pills" className="flex-column">

                                    {membership.includes("ultimate") || membership.includes("fastpass") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-14">Acadeable</Nav.Link></Nav.Item>
                                            {/* <Nav.Item><Nav.Link eventKey="tab-9">Video Flix</Nav.Link></Nav.Item> */}
                                        </>
                                        : ""
                                    }

                                    {membership.includes("fastpass") || membership.includes("bundle") || membership.includes('dfy') ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-33">YouTube Keyword Research</Nav.Link></Nav.Item>
                                            {/* <Nav.Item><Nav.Link eventKey="tab-14">Master Class</Nav.Link></Nav.Item> */}
                                            <Accordion className='master_upgrade_wrap'>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header style={{ color: "#424242" }}>Master Class</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Nav.Item><Nav.Link eventKey="tab-28">Week 1</Nav.Link></Nav.Item>
                                                        <Nav.Item><Nav.Link eventKey="tab-29">Week 2</Nav.Link></Nav.Item>
                                                        <Nav.Item><Nav.Link eventKey="tab-30">Week 3</Nav.Link></Nav.Item>
                                                        <Nav.Item><Nav.Link eventKey="tab-31">Week 4</Nav.Link></Nav.Item>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            {/* <Nav.Item><Nav.Link eventKey="tab-9">Video Flix</Nav.Link></Nav.Item> */}
                                        </>
                                        : ""
                                    }

                                    {membership.includes("enterprise") || membership.includes("fastpass") || membership.includes("bundle") ? <>
                                        <Nav.Item><Nav.Link eventKey="tab-8">Rebranding</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-13">Business Finder</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-1">FB Templates</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-3">Client Contract</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-4">Reseller License</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-6">DFY Lead Magnets</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-7">Agency Website</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-10">DYF Course</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-11">Web Hosting</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-32">DFY Articles</Nav.Link></Nav.Item>

                                    </> : ""}


                                    {/* {membership.includes("enterprise") || membership.includes("bundle") ?
                                        <>
                                            <Nav.Item><Nav.Link eventKey="tab-33">YouTube Keyword Research</Nav.Link></Nav.Item>
                                        </> : ''
                                    } */}

                                    {/* <Nav.Item><Nav.Link eventKey="tab-5">Video Commercial</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-9">Video Flix</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-10">DFY Course</Nav.Link></Nav.Item> */}
                                    {/* <Nav.Item><Nav.Link eventKey="tab-12">Social Media Assets</Nav.Link></Nav.Item> */}

                                </Nav>
                            </div>
                            <div className='upgrade-right'>
                                <div className='upgrade-right-in'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tab-0" className='firstTab'>
                                            <img alt='' src={require('../../../assets/images/upgrade.png')} />
                                            <h2 className='pt-5'>Welcome!</h2>
                                            <h3>Choose any option to upgrade</h3>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab-1"><FbTemplates /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-2"><VipEventCalendar /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-3"><ClientContract /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-4"><ResellerLicense /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-5"><VideoCommercial /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-6"><DfyLeadMagnets /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-7"><AgencyWebsite /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-8"><Rebranding /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-9"><VideoFlix /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-10"><DfyCourse /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-11"><WebHosting /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-12"><SocialMediaAssets /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-13"><BusinessFinder /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-14"><Acadeable /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-26"><DfyWhitelabelContentStreams /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-32"><DfyArticles /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-28"><Week1 /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-29"><Week2 /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-30"><Week3 /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-31"><Week4 /></Tab.Pane>
                                        <Tab.Pane eventKey="tab-33"><YoutubeKeySearch /></Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>

                    </Tab.Container>


                </div>
            </section>

            <Footer></Footer>
        </>
    )

}

export default Upgrade;