import React, { useEffect, useState } from 'react'
import CreateModalAssets from '../CreateModalAssets'
import { FaSpinner } from 'react-icons/fa'
import BonusRow from '../BonusAssets/BonusRow'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../../../Redux/Actions/CommonActions'
import EbookRow from './EbookRow'
import EbookTemplateModal from './EbookTemplateModal'

const EbookData = ({ createData, handleCreate, cid, handleClose, country, language }) => {
    const dispatch = useDispatch()
    const [ebooks, setEbooks] = useState([])
    const [show, setShow] = useState({
        enable: false,
        data: false
    })
    const [ebookLoader, setEbookLoader] = useState({
        fetch: false
    })

    const handleShowTemp = (obj) => {
        setShow({
            ...show,
            enable: true,
            data: obj
        })
    }
    const handleCloseTemp = () => {
        setShow({
            ...show,
            enable: false,
            data: false
        })
    }

    const fetchAllEbook = () => {
        let data = {
            cId: cid
        }
        setEbookLoader({
            ...ebookLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-ebook-all", data, setEbooks, ebookLoader, setEbookLoader))
    }

    useEffect(() => {
        fetchAllEbook()
    }, [])
    return (
        <>
            <div className='title-head mt-5'>
                <h2>Ebook</h2>
                <button className='site-link' onClick={() => handleCreate("ebook")}><span>Create Ebook</span></button>
            </div>
            <div className='cont-box'>
                <div className='video-table'>
                    <div className='table-responsive'>
                        <table className='table text-center'>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th></th>
                            </tr>
                            {ebooks.length > 0 ?
                                ebooks.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <EbookRow
                                                curElem={curElem}
                                                index={index}
                                                ebooks={ebooks}
                                                setEbooks={setEbooks}
                                                fetchAllEbook={fetchAllEbook}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <tr >
                                    <td colSpan={5} className="text-center load-icon-help mt-3">
                                        {
                                            ebookLoader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                                <p style={{ fontSize: 15 }}>
                                                    You do not have ebooks in this campaign.
                                                </p>
                                        }
                                    </td>
                                </tr>
                            }

                        </table>
                    </div>
                </div>
            </div>

            <CreateModalAssets
                show={createData.show && createData.type === "ebook"}
                handleClose={handleClose}
                type={createData.type}
                cid={cid}
                country={country}
                language={language}
                handleShowTemp={handleShowTemp}
            />

            <EbookTemplateModal
                show={show}
                handleClose={handleCloseTemp}
                fetchData={fetchAllEbook}
                url={createData.url}
            />
        </>
    )
}

export default EbookData