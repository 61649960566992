import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import HeaderSection from "./HeaderSection";
import AddonDomain from "./AddonDomain";
import VideoSection from "./VideoSection";
import BenifitSection from "./BenifitSection";
import FooterSection from "./FooterSection";
import { onCheckedToggle, onFetchLanding, onUpdateLanding } from "../../../../../../Redux/Actions/ProjectActions";
import Header from "../../../../../header/Header";
import Footer from "../../../../../footer/Footer";
import TitleBar from "../../../../../title-bar/TitleBar";
import { MdArrowBackIos } from "react-icons/md";
import PageStyleSection from "./PageStyleSection";
import BonusSection from "./BonusSection";
import ThankyouSection from "./ThankyouSection";
import AicoachSection from "./AicoachSection";

const EditBonusPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lpId = queryString.parse(location.search).id
    const landingData = useSelector(state => state.bonus.data)

    const [loader, setLoader] = useState({
        submitLoader: false,
        pageLoader: true
    })

    const [addon, setAddon] = useState(false)

    const fetchLandingData = () => {
        let data = {
            id: lpId
        }
        dispatch(onFetchLanding(data, loader, setLoader))
    }

    const handleChecked = (e) => {
        const { name, checked } = e.target
        if (name === "addon") {
            if (addon) {
                setAddon(false)
            } else {
                setAddon(true)
            }
        }
        dispatch(onCheckedToggle(name, checked))
    }

    const onCopyLink = () => {
        var copyText = document.getElementById("liveLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    const handleSubmit = () => {
        let data = { ...landingData }
        delete data.domain
        setLoader({
            ...loader,
            submitLoader: true
        })
        dispatch(onUpdateLanding(data, navigate, loader, setLoader))
    }

    const handlePreview = () => {
        window.open(landingData.liveLink, "_blank")
    }

    useEffect(() => {
        if (lpId) {
            fetchLandingData()
        }
    }, [lpId])

    useEffect(() => {
        if (landingData.domain.enable) {
            setAddon(landingData.domain.enable)
        }
    }, [landingData])

    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <Header />
                <TitleBar title="Bonus Page" />
                <div className="page-wrap">
                    <div className="container">
                        <div className="business-wrap">
                            <div className="business-top">
                                <h2><Link className="text-dark" to={`/projects/assets?cid=${landingData.cId}`}><MdArrowBackIos className='pb-1' />Edit Landing Page  </Link></h2>
                                <div>
                                    <Link className="site-link mx-2" to={`/template-customize?productLink=${landingData.liveLink}&productId=${lpId}&type=bonus`} target="_blank"><span>Edit in WYSIWYG</span></Link>
                                    <button onClick={handlePreview} className="site-link" ><span>Preview</span></button>
                                </div>
                            </div>

                            <div className="business-list">
                                <div className="inpLabelWrap mt-0">
                                    <span className="labelTxt">Live Link</span>
                                    <div className="inpLabel">
                                        <label htmlhtmlFor="liveLink">Live Link</label>
                                        <input
                                            className="inpLabel-inp"
                                            value={landingData.liveLink}
                                            type="text"
                                            readOnly
                                            id="liveLink"
                                        />
                                        <span className="inpBtn-icon " style={{ cursor: "pointer" }} onClick={onCopyLink}>
                                            <FiCopy /> <span className="ps-1">Copy</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Add Your Domain</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleChecked(e)}
                                                name="addon"
                                                checked={addon}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    addon ?
                                        <AddonDomain />
                                        : ""
                                }

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Page Style</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="style"
                                                checked={landingData.style.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {landingData.style.enable ?
                                    <PageStyleSection /> : ""}

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Header Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="header"
                                                checked={landingData.headers.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.headers.enable ?
                                        <HeaderSection />
                                        : ""
                                }

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Video Embed Code Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="videos"
                                                checked={landingData.videos.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.videos.enable ?
                                        <VideoSection />
                                        : ""
                                }

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Product Features Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="benefits"
                                                checked={landingData.benefits.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.benefits.enable ?
                                        <BenifitSection />
                                        : ""
                                }

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Bonus Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="bonus"
                                                checked={landingData.bonus.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.bonus.enable ?
                                        <BonusSection />
                                        : ""
                                }

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Thank You Page Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="thankyou"
                                                checked={landingData.thankyou.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.thankyou.enable ?
                                        <ThankyouSection />
                                        : ""
                                }
                                <AicoachSection />

                                <div className="inpLabel d-flex align-items-center justify-content-between line">
                                    <label htmlFor="">Footer Section</label>
                                    <div className="d-flex">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name="footer"
                                                checked={landingData.footer.enable}
                                                onChange={(e) => handleChecked(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    landingData.footer.enable ?
                                        <FooterSection />
                                        : ""
                                }

                                <div className="btn-sec text-end mt-3">
                                    <button onClick={handleSubmit} className="site-link"><span>{loader.submitLoader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </>
    )
}

export default EditBonusPage;