import React, { useEffect, useState } from 'react';
import TitleBar from '../../../title-bar/TitleBar';
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { onFetchCamapign, onRegenrateScript, onRowDataFetch, onUpdateDocs } from '../../../../Redux/Actions/ProjectActions';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdArrowBack } from "react-icons/io";
const EditDocs = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { cid, id, type } = queryString.parse(location.search)

    const [isEnter, setIsEnter] = useState({
        in: false,
        out: false
    })
    const scriptStatus = ["Visiting URL", "Reading page's content", "Analysing content", "Extracting content"]
    const [staText, setStaText] = useState("")
    const [campaignData, setCampaignData] = useState({
        name: ""
    })
    const [loader, setLoader] = useState({
        fetch: true,
        update: false,
        regenrate: false
    })

    const [docData, setDocData] = useState({
        text: "",
        mediaData: []
    })



    const handleChange = (e) => {
        const { name, value } = e.target
        setDocData({
            ...docData,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            update: true
        })
        let ep = ""
        let data = {}
        if (type === "w") {
            ep = "update-write"
            data.id = docData.id
            data.title = docData.title
            data.description = docData.text
        } else if (type === "i") {
            ep = "update-import-data"
            data.id = docData.id
            data.mediaData = docData.mediaData
            data.data = docData.text
            data.websiteUrl = docData.websiteUrl
        } else if (type === "u") {
            ep = "update-document"
            data.id = docData.id
            data.data = docData.text
        }
        else if (type === "m") {
            ep = "update-media"
            data.id = docData.id
            data.data = docData.text
        }
        dispatch(onUpdateDocs(ep, data, loader, setLoader))

    }

    const fetchRowDoc = () => {
        let data = {
            cId: cid,
            id: id,
        }
        let ep = type === "w" ? "fetch-write-campaign" : type === "i" ? "fetch-import-data" : type === "u" ? "fetch-document-data" : type === "m" ? "fetch-media-data" : ""
        dispatch(onRowDataFetch(ep, data, setDocData, type, loader, setLoader))
    }

    const handleRegenerateScript = () => {
        let data = {
            url: docData.websiteUrl,
            id: id,
            type: docData.type
        }
        setLoader({
            ...loader,
            regenrate: true
        })

        let ind = 0
        setStaText(scriptStatus[0])
        const interval = setInterval(() => {
            ind++
            if (ind === 4) {
                ind = 1
            }
            setTimeout(() => {
                setIsEnter({
                    ...isEnter,
                    in: false,
                    out: true
                })
                setTimeout(() => {
                    setStaText(scriptStatus[ind])
                }, 1000)
            }, 1000)
        }, 7000)

        dispatch(onRegenrateScript(data, fetchRowDoc, loader, setLoader, interval))
    }

    const handleDeleteMedia = (e, index) => {
        e.stopPropagation()
        let arr = [...docData.mediaData]
        arr.splice(index, 1)
        setDocData({
            ...docData,
            mediaData: arr
        })
    }


    useEffect(() => {
        if (cid && id && type) {
            fetchRowDoc()
        }
    }, [cid, id, type])

    useEffect(() => {
        if (staText) {
            setIsEnter({
                ...isEnter,
                in: true,
                out: false
            })
        }
    }, [staText])

    const fetchCampaign = () => {
        let data = {
            id: cid
        }
        dispatch(onFetchCamapign(data, setCampaignData, false))
    }

    useEffect(() => {
        fetchCampaign()
    }, [])

    console.log(docData)

    return (
        loader.fetch ?
            <>
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </> :
            <>
                <TitleBar title="Update Document" />
                <Header />

                <section className='page-wrap'>

                    <form className='container' onSubmit={handleSubmit}>
                        <div className='flex-bar'>
                            <h4 className='text-capitalize'>{campaignData.name ? `${campaignData.name} | Update Document` : null}</h4>
                            <div className='text-center'>
                                <Link to={`/projects/knowledge-brain?cid=${cid}`}><button className='site-link bdr me-1'><span><IoMdArrowBack /> Back</span></button></Link>
                                <button className='site-link ' disabled={loader.update}><span >{loader.update ? <>Updating <i className="fa fa-spinner fa-spin" /> </> : "Update"}</span></button>
                            </div>
                        </div>

                        <div className='sm-containern' >
                            <div className='row'>
                                <div className='col-lg-12'>
                                    {type === "w" ?
                                        <>
                                            <label htmlFor="">Title</label>
                                            <div className='p-1'>
                                                <input
                                                    className='common-input'
                                                    type='text'
                                                    value={docData.title}
                                                    name='title'
                                                    placeholder='Enter Title Here'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </> : null
                                    }
                                    {type === "i" ?
                                        <>
                                            <label htmlFor="">Website URL</label>
                                            <div className='p-1' >
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span style={{ position: "relative", width: "100%" }}>
                                                        <input
                                                            className='common-input'
                                                            type='url'
                                                            value={docData.websiteUrl}
                                                            name='websiteUrl'
                                                            placeholder='Enter Website URL'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <select
                                                            className='input-select-box'
                                                            onChange={handleChange}
                                                            name='type'
                                                            value={docData.type}
                                                        >
                                                            <option value={"normal"}>Non E-commerce URL</option>
                                                            <option value={"ecom"}>E-commerce URL</option>
                                                        </select>
                                                    </span>
                                                    <button className='site-link ms-3' type='button' onClick={handleRegenerateScript}><span className='d-flex justify-content-center align-items-center'>{loader.regenrate ? <>Regenerating <i className="fa fa-spinner fa-spin ms-1" /></> : "Regenerate"}</span></button>
                                                </div>
                                                {docData.error ?
                                                    <div className="inpx-wrap mt-2">
                                                        <div className="alert alert-danger" role="alert">
                                                            <span >{docData.error}</span>
                                                        </div>
                                                    </div> : ""}
                                                <div className="swiper_text">
                                                    <div className='text-center my-1 '>
                                                        {loader.regenrate && staText ?
                                                            <p style={{ color: "#3f429b" }} className={`animate__animated ${isEnter.in ? 'animate__lightSpeedInLeft' : ""} ${isEnter.out ? 'animate__lightSpeedOutRight' : ""}`}><strong>{staText}...</strong></p> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </> : null}
                                </div>
                                <div className='col-lg-12'>
                                    <label htmlFor="">Content</label>
                                    <div className='inp-bg p-1' style={{ height: 500 }}>
                                        <textarea
                                            value={docData.text}
                                            onChange={handleChange}
                                            className='common-input'
                                            name='text'
                                            style={{ height: "100%" }}
                                            required
                                        />
                                    </div>
                                </div>
                                {type === "i" ?
                                    <div className='col-lg-12'>
                                        {docData.mediaData.length > 0 ?
                                            <>
                                                <label htmlFor="">Media</label>
                                                <div className='row p-3'>
                                                    {docData.mediaData.map((curElem, index) => {
                                                        return (
                                                            <div className='col-2 p-1' key={index}>
                                                                <div className='scrap-media-files'>
                                                                    <AiOutlineClose className='import-media-cross' title='Remove' onClick={(e) => handleDeleteMedia(e, index)} />
                                                                    <img src={curElem.url} alt='' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                            : ""
                                        }
                                    </div> : null}
                            </div>
                        </div>
                    </form>
                </section >
                <Footer />
            </>
    )

}

export default EditDocs;