import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { onCheckResourcesStatus, onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import dummyIcon from "../../../../../assets/images/defaultIcon.png"
import { Link } from 'react-router-dom'
import { FaRegEdit } from 'react-icons/fa'

const BannerRow = ({ curElem, index, banners, setBanners, fetchAllBanner }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-ads", data, banners, setBanners, setSweet))
    }

    const checkStatus = (interval) => {
        let data = {
            type: "ads",
            id: curElem.id
        }
        dispatch(onCheckResourcesStatus(data, interval, fetchAllBanner))
    }

    useEffect(() => {
        if (+curElem.status === 2) {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem.id])

    return (
        <tr>
            <td>
                <span className='vid-thumb'><img src={dummyIcon} alt="" /></span>
            </td>
            <td>{curElem.name}</td>
            <td ><span className='status-box' style={{ background: +curElem.status === 2 ? "#FF7F50" : "#008000" }}>{+curElem.status === 2 ? "Generating..." : "Generated"}</span></td>
            <td>{curElem.created}</td>
            <td>
                {+curElem.status === 1 ?
                    <Link className='link-icon' title='Edit' to={`/projects/update-ads?cid=${curElem.cId}&id=${curElem.id}`}><FaRegEdit /></Link> : null}
                <a className='link-icon' onClick={() => onDelete(curElem.id)}><BsTrash /></a>
            </td>


            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this banner?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </tr>
    )
}

export default BannerRow