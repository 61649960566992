import React, { useEffect, useState } from 'react'
import { RiArrowGoBackLine, RiArrowGoForwardLine, RiDeleteBin6Line, RiFileCopyLine } from 'react-icons/ri'
import { LuArrowDownWideNarrow, LuArrowUpWideNarrow } from "react-icons/lu"
import { FiSkipForward, FiSkipBack, FiMusic } from 'react-icons/fi'
import { BsStopCircle, BsFillMicFill, BsFillMicMuteFill, BsScissors, BsPlusLg } from "react-icons/bs"
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux'
// import { layerDuplicate, onRedo, onUndo, removeLayer, saveVideo, updateLastAction, updateLayerPos, updatePresent } from '../../../../Redux/Actions/VideoActions'
import PreviewScreen from './PreviewScreen'
import SlidePlayPause from './SlidePlayPause'
import { BiCrop, BiTrashAlt, BiVolumeFull } from "react-icons/bi"
import { MdZoomOutMap } from "react-icons/md"
import { IoMdMusicalNote } from "react-icons/io"
import VideoTiles from '../VideoTile/VideoTiles'
import { layerDuplicate, onRedo, onUndo, removeLayer, saveVideo, updateLastAction, updateLayerPos, updatePresent } from '../../../../../../../Redux/Actions/VideoActions'

const Preview = ({ preLoader }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    let actionArr = ["UNDO", "REDO", "ADD_VIDEO_DATA", "UPDATE_SLIDES_DATA", "END_ANIMATION", "ONPLAY_SLIDE", "UPDATE_WATERMARK_HW", "UPDATE_LOGO_HW"]

    const [allowCall, setAllowCall] = useState(true)
    const [playStatus, setPlayStatus] = useState(false)
    const [undoActive, setUndoActive] = useState(false)
    const [redoActive, setRedoActive] = useState(false)
    const [second, setSecond] = useState(0)
    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })


    const deleteLayer = () => {
        dispatch(removeLayer(selectedSlide, selectedSlideIndex))
    }


    const duplicateLayer = () => {
        if (selectedSlide.layers.length > 0) {
            let layerData = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
            const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (layerDataIndex !== -1) {
                const val = { ...layerData }
                dispatch(layerDuplicate(selectedSlideIndex, val, layerDataIndex))
            }
        }
    }
    const handleLayerUpDown = (type) => {
        const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (layerDataIndex !== -1) {
            if (type === "down") {
                if (layerDataIndex > 0) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "up"))
                }
            } else {
                if (selectedSlide.layers.length - 1 > layerDataIndex) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "down"))
                }
            }
        }
    }

    const handleUndo = () => {
        let id = selectedSlide.id
        if (!undoActive) {
            dispatch(onUndo(id))
        }
    }
    const handleRedo = () => {
        let id = selectedSlide.id
        if (!redoActive) {
            dispatch(onRedo(id))
        }
    }



    useEffect(() => {
        const handleKey = (event) => {
            if (event.keyCode === 46) {
                deleteLayer()
            }
            if (event.keyCode === 90 && (event.ctrlKey || event.metaKey)) {
                handleUndo()
            }
            if (event.keyCode === 89 && (event.ctrlKey || event.metaKey)) {
                handleRedo()
            }
        }
        document.addEventListener("keydown", handleKey)
        return () => document.removeEventListener("keydown", handleKey)
    }, [selectedSlide])

    useEffect(() => {
        if (campaign.undoRedo.length > 0) {
            const undoRedoObj = campaign.undoRedo.find(({ slideId }) => parseInt(slideId) === parseInt(selectedSlide.id))
            if (undoRedoObj) {
                if (undoRedoObj.past.length > 0) {
                    setUndoActive(false)
                }
                else {
                    setUndoActive(true)
                }
                if (undoRedoObj.future.length > 0) {
                    setRedoActive(false)
                }
                else {
                    setRedoActive(true)
                }
            }
        }
    }, [campaign.undoRedo, selectedSlideIndex])

    useEffect(() => {
        if (actionArr.includes(campaign.lastAction)) {
            dispatch(updateLastAction(selectedSlide))
        } else {
            dispatch(updatePresent(selectedSlide))
        }
    }, [selectedSlide])

    useEffect(() => {
        if (selectedSlide) {
            const data = selectedSlide.duration ? selectedSlide.duration : 0
            setTime({
                ...time,
                s: parseInt(data % 60),
                m: Math.floor(data % 3600 / 60),
                h: parseInt(data / 3600)
            })
        }
    }, [selectedSlide.duration])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])


    //Save Slide 

    useEffect(() => {
        if (campaign && allowCall) {
            setAllowCall(false)
            dispatch(saveVideo(campaign, setAllowCall))
        }
    }, [campaign])

    return (
        <>
            <div className="editable">

                <div className="d-flex align-items-center justify-content-center flex-column" style={{ width: "100%", height: "100%" }}>
                    <div className={`${campaign.dimension === "9x16" ? "editor_9X16" : "editor_16X9"}`}>

                        <div className="canvasEditor-main">
                            <PreviewScreen
                                slideData={campaign.slides}
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                                preLoader={preLoader}
                                promo={campaign.promo}
                                dimension={campaign.dimension}
                            />
                        </div>
                    </div>
                    <div className="editor-bar">

                        <div className='editor-bar-left' style={{ height: "100%" }}>
                            <ul>
                                <li
                                    onClick={() => handleUndo()}
                                    style={{ cursor: "pointer" }}
                                    title='Undo'
                                >
                                    <RiArrowGoBackLine className='whiteFont' />
                                </li>
                                <li
                                    onClick={() => handleRedo()}
                                    style={{ cursor: "pointer" }}
                                    title='Redo'
                                >
                                    <RiArrowGoForwardLine className='whiteFont' />
                                </li>
                                <li
                                    onClick={duplicateLayer}
                                    style={{ cursor: "pointer" }}
                                    title='Copy Layer'
                                >
                                    <RiFileCopyLine className='whiteFont' />
                                </li>
                                <li
                                    onClick={() => handleLayerUpDown('down')}
                                    style={{ cursor: "pointer" }}
                                    title='Layer Down'
                                >
                                    <LuArrowDownWideNarrow className='whiteFont' />
                                </li>
                                <li
                                    onClick={() => handleLayerUpDown('up')}
                                    style={{ cursor: "pointer" }}
                                    title='Layer Up'
                                >
                                    <LuArrowUpWideNarrow className='whiteFont' />
                                </li>
                                <li
                                    onClick={deleteLayer}
                                    style={{ cursor: "pointer" }}
                                    title='Layer Delete'>
                                    <RiDeleteBin6Line className='whiteFont' />
                                </li>
                            </ul>
                        </div>
                        <div className='editor-bar-mid'>
                            <SlidePlayPause
                                setSecond={setSecond}
                                setPlayStatus={setPlayStatus}
                                playStatus={playStatus}
                                timer={timer}
                                setTimer={setTimer}
                            />

                        </div>

                        <div className='editor-bar-right' style={{ fontSize: 16 }}>
                            {playStatus ?
                                <span className='whiteFont'>
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </span> :
                                <span className='whiteFont'>
                                    {time.h > 9 ? "" : "0"}{time.h}:{time.m > 9 ? "" : "0"}{time.m}:{time.s > 9 ? "" : "0"}{time.s}
                                </span>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div className="editor-controller d-none">
                <div className='video-slide'>
                    <VideoTiles />
                </div>
            </div>
        </>
    )
}

export default Preview