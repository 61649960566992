import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeCoach } from '../../../../../../Redux/Actions/ProjectActions'

import { fetchData } from '../../../../../../Redux/Actions/CommonActions'

const AicoachSection = () => {
    const dispatch = useDispatch()
    const optinData = useSelector(state => state.optin.data)

    const [aiCoaches, setAiCoaches] = useState([])
   
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeCoach(name, value))
    }

    const fetchAutoResponder = () => {
        dispatch(fetchData("fetch-coach", {}, setAiCoaches))
    }
 
    useEffect(() => {
        fetchAutoResponder()
    }, [])

    return (
        <div>
            <div className='inpLabelWrap'>
                <span className='labelTxt'>AI-Coach List</span>
                <select
                    className='common-input'
                    value={optinData.aiCoach}
                    name='aiCoach'
                    onChange={handleChange}
                >
                    <option value="">Select AI-Coach</option>
                    {aiCoaches.length > 0 ?
                        aiCoaches.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem.id}>{curElem.username}</option>
                            )
                        })
                        : null}
                </select>
            </div>
        </div>
    )
}

export default AicoachSection