import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { FaRegEdit } from 'react-icons/fa'
import { CiGlobe } from "react-icons/ci";
import { Link } from 'react-router-dom'
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import SweetAlert from '../../../../CommonComponent/SweetAlert'
import { baseURL } from '../../../../../Global/Global'
import { onCheckStatus } from '../../../../../Redux/Actions/ProjectActions'
import { MdOutlineDownload } from "react-icons/md";
import { CgScreen } from "react-icons/cg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import PublishModal from '../../PublishModal';
import dummyIcon from "../../../../../assets/images/defaultIcon.png"


const VideoRows = ({ curElem, index, videos, setVideos }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
        bgColor: "#FFA500"
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-video", data, videos, setVideos, setSweet))
    }

    const fetchVideo = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }


    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let progress = 100
            let bgColor = "#FF7F50"

            videoUrl = curElem.videoUrl

            switch (+curElem.status) {
                case 1:
                    text = "Generating Script..."
                    showProg = true
                    activeIntrval = true
                    bgColor = "#FF7F50"
                    break;
                case 2:
                    text = "Render Successful"
                    canEdit = true
                    url = "projects/editor"
                    bgColor = "#008000"
                    break;
                case 3:
                    canEdit = true
                    text = "Rendering Failed"
                    url = "projects/editor"
                    bgColor = "#FF0000"

                    break;
                case 4:
                    canEdit = true
                    text = "Script Generated"
                    url = "projects/update-script"
                    bgColor = "#008000"
                    break;
                case 5:
                    text = "Generating Video..."
                    showProg = true
                    activeIntrval = true
                    url = "projects/editor"
                    progress = 100
                    bgColor = "#FF7F50"
                    break;
                case 6:
                    canEdit = true
                    text = "Awaiting Customization"
                    url = "projects/editor"
                    bgColor = "#191970"
                    break;
                case 7:
                    canEdit = true
                    text = "Downloading Resources..."
                    activeIntrval = true
                    showProg = true
                    url = "projects/editor"
                    bgColor = "#FF7F50"
                    break;
                case 8:
                    canEdit = true
                    activeIntrval = true
                    url = "projects/editor"
                    showProg = true
                    text = curElem.renderProgress
                    bgColor = "#DAA520"

                    break;
                case 9:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = "projects/editor"
                    progress = curElem.renderProgress
                    bgColor = "#DAA520"
                    break;
                case 11:
                    canEdit = true
                    text = "Waiting in Queue..."
                    activeIntrval = true
                    showProg = true
                    url = "projects/editor"
                    bgColor = "#4B0082"
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = ""
            }

            setCommon({
                ...common,
                statusText: text,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnailUrl,
                failedData: curElem.logFile ? JSON.parse(curElem.logFile) : false,
                progress: progress,
                bgColor: bgColor
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])


    return (
        <tr>
            <td>
                <span className='vid-thumb'><img src={common.thumbUrl ? common.thumbUrl : dummyIcon} alt="" /></span>
            </td>
            <td>{curElem.name}</td>
            <td>

                {common.statusText === "Rendering Failed" ?
                    <span className='status-box' style={{ background: common.bgColor }} data-tooltip-id={`my-tooltip-${curElem.id}`} >{common.statusText}</span> :
                    common.statusText === "Rendering" ?
                        <span className='status-box' style={{ background: common.bgColor }}>{common.statusText} {common.progress}%</span> :
                        <span className='status-box' style={{ background: common.bgColor }}>{common.statusText}</span>
                }
            </td>
            <td>{curElem.created}</td>
            <td>
                {common.isEditabel ?
                    <Link className='link-icon' title='Edit' to={`/${common.urlToRedirect}?cid=${curElem.cId}&id=${curElem.id}`}><FaRegEdit /></Link> : null}
                {common.isDownloadable ?
                    <>
                        <a className='link-icon' title='Download' onClick={handleDownload}><MdOutlineDownload /></a>
                        <a className='link-icon' title='Publish' onClick={() => setShow(true)}><CiGlobe /></a>
                        <a className='link-icon' title='OCP' onClick={() => handleOCP(curElem.id)}><CgScreen /></a>
                    </>
                    : null
                }
                <a className='link-icon' title='Delete' onClick={() => onDelete(curElem.id)}><BsTrash /></a>
            </td>
            {common.failedData && common.statusText === "Rendering Failed" ?
                <Tooltip
                    id={`my-tooltip-${curElem.id}`}
                    style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                    place="bottom"
                    content={common.failedData.errorMessage}
                />
                : ""}

            <PublishModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this video?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />


        </tr>
    )
}

export default VideoRows