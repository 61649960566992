import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SocialBox from './SocialBox'
import { onChangeOptinContact, onChangeOptinContactButton, onChangeOptinContactStyle } from '../../../../../../Redux/Actions/ProjectActions'
import GradientBox from '../../../../../CommonComponent/GradientBox'
import { SketchPicker } from 'react-color'
import rgbHex from 'rgb-hex'
import { useImmer } from 'use-immer'
import { fetchData } from '../../../../../../Redux/Actions/CommonActions'

const ContactSection = () => {
    const dispatch = useDispatch()
    const optinData = useSelector(state => state.optin.data)

    const [autoRsponder, setAutoResponder] = useState([])
    const [isGradientStyle, setIsGradientStyle] = useState(false);
    const [isGradientButton, setIsGradientButton] = useState(false);
    const [typeStyle, setTypeStyle] = useState(false);
    const [typeButton, setTypeButton] = useState(false);

    const [colorShow, setColorShow] = useImmer({
        style: {
            bg: false,
            font: false
        },
        btn: {
            bg: false,
            font: false
        }
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeOptinContact(name, value))
    }

    const handleChangeButton = (e) => {
        const { name, value } = e.target
        dispatch(onChangeOptinContactButton(name, value))
    }

    const handleChangeStyle = (e) => {
        const { name, value } = e.target
        dispatch(onChangeOptinContactStyle(name, value))
    }

    const getFinalGradient = (type, finalGradient) => {
        if (type === "style") {
            dispatch(onChangeOptinContactStyle("bg", finalGradient))
        } else if (type === "btn") {
            dispatch(onChangeOptinContactButton("bg", finalGradient))
        }
    }


    const handleChangeColor = (e, type) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        if (type === "styleBg") {
            dispatch(onChangeOptinContactStyle("bg", hex))
        } else if (type === "styleFont") {
            dispatch(onChangeOptinContactStyle("fontColor", hex))
        }
        else if (type === "btnBg") {
            dispatch(onChangeOptinContactButton("bg", hex))
        }
        else if (type === "btnFont") {
            dispatch(onChangeOptinContactButton("color", hex))
        }
    }

    const handleToggleActive = (type) => {
        if (type === "styleBg") {
            setColorShow((draft) => {
                draft.style.bg = true
            })
        } else if (type === "styleFont") {
            setColorShow((draft) => {
                draft.style.font = true
            })
        }
        else if (type === "btnBg") {
            setColorShow((draft) => {
                draft.btn.bg = true
            })
        }
        else if (type === "btnFont") {
            setColorShow((draft) => {
                draft.btn.font = true
            })
        }
    }

    const handleToggleDeactive = (type) => {
        if (type === "styleBg") {
            setColorShow((draft) => {
                draft.style.bg = false
            })
        } else if (type === "styleFont") {
            setColorShow((draft) => {
                draft.style.font = false
            })
        }
        else if (type === "btnBg") {
            setColorShow((draft) => {
                draft.btn.bg = false
            })
        }
        else if (type === "btnFont") {
            setColorShow((draft) => {
                draft.btn.font = false
            })
        }
    }
    const fetchAutoResponder = () => {
        dispatch(fetchData("fetch-optin-auto", {}, setAutoResponder))
    }

    useEffect(() => {
        if (isGradientStyle) {
            setTypeStyle("Gradient")
        } else {
            setTypeStyle("Color")
        }
    }, [isGradientStyle])

    useEffect(() => {
        if (isGradientButton) {
            setTypeButton("Gradient")
        } else {
            setTypeButton("Color")
        }
    }, [isGradientButton])

    useEffect(() => {
        fetchAutoResponder()
    }, [])

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Opt-in Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Heading"
                        type="text"
                        name='heading'
                        value={optinData.contact.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Opt-in Social Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Social Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Social Heading"
                        type="text"
                        name='contactHeading'
                        value={optinData.contact.contactHeading}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className='col-md-12'>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Button Text </span>
                    <div className="inpLabel mb-1">
                        <label htmlFor="heading">Button Text</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Enter Button Text Here"
                            type="text"
                            name='btnText'
                            value={optinData.contact.btn.btnText}
                            onChange={handleChangeButton}
                        />
                    </div>
                </div>
            </div>

            <div className='inpLabelWrap'>
                <span className='labelTxt'>Opt-in List</span>
                <select
                    className='common-input'
                    value={optinData.contact.arList}
                    name='arList'
                    onChange={handleChange}
                >
                    <option value="">Select Opt-in</option>
                    {autoRsponder.length > 0 ?
                        autoRsponder.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem.id}>{curElem.username}</option>
                            )
                        })
                        : null}
                </select>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Opt-in Style</span>
                <div className='row p-2'>
                    <div className='col-lg-6'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'> Background Color Type</span>
                            <select
                                className='common-input'
                                value={typeStyle}
                                onChange={(e) => setTypeStyle(e.target.value)}
                            >
                                <option value="Color">Color</option>
                                <option value="Gradient">Gradient</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>{typeStyle}</span>
                            {typeStyle === "Color" ?
                                <div className='primary-background'>
                                    <div className='color-wrap'>
                                        <span
                                            className='colorBox'
                                            style={{ background: optinData.contact.style.bg }}
                                            onClick={() => handleToggleActive("styleBg")}
                                        ></span>
                                        <span className='colorVal'>{optinData.contact.style.bg}</span>
                                    </div>
                                    {colorShow.style.bg ?
                                        <>
                                            <div
                                                style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                onClick={() => handleToggleDeactive("styleBg")}
                                            />
                                            <span className="color-box">
                                                <SketchPicker
                                                    color={optinData.contact.style.bg}
                                                    onChange={(e) => handleChangeColor(e, "styleBg")}
                                                />
                                            </span>
                                        </> : null}
                                </div> :
                                <div className='primary-gradient'>
                                    <GradientBox
                                        getFinalGradient={getFinalGradient}
                                        colorString={optinData.contact.style.bg}
                                        setIsGradient={setIsGradientStyle}
                                        type={"style"}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>Font Color</span>
                            <div className='primary-background'>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: optinData.contact.style.fontColor }}
                                        onClick={() => handleToggleActive("styleFont")}
                                    ></span>
                                    <span className='colorVal'>{optinData.contact.style.fontColor}</span>
                                </div>
                                {colorShow.style.font ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactive("styleFont")}
                                        />
                                        <span className="color-box">
                                            <SketchPicker
                                                color={optinData.contact.style.fontColor}
                                                onChange={(e) => handleChangeColor(e, "styleFont")}
                                            />
                                        </span>
                                    </> : null}
                            </div>
                        </div>
                    </div>





                    <div className='col-lg-6'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>Button Background Type</span>
                            <select
                                className='common-input'
                                value={typeButton}
                                onChange={(e) => setTypeButton(e.target.value)}
                            >
                                <option value="Color">Color</option>
                                <option value="Gradient">Gradient</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>{typeButton}</span>
                            {typeButton === "Color" ?
                                <div className='primary-background'>
                                    <div className='color-wrap'>
                                        <span
                                            className='colorBox'
                                            style={{ background: optinData.contact.btn.bg }}
                                            onClick={() => handleToggleActive("btnBg")}
                                        ></span>
                                        <span className='colorVal'>{optinData.contact.btn.bg}</span>
                                    </div>
                                    {colorShow.btn.bg ?
                                        <>
                                            <div
                                                style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                onClick={() => handleToggleDeactive("btnBg")}
                                            />
                                            <span className="color-box">
                                                <SketchPicker
                                                    color={optinData.contact.btn.bg}
                                                    onChange={(e) => handleChangeColor(e, "btnBg")}
                                                />
                                            </span>
                                        </> : null}
                                </div> :
                                <div className='primary-gradient'>
                                    <GradientBox
                                        getFinalGradient={getFinalGradient}
                                        colorString={optinData.contact.btn.bg}
                                        setIsGradient={setIsGradientButton}
                                        type={"btn"}
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    <div className='col-lg-12'>
                        <div className='inpLabelWrap mt-1'>
                            <span className='labelTxt'>Button Font Color</span>
                            <div className='primary-background'>
                                <div className='color-wrap'>
                                    <span
                                        className='colorBox'
                                        style={{ background: optinData.contact.btn.color }}
                                        onClick={() => handleToggleActive("btnFont")}
                                    ></span>
                                    <span className='colorVal'>{optinData.contact.btn.color}</span>
                                </div>
                                {colorShow.btn.font ?
                                    <>
                                        <div
                                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                            onClick={() => handleToggleDeactive("btnFont")}
                                        />
                                        <span className="color-box">
                                            <SketchPicker
                                                color={optinData.contact.btn.color}
                                                onChange={(e) => handleChangeColor(e, "btnFont")}
                                            />
                                        </span>
                                    </> : null}
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-12 mt-3'>
                        <span className='labelTxt'>Social Connctions</span>
                        <div className='p-2'>
                            <SocialBox
                                type={"Facebook"}
                                data={optinData.contact.socials.facebook}
                                name="facebook"

                            />
                            <SocialBox
                                type={"Youtube"}
                                data={optinData.contact.socials.youtube}
                                name="youtube"

                            />
                            <SocialBox
                                type={"X (Twitter)"}
                                data={optinData.contact.socials.twitter}
                                name="twitter"
                            />
                            <SocialBox
                                type={"LinkedIn"}
                                data={optinData.contact.socials.linkedin}
                                name="linkedin"
                            />
                            <SocialBox
                                type={"Instagram"}
                                data={optinData.contact.socials.instagram}
                                name="instagram"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSection