import React, { useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch } from 'react-redux';
// import { onCancleBenefit, onChangeBenefit } from '../../Actions/ClientsAction';
import IconsModal from './Modals/IconsModal'
import defaultImage from "../../../../../../assets/images/dummyImage.jpg"
import ImagesModal from './Modals/ImagesModal';
import Swal from 'sweetalert2';
import { onCancleBenefit, onChangeBenefit } from '../../../../../../Redux/Actions/ProjectActions';
import SweetAlert from '../../../../../CommonComponent/SweetAlert';

const BenefitRows = ({ curElem, index, handleChange }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
    })

    const handleCancel = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        dispatch(onCancleBenefit(sweet.id))
        onCancel()
    }


    // const handleCancel = (index) => {
    //     Swal.fire({
    //         title: "Are You Sure?",
    //         text: "You won't be able to revert this!",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#fff",
    //         confirmButtonText: "Yes, delete it"
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             dispatch(onCancleBenefit(index))
    //         }
    //     })
    // }

    const handleAddIcon = (data, index) => {
        dispatch(onChangeBenefit('icon', data, index))
    }
    return (
        <>
            <div className="col-lg-4 mb-4">
                <div className="benefit-single" style={{ position: "relative" }}>
                    <h6>Product {index + 1}</h6>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Heading</span>
                        <div className="inpLabel">
                            <label>Enter Product Heading</label>
                            <input
                                className="inpLabel-inp"
                                placeholder="Heading"
                                type="text"
                                name='heading'
                                value={curElem.heading}
                                onChange={(e) => handleChange(e, index)}
                            />
                        </div>
                    </div>
                    <div
                        className='list-delete'
                        style={{ cursor: "pointer", fontSize: "20px", opacity: 1 }}
                        onClick={() => handleCancel(index)}
                    >
                        <RxCross2 />
                    </div>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Choose Icon</span>
                        <div style={{ position: 'relative', cursor: "pointer" }} onClick={handleShow}>
                            <span className='uploaded-img upload-icons'>
                                {curElem.icon ?
                                    <i className={`fa ${curElem.icon}`} aria-hidden="true" /> :
                                    <img src={defaultImage} alt="" />
                                }
                            </span>
                            <span className="chooseIcon  cursor-pointer">Choose Icon</span>
                        </div>


                    </div>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Description</span>
                        <div className="inpLabel">
                            <label htmlFor="description" style={{ width: "100%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #3f429b", borderLeft: "1px solid #3f429b", borderRight: "1px solid #3f429b" }}>Enter Description</label>
                            <textarea
                                className="inpLabel-inp"
                                name="description"
                                placeholder='Enter Description'
                                type="text"
                                value={curElem.description}
                                onChange={(e) => handleChange(e, index)}
                            />
                        </div>
                    </div>
                </div>
                {/* <ImagesModal
                    show={show}
                    handleClose={handleClose}
                    callbackFun={handleAddIcon}
                    iconIndex={index}
                /> */}
                <IconsModal
                    show={show}
                    handleClose={handleClose}
                    callbackFun={handleAddIcon}
                    iconIndex={index}
                />

                <SweetAlert
                    show={sweet.enable}
                    message={"Are you sure you want to remove this benifit?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    alertLoader={sweet.loader}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancel}
                    performDelete={onConfirm}
                />
            </div>

        </>
    )
}

export default BenefitRows