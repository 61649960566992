import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchData, onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { BiTrash, BiChevronDownSquare } from "react-icons/bi"
import { MdOutlineEdit } from "react-icons/md"
import Dropdown from 'react-bootstrap/Dropdown';
import { BsBoxArrowInDown, BsChevronDown } from "react-icons/bs"
import { Link } from 'react-router-dom'
import SweetAlert from '../../CommonComponent/SweetAlert'

const VideoProjects = () => {
    const dispatch = useDispatch()

    const [camp, setCamp] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchCamp = () => {
        let limit = 4
        dispatch(fetchData("get-campaign", {}, setCamp, loader, setLoader, limit))
    }

    useEffect(() => {
        fetchCamp()
    }, [])

    return (
        <div className='video-project '>
            <div className='video-project-wrap'>
                <div className='row'>
                    {camp.length > 0 ?
                        camp.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Link className="col-lg-3" to={`/projects/knowledge-brain?cid=${curElem.id}`}>
                                        <div className="video-project-single" >
                                            <div className="video-project-img" style={{ background: "#c9c9f1" }}><img alt="" src={require('../../../assets/images/cardicon.png')} style={{ objectFit: "contain" }} /></div>
                                            <div className="video-project-bottom text-center" >
                                                <h6>{curElem.name}</h6>
                                                <p className='text-dark'>{curElem.created}</p>
                                                {/* <div className="drop-style-1">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="r" id="dropdown-basic">
                                                            <BsChevronDown />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <ul>
                                                                <li><Link to={`/projects/knowledge-brain?cid=${curElem.id}`}><MdOutlineEdit /> Edit</Link></li>
                                                                <li><Link onClick={() => onDelete(curElem.id)}><BiTrash /> Delete</Link></li>
                                                            </ul>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Link>
                                </React.Fragment>
                            )
                        })
                        :
                        <div className="col-md-12">
                            <h4 className="text-center load-icon-help mt-3">
                                {
                                    loader.fetch ?
                                        <i className="fa fa-spinner fa-spin ms-1" style={{ fontSize: 25, color: "#3f429b" }} />
                                        :
                                        <p style={{ fontSize: 18 }}>
                                            You do not have any "Projects" in your account.
                                        </p>
                                }
                            </h4>
                        </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default VideoProjects