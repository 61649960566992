import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from 'react-icons/gi';
import { FaSpinner } from 'react-icons/fa';
import { addTransition, removeTransition } from '../../../../../../../Redux/Actions/VideoActions';

const TransCard = (props) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [state, setState] = useState({
        active: false,
        loader: false
    })

    const handleTransition = () => {
        dispatch(addTransition(props.name, selectedSlideIndex))
    }
    const handleRemove = (e) => {
        e.stopPropagation()
        dispatch(removeTransition(selectedSlideIndex))
    }

    const handleActive = () => {
        setState({
            ...state,
            loader: true,
            active: true
        })

    }

    const handleLeave = () => {
        setState({
            ...state,
            loader: false,
            active: false
        })
    }
    return (
        <div className="col-6">
            <div
                className="photo-single"
                style={{ cursor: "pointer" }}
                onClick={() => handleTransition()}
                onMouseEnter={() => handleActive()}
                onMouseLeave={() => handleLeave()}
            >
                <img
                    alt=""
                    src={state.active ? props.preview : props.thumbnail}
                    onLoad={() => setState({
                        ...state,
                        loader: false,
                    })}
                />

                <div className="trans-name">
                    {state.loader ?
                        <FaSpinner className='spin' style={{ fontSize: 25, color: "#3f429b"}} />
                        : <span className='text-light'>{props.name}</span>
                    }
                </div>
                {selectedSlide.transitionName === props.name ?
                    <div className='select-tile-style'  onClick={handleRemove}>
                        <GiCheckMark />
                    </div>
                    : ''
                }
            </div>
        </div>
    );
}

export default TransCard;
