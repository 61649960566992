import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { onfetchImages } from '../../../../../../../Redux/Actions/ProjectActions';
import iconData from '../../../../../../../Global/icon.json';

const IconsModal = (props) => {
    const [iconList, setIconList] = useState([])
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })
    const [selected, setSelected] = useState("")
    const [search, setSearch] = useState("")
    const handleChange = (e) => {
        if (iconList.length > 0) {
            setIconList([])
        }
        setSearch(e.target.value)
    }



    const onChooseImage = (curElem, index) => {
        setSelected(curElem)
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            props.callbackFun(selected, props.iconIndex)
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);
    }

    useEffect(() => {
        if(!props.show){
            setSelected("")
        }
    },[props.show])


    return (
        <Modal className="theme-modal video-modal xl" show={props.show} centered>
            <Modal.Body>
                <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>
                <div className="imgUp">
                    <div className='inpLabel m-0'>
                        <label style={{ fontSize: 15 }}>Icons</label>
                    </div>
                    <div className="imgUp-main mt-1">
                        <div className="img-search" >
                            <input
                                type="text"
                                placeholder="Search Icon"
                                value={search}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className='landing-items'>
                            {
                                iconData.length > 0 ?
                                    iconData.filter(
                                        icon => icon.toLowerCase().indexOf('fa-' + search.toLowerCase()) > -1
                                    ).map((item, index) => {
                                        return (
                                            <a onClick={(e) => onChooseImage(item)} key={index} className="emoji-custom" style={{ cursor: "pointer" }}>
                                                <i style={{color:selected === item ? "#3f429b" : ""}} className={`fa ${item}`} aria-hidden="true"></i>
                                            </a>
                                        )
                                    })
                                    : ''
                            }
                        </div>
                        {/* <InfiniteScroll
                            dataLength={iconList.length} //This is important field to render the next data
                            next={() => fetchImage(search.page + 1)}
                            hasMore={true}
                            scrollableTarget={`scrollableIconModal`}
                        >
                            <div className="img-search-scroll" style={{ minHeight: "350px", maxHeight: "390px" }} id='scrollableIconModal'>
                                <ul className="iconList">
                                    {iconData.length > 0 ?
                                        iconData.map((curElem, index) => {
                                            return (
                                                <li key={index}>
                                                    <div
                                                        className="imgUp-single cursor-pointer"
                                                        style={{ border: search.index === index ? "1px solid #d84444" : "" }}
                                                        onClick={() => onChooseImage(curElem, index)}
                                                    >
                                                        <img src={curElem.local_url} alt={curElem.name} />
                                                        <div className="imgStat"><BsCheckCircle /></div>
                                                    </div>
                                                </li>
                                            )
                                        }) :
                                        <div className='text-center pt-3' style={{ margin: "auto" }}>
                                            {msg}
                                        </div>
                                    }
                                </ul>
                                {loader.fetchLoader ?
                                    <div className='text-center mt-3'>
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#ff6363" }} />
                                    </div>
                                    : ""}
                            </div>
                        </InfiniteScroll> */}
                    </div>
                    <div className="btnTab">
                        <button className="site-link bdr ml-2" onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="site-link" onClick={handleSave}><span> {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin" /></> : "Use"}</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default IconsModal