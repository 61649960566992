import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../title-bar/TitleBar';
import Header from '../../../../header/Header';
import Footer from '../../../../footer/Footer';

import { onFetchScript, onUpdateScript } from '../../../../../Redux/Actions/ProjectActions';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { MdArrowBackIos } from "react-icons/md";
const UpdateScript = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, cid } = queryString.parse(location.search)

  const [scriptText, setScriptText] = useState("")
  const [loader, setLoader] = useState(true)
  const [buttonLoader, setButtonLoader] = useState({
    save: false,
    next: false
  })

  const handleSubmit = (type) => {
    if (scriptText) {
      let data = {
        id: id,
        cId: cid,
        savedScript: scriptText,
        type: type
      }
      setButtonLoader({
        ...buttonLoader,
        next: type === "save" ? false : true,
        save: type === "save" ? true : false
      })
      dispatch(onUpdateScript(data, navigate, buttonLoader, setButtonLoader))
    } else {
      dispatch(setAlert("Please provide script!", "danger"))
    }
  }

  const fetchVideo = () => {
    let data = {
      id: id
    }
    setLoader(true)
    dispatch(onFetchScript(data, setScriptText, setLoader))
  }

  useEffect(() => {
    if (id && cid) {
      fetchVideo()
    }
  }, [id, cid])


  return (
    loader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <TitleBar title="Create Video" />
        <Header />

        <section className='page-wrap'>
          <div className='container'>

            <div className='flex-bar'>
              <h4 style={{ fontSize: 28 }}><Link className='text-dark' to={`/projects/assets?cid=${cid}`}><MdArrowBackIos className='pb-1' />Review Script</Link></h4>
              <div className='text-center'>
                <button className='site-link bdr' onClick={() => handleSubmit("save")}><span>{buttonLoader.save ? <>Saving <i className="fa fa-spinner fa-spin" /></> : "Save Script"}</span></button>
                <button className='site-link ms-3' onClick={() => handleSubmit("createVideo")}><span>{buttonLoader.next ? <>Converting <i className="fa fa-spinner fa-spin" /></> : "Convert to Video "}</span></button>
              </div>
            </div>

            <div className='sm-containern'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='cont-box sm-gap'>
                    <div className='inp-bg'>
                      <div className='inp-bg-top'>
                        <p><strong>Edit your script here</strong></p>
                      </div>
                      <div className='inp-bg-mid'>
                        <textarea
                          className='common-input'
                          style={{ height: "98%" }}
                          value={scriptText}
                          onChange={(e) => setScriptText(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
  )

}

export default UpdateScript;